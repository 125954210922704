import { createSlice } from "@reduxjs/toolkit";

import moment from "moment";

const initialState = {
  dirty: false,
  department: {},
  departmentList: [],
  userInDepartment: [],
  userList: [],
  userWoDepartment: [],
};

const slice = createSlice({
  name: "departmentSlice",
  initialState: initialState,
  reducers: {
    addDepartment: (state, action) => {
      const newName = action.payload;
      const exists = state.departmentList.filter((r) => {
        return r.name.toUpperCase() === newName.toUpperCase();
      }).length;

      if (exists === 0) {
        const newDepartment = {
          departmentid: action.payload + "" + moment().valueOf(),
          name: action.payload,
          staff_doc: [],
        };

        return {
          ...state,
          dirty: true,
          departmentList: [...state.departmentList, newDepartment],
        };
      } else {
        return { ...state };
      }
    },
    loadDepartmentList: (state, action) => {
      const newDepartmentList = [...action.payload];

      let userWDepartment = [];

      newDepartmentList.forEach((department) => {
        department.staff_doc.forEach((staff_doc) => {
          userWDepartment = [...userWDepartment, staff_doc.userid];
        });
      });

      const newUserWoDepartment = state.userList?.filter((user) => {
        const exists = userWDepartment.filter((user2) => {
          return user.userid === user2;
        }).length;
        return exists === 0;
      });

      return {
        ...state,
        departmentList: newDepartmentList,
        userWoDepartment: newUserWoDepartment,
      };
    },
    selectDepartment: (state, action) => {
      const department = action.payload;

      return {
        ...state,
        userInDepartment: [...department.staff_doc],
        department: department,
      };
    },
    removeDepartment: (state, action) => {
      const department = action.payload;

      const newDepartmentList = state.departmentList.filter((d) => {
        return d.name.toUpperCase() !== department.name.toUpperCase();
      });
      let newUserWoDepartment = [...state.userWoDepartment];
      department.staff_doc.forEach((s) => {
        const newUser = state.userList
          .filter((user) => {
            return user.userid === s.userid;
          })
          .shift();
        newUserWoDepartment = [...newUserWoDepartment, newUser];
      });
      newUserWoDepartment = newUserWoDepartment.sort((a, b) => {
        return a?.fullname
          ?.toUpperCase()
          ?.localeCompare(b?.fullname?.toUpperCase());
      });
      return {
        ...state,
        dirty: true,
        userWoDepartment: newUserWoDepartment,
        departmentList: newDepartmentList,
        userInDepartment: [],
        department: {},
      };
    },
    updateUserWoDepartment: (state, action) => {
      return { ...state, userWoDepartment: action.payload };
    },
    removeUserToDepartment: (state, action) => {
      const removedUser = action.payload;
      let newUserWoDepartment = [...state.userWoDepartment];
      let newDepartmentList = state.departmentList;
      let newUserInDepartment = state.userInDepartment.filter((user) => {
        return user.userid !== removedUser.userid;
      });
      newUserWoDepartment = newUserWoDepartment.filter((d) => {
        return d.userid !== removedUser.userid;
      });
      newUserWoDepartment = [...newUserWoDepartment, removedUser];

      newDepartmentList = state.departmentList.map((department) => {
        if (department.departmentid === state.department.departmentid) {
          return { ...department, staff_doc: newUserInDepartment };
        } else {
          return department;
        }
      });

      return {
        ...state,
        dirty: true,
        userWoDepartment: newUserWoDepartment,
        userInDepartment: newUserInDepartment,
        departmentList: newDepartmentList,
      };
    },
    addUserToDepartment: (state, action) => {
      //   console.log(state.department?.departmentid);
      if (state.department?.departmentid === undefined) return state;
      const newUser = action.payload;
      let newUserWoDepartment = [...state.userWoDepartment];
      let newDepartmentList = state.departmentList;
      let newUserInDepartment = [...state.userInDepartment];
      newUserWoDepartment = newUserWoDepartment.filter((d) => {
        return d.userid !== newUser.userid;
      });
      const exists = state.userInDepartment.filter((user) => {
        return user.userid === newUser.userid;
      }).length;
      if (exists === 0) {
        newUserInDepartment = [...newUserInDepartment, newUser];
      }
      newDepartmentList = state.departmentList.map((department) => {
        if (department.departmentid === state.department.departmentid) {
          return { ...department, staff_doc: newUserInDepartment };
        } else {
          return department;
        }
      });

      return {
        ...state,
        dirty: true,
        userWoDepartment: newUserWoDepartment,
        userInDepartment: newUserInDepartment,
        departmentList: newDepartmentList,
      };
    },
    loadUserList: (state, action) => {
      const newUserList = action.payload;
      let userWDepartment = [];
      state.departmentList.forEach((department) => {
        department.staff_doc.forEach((staff_doc) => {
          userWDepartment = [...userWDepartment, staff_doc.userid];
        });
      });

      const newUserWoDepartment = newUserList.filter((user) => {
        const exists = userWDepartment.filter((user2) => {
          return user2 === user.userid;
        }).length;
        return exists === 0;
      });
      return {
        ...state,
        userList: newUserList,
        userWoDepartment: newUserWoDepartment,
      };
    },
    clearDirty: (state, action) => {
      return { ...state, dirty: false };
    },
  },
});

export const {
  addDepartment,
  removeDepartment,
  loadDepartmentList,
  loadUserList,
  selectDepartment,
  addUserToDepartment,
  removeUserToDepartment,
  clearDirty,
} = slice.actions;
export default slice.reducer;
