import { configureStore } from "@reduxjs/toolkit";

import thunk from "redux-thunk";

import ApplicationSlice from "./ApplicationSlice";
import AppraisalSlice from "./AppraisalSlice";
import DepartmentSlice from "./DepartmentSlice";
import CompanySlice from "./CompanySlice";
import AppraiseeListSlice from "./AppraiseeListSlice";
import ImportUserSlice from "./ImportUserSlice";
import MyAppraisalListSlice from "./MyAppraisalListSlice";

export default configureStore({
  reducer: {
    applicationSlice: ApplicationSlice,
    appraisalSlice: AppraisalSlice,
    departmentSlice: DepartmentSlice,
    companySlice: CompanySlice,
    appraiseeListSlice: AppraiseeListSlice,
    importUserSlice: ImportUserSlice,
    MyAppraisalListSlice: MyAppraisalListSlice,
  },
  middleware: [thunk],
});
