import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Row, Col } from "react-bootstrap";
import MyPaginate from "../../components/MyPaginate";
import {
  addCompany,
  selectCompany,
  removeCompany,
} from "../../store/CompanySlice";

import "./CompanyForm.css";

const mstp = (state, ownProps) => {
  const list = state.companySlice.companyList;

  return { list };
};
const mdtp = (dispatch, ownProps) => {
  const _addCompany = (v) => dispatch(addCompany(v));
  const _selectCompany = (company) => dispatch(selectCompany(company));
  const _removeCompany = (company) => dispatch(removeCompany(company));
  return {
    addCompany: _addCompany,
    selectCompany: _selectCompany,
    removeCompany: _removeCompany,
  };
};

const CompanyList = (props) => {
  const { list, addCompany, removeCompany, selectCompany } = props;
  const [showAdd, setShowAdd] = useState(false);
  const [newCompany, setNewCompany] = useState("");
  const [currentList, setCurrentItems] = useState([]);
  const handleAddCompany = (ev) => {
    const newDept = ev.target.value;
    setNewCompany(newDept);
  };
  const handleKeyUp = (ev) => {
    if (ev.key === "Escape") {
      addCompany(newCompany);
      setShowAdd(false);
    } else if (ev.key === "Enter") {
      addCompany(newCompany);
      setShowAdd(false);
    }
  };
  const handleShowAddCompanyButton = (ev) => {
    setShowAdd(!showAdd);
  };
  const handleSelectCompany = (company) => {
    selectCompany(company);
  };
  const handleRemove = (company) => {
    if (window.confirm("Confirm remove this?")) {
      removeCompany(company);
    }
  };
  return (
    <Card style={{ border: "1px solid black" }}>
      <Card.Header style={{ backgroundColor: "lightgreen" }}>
        <div className="d-flex justify-content-between">
          <div>Company</div>
          <div>
            <Button variant="primary" onClick={handleShowAddCompanyButton}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        {showAdd && (
          <input
            className="form-control"
            onKeyUp={handleKeyUp}
            onChange={handleAddCompany}
            placeholder="New Company"
          ></input>
        )}

        <MyPaginate list={list} setCurrentItems={setCurrentItems} />
        <ol>
          {currentList.map((d, i) => {
            return (
              <li key={i}>
                <span onClick={(ev) => handleSelectCompany(d)}>{d.name}</span>
                {d?.staff_doc.length ? (
                  <sup>{d.staff_doc.length}</sup>
                ) : (
                  <></>
                )}{" "}
                <span className="action">
                  <Button
                    className="btn btn-sm"
                    variant="warning"
                    onClick={(ev) => handleRemove(d)}
                  >
                    -
                  </Button>
                </span>
              </li>
            );
          })}
        </ol>
      </Card.Body>
    </Card>
  );
};
export default connect(mstp, mdtp)(CompanyList);
