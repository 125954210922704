import React from "react";
import AppraisalPart1Question from "./AppraisalPart1Question";
import Questions from "../../AppraisalQuestions";

import { Score3 as Score } from "../../components/Score";
import { useSelector } from "react-redux";
const AppraisalPart1 = (props) => {
  const { readOnly } = props;
  const score = useSelector(
    (state) => state.appraisalSlice.totalScore?.averageScore
  );

  return (
    <div className="card mb-3">
      <div className="card-body mb-3">
        <h4>PART I: PROFICIENCY IN CURRENT ROLE</h4>
      </div>
      <div className="row">
        <div className="col-12 col-lg-8">
          <h5>Performance Factors</h5>
        </div>
        <div className="d-none d-lg-block col-lg-4">
          <div className="d-flex justify-content-between">
            <div></div>
            <div>
              <h5>Scale Rating</h5>
            </div>
          </div>
        </div>
      </div>
      {Questions.map((q, i) => {
        return (
          <AppraisalPart1Question
            key={i}
            no={q.questionNo}
            title={q.questionTitle}
            question={q.question}
            tips={q.tips}
            readOnly={readOnly}
          />
        );
      })}

      <div
        className="card"
        style={{
          border: "1px solid black",
          boxShadow: "5px 5px 10px gray",
          backgroundColor: "FloraWhite",
          marginBottom: "1.5em",
        }}
      >
        <div className="card-body">
          <div className="row">
            <div className="d-flex flex-nowrap justify-content-end">
              <h5 style={{ display: "inline" }}>Total Score </h5>
              <span style={{ position: "relative" }}>
                <Score score={score} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppraisalPart1;
