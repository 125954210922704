import React from "react";

const LinkCard = (props) => {
  const handleClick = () => {
    props.onClick();
  };
  return (
    <div className="card linkcard" onClick={handleClick}>
      <div className="card-header noborder">{props.children}</div>
      <div className="card-body">
        <div className="title">{props.title}</div>
        <div className="description">{props.description}</div>
      </div>
    </div>
  );
};
export default LinkCard;
