import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import moment from "moment";
import { get } from "axios";
const mapStateToProps = (state, ownProps) => {
  const appraisee = state.appraisalSlice.appraisee;
  const year = state.appraisalSlice.year;

  // console.log(state.appraisalSlice);
  return { appraisee, year };
};

const AppraisalFormHeader = (props) => {
  const [pic, setPic] = useState("");
  const { appraisee } = props;

  const { userid, fullname, date_joined, position } = appraisee;
  useEffect(() => {
    let isMounted = true;
    // console.log(userid);
    // console.log(appraisee);
    if (userid === undefined) return;
    get(
      "https://eleave.hslnet.my/apiv2/public/index.php/employeephoto/" + userid
    )
      .then((res) => {
        if (isMounted) {
          setPic(res.data.photo);
        }
      })
      .catch((e) => {});

    return () => {
      isMounted = false;
    };
  }, [userid]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12 col-lg-9">
            <div className="form-group row">
              <label className="col-6 col-md-3 col-form-label">
                Staff Name
              </label>
              <div className="col-6 col-md-9">
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={fullname}
                ></input>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-6 col-md-3 col-form-label">Position</label>
              <div className="col-6 col-md-9">
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={position}
                ></input>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-6 col-md-3 col-form-label">
                Date joined
              </label>
              <div className="col-6 col-md-9">
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  value={moment(date_joined).format("d MMM yyyy")}
                ></input>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-3">
            {pic && (
              <img
                alt="xx"
                src={pic}
                style={{ borderRadius: "50%", width: "8em" }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="card-body">
        <h6>OBJECTIVES</h6>
        <div>
          The purpose of the evaluation is to provide constructive feedback to
          staff and to formulate a basis for salary reviews and bonuses. It
          should assist both the staff member and his/her supervisor in
          identifying areas of improvement or competency gaps which can be
          addressed.
        </div>
      </div>
      <div className="card-body">
        <h6>INSTRUCTIONS</h6>
        <div>
          This performance evaluation must be completed honestly and objectively
          by the relevant head of department or supervisor. Each criterion is to
          be rated using the scale referenced below:
        </div>
        <div style={{ padding: "1em" }}>
          <table className="table table-scoreboard">
            <thead>
              <tr>
                <th style={{ minWidth: "10vw" }}>Scale</th>
                <th style={{ minWidth: "10vw" }}>Rating</th>
                <th style={{ minWidth: "10vw" }}>Remark</th>
                <th>Competency Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span style={{ fontSize: "0.5em" }}>
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                </td>
                <td>100</td>
                <td>Excellent</td>
                <td rowSpan={2}>No action required</td>
              </tr>
              <tr>
                <td>
                  <span style={{ fontSize: "0.5em" }}>
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                </td>
                <td>85</td>
                <td>Good</td>
              </tr>
              <tr>
                <td>
                  <span style={{ fontSize: "0.5em" }}>
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                </td>
                <td>70</td>
                <td>Satisfactory</td>
                <td rowSpan={2}>Optional training can be considered</td>
              </tr>
              <tr>
                <td>
                  <span style={{ fontSize: "0.5em" }}>
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                </td>
                <td>50</td>
                <td>Fair</td>
              </tr>
              <tr>
                <td>
                  <span style={{ fontSize: "0.5em" }}>
                    <FontAwesomeIcon icon={faStar} />
                  </span>
                </td>
                <td>30</td>
                <td rowSpan={2}>Poor</td>
                <td rowSpan={2}>
                  Performance below expectation and competency gap exists.
                  Relevant training or actions may be considered
                </td>
              </tr>
              <tr>
                <td>
                  <span style={{ fontSize: "0.5em" }}></span>
                </td>
                <td>0</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          The appraiser must assess the staff member according to his/her
          position and level of experience and against his/her job descriptions.
          The appraiser should also bear in mind that the HSL Group expects
          superior performances from its staff. Specific examples should be
          provided to support assessments, particularly for ratings{" "}
          <b>5 stars and no stars</b>. It is essential that this evaluation be
          discussed with the staff member for development and improvement.
        </div>
      </div>
    </div>
  );
};
export default connect(mapStateToProps, null)(AppraisalFormHeader);
