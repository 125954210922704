import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { removeUserToDepartment } from "../../store/DepartmentSlice";
import { useDispatch } from "react-redux";
import MyPaginate from "../../components/MyPaginate";

const mstp = (state, ownProps) => {
  const list = state.departmentSlice.userInDepartment;
  return { list };
};

export default connect(mstp)((props) => {
  const dispatch = useDispatch();
  const handleRemove = (u) => {
    dispatch(removeUserToDepartment(u));
  };

  const { list } = props;
  const [currentList, setCurrentItems] = useState([]);
  const [sortedList, setSortedList] = useState([]);

  useEffect(() => {
    // console.log(list);
    const l = [...list];
    const sl = l.sort((a, b) => {
      return a.fullname?.localeCompare(b.fullname);
    });
    // console.log(sl);
    setSortedList(sl);
  }, [list]);

  return (
    <>
      <MyPaginate list={sortedList} setCurrentItems={setCurrentItems} />
      <ol>
        {currentList.map((u, i) => {
          return (
            <li key={i}>
              {u.fullname}
              <span className="action">
                <button onClick={(e) => handleRemove(u)}>-</button>
              </span>
            </li>
          );
        })}
      </ol>
    </>
  );
});
