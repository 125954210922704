import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Row, Col, Table } from "react-bootstrap";

import { LISTVIEW, YEAR } from "../../Constants";
import { postError } from "../../store/ApplicationSlice";
import { get } from "axios";
import { useNavigate } from "react-router-dom";
import SubmittedList from "./SubmittedList";

import StatusBall from "../../components/StatusBall";

// import "./DepartmentForm.css";

const SupervisorSubmitted = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [supervisorRecords, setSupervisorRecords] = useState([]);
  
  const [listRecord, setListRecord] = useState([]);
  const [listTitle, setListTitle] = useState("");

  const [mode, setMode] = useState(LISTVIEW.LIST);

  useEffect(() => {
    get("api/appraisees/details")
      .then((res) => {
        console.log(res.data);
        setSupervisorRecords(res.data);
      })
      .catch((e) => {
        dispatch(postError(e));
      });
  }, []);
  
  const handleBack = (ev) => {
    navigate("../main");
  };

  const handleListMode = () => {
    setMode(LISTVIEW.LIST);
  };

  return (
    <>
      {mode === LISTVIEW.VIEW && (
        <SubmittedList
          name2={listTitle}
          records={listRecord}
          onBack={handleListMode}
        />
      )}
      
      {mode === LISTVIEW.LIST && (
        <Row>
          <Col md={2} className="d-none d-md-block"></Col>
          <Col xs={12} md={8} lg={8}>
            <Card style={{ border: "1px solid black", padding: "10px" }}>
              <Table striped style={{ border: "1px solid black" }}>
                <thead>  
                  <tr>
                    <th>&nbsp;</th>
                    <th className="h3" colSpan={2}>
                      Supervisor
                    </th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <td>No</td>
                    <td>Appraisee</td>
                    <td>Proxy</td>
                    <td>Appraiser</td>
                    <td>Status</td>
                  </tr>                  
                  {supervisorRecords.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        style={{ cursor: "pointer" }}
                      >
                        <td>{i + 1}</td>
                        <td className="h4">{item.appraisee?.fullname}</td>
                        
                        <td>{item.proxy?.fullname}</td>
                        <td>{item.appraiser?.fullname}</td>

                        {/* <td className="h4">{item.score}</td> */}
                        <td className="h4">
                      <StatusBall status={item.status} />
                    </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>

       
           
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <div>
                    <button className="btn btn-warning" onClick={handleBack}>
                      Back
                    </button>
                  </div>
                  <div></div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SupervisorSubmitted;
