import React from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFound = (props) => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/main");
  };
  return (
    <div>
      <Card>
        <Card.Body>
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>Opps! This Page Could Not Be Found</h2>
          <p>
            Sorry, the page you are looking for does not exists, or it is
            temporarily unavailable
          </p>
          <Button onClick={handleLogin}>Go To HomePage</Button>
        </Card.Body>
      </Card>
    </div>
  );
};
export default NotFound;
