import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { addAppraiser, updateAppraisers } from "../../store/AppraiseeListSlice";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import AppraiserCard from "./AppraiserCard";

const mstp = (state, ownProps) => {
  const list = state.appraiseeListSlice.staffs;
  const appraisee = state.appraiseeListSlice.appraisee;
  const appraisers = state.appraiseeListSlice.appraisee?.appraisers || [];
  // console.log(appraisers);
  // console.log(list);
  return { list, appraisers, appraisee };
};
const mdtp = (dispatch, ownProps) => {
  const _updateAppraisers = (appraisers) =>
    dispatch(updateAppraisers(appraisers));

  const _addAppraiser = (user) => dispatch(addAppraiser(user));
  return { addAppraiser: _addAppraiser, updateAppraisers: _updateAppraisers };
};

export default connect(
  mstp,
  mdtp
)((props) => {
  const { list, appraisers, appraisee, addAppraiser, updateAppraisers } = props;
  const [showAddAppraiser, setShowAddAppraiser] = useState(false);

  const handleUpdateAppraisers = (rowno, appraiserObj) => {
    const newAppraisers = [...appraisers];
    newAppraisers[rowno] = appraiserObj;
    updateAppraisers(newAppraisers);
  };
  const handleAdd = () => {
    setShowAddAppraiser(true);
  };
  const handleOnSelect = (item) => {
    setShowAddAppraiser(false);
    addAppraiser(item);
  };
  const handleRemove = (rowno) => {
    const newAppraisers = appraisers.filter((arr, i) => {
      return i !== rowno;
    });

    updateAppraisers(newAppraisers);
  };
  const formatResult = (item) => {
    return (
      <>
        <span
          style={{
            display: "block",
            textAlign: "left",
          }}
        >
          {item.fullname}
        </span>
      </>
    );
  };
  return (
    <div className="card bordered">
      <div className="card-header" style={{ backgroundColor: "yellow" }}>
        <h3> Appraisers for {appraisee.fullname}</h3>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            {appraisers.map((appraiser, i) => {
              return (
                <AppraiserCard
                  key={i}
                  rowno={i}
                  appraisee={appraisee}
                  appraiserObj={appraiser}
                  onUpdate={handleUpdateAppraisers}
                  onRemove={handleRemove}
                />
              );
            })}
          </div>
        </div>

        <div
          className="card"
          style={{
            backgroundColor: "lightblue",
            boxShadow: "5px 5px 5px gray",
          }}
        >
          <div className="card-body">
            {!showAddAppraiser && (
              <>
                Add More{" "}
                <button className="btn btn-sm btn-primary" onClick={handleAdd}>
                  +
                </button>
              </>
            )}
            {showAddAppraiser && (
              <div className="form-group row">
                <label className="col-4 h4">Appraiser</label>
                <div className="col-8">
                  <ReactSearchAutocomplete
                    items={list}
                    fuseOptions={{ keys: ["fullname"] }}
                    resultStringKeyName="fullname"
                    onSelect={handleOnSelect}
                    autoFocus
                    formatResult={formatResult}
                    onClear={() => {
                      setShowAddAppraiser(false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="card-body"></div>
        </div>
      </div>
    </div>
  );
});
