import React, { useState, useEffect } from "react";
import { Status } from "../../Constants";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { postError } from "../../store/ApplicationSlice";
import { YESNO } from "../../Constants";
// import "./ImportUserForm.css";
import get from "axios";
import { useDispatch } from "react-redux";
// const mstp = (state, ownProps) => {
//   const list = state.MyAppraiserSlice.userList;
//   return { userList: list };
// };

// const mdtp = (dispatch, ownProps) => {
//   const _updateImportedList = (list) => dispatch(updateImportedList(list));

//   const _postError = (e) => dispatch(postError(e));
//   return {
//     updateImportedList: _updateImportedList,

//     postError: _postError,
//   };
// };

const AppraisalListItemSmall = (props) => {
  const [pic, setPic] = useState("");

  const { row_no, record, onView, iam } = props;

  const { appraiser_doc, user_doc, appraisal_doc, appraisers } = record;
  const { fullname, userid } = user_doc;
  const score = appraisal_doc?.totalScore?.averageScore | "--";
  const status = appraisal_doc?.status;

  let workQuality = appraisal_doc?.workQuality?.score | "--";

  workQuality =
    appraisal_doc?.workQuality?.relevance === YESNO.NO
      ? "Not Relevant"
      : workQuality;

  let workEfficiency = appraisal_doc?.workEfficiency?.score | "--";

  workEfficiency =
    appraisal_doc?.workEfficiency?.relevance === YESNO.NO
      ? "Not Relevant"
      : workEfficiency;

  let jobKnowledge = appraisal_doc?.jobKnowledge?.score | "--";

  jobKnowledge =
    appraisal_doc?.jobKnowledge?.relevance === YESNO.NO
      ? "Not Relevant"
      : jobKnowledge;

  let communication = appraisal_doc?.communicationSkills?.score | "--";

  communication =
    appraisal_doc?.communication?.relevance === YESNO.NO
      ? "Not Relevant"
      : communication;
  let workInterpersonalSkills =
    appraisal_doc?.interpersonalSkills?.score | "--";

  workInterpersonalSkills =
    appraisal_doc?.workInterpersonalSkills?.relevance === YESNO.NO
      ? "Not Relevant"
      : workInterpersonalSkills;
  let isoQms = appraisal_doc?.isoQualityManagementSystem?.score | "--";

  isoQms =
    appraisal_doc?.isoQms?.relevance === YESNO.NO ? "Not Relevant" : isoQms;

  let attenance = appraisal_doc?.attenance?.totalScore?.score | "--";

  attenance =
    appraisal_doc?.attenance?.relevance === YESNO.NO
      ? "Not Relevant"
      : attenance;

  let supervisorSkill =
    appraisal_doc?.supervisorySkills?.totalScore?.score | "--";

  supervisorSkill =
    appraisal_doc?.supervisorySkills?.relevance === YESNO.NO
      ? "Not Relevant"
      : supervisorSkill;

  const handleClick = () => {
    console.log(1);
    onView && onView(row_no, record);
  };
  useEffect(() => {
    let isMounted = true;

    get(
      "https://eleave.hslnet.my/apiv2/public/index.php/employeephoto/" + userid
    )
      .then((res) => {
        if (isMounted) {
          setPic(res.data.photo);
        }
      })
      .catch((e) => {});

    return () => {
      isMounted = false;
    };
  }, [userid]);
  return (
    <Card
      className="scorecard"
      style={{ marginBottom: "3em", cursor: "pointer" }}
      onClick={() => handleClick()}
    >
      <Card.Header className={status}>{status}</Card.Header>
      <Card.Body style={{ padding: "1em" }}>
        <Row style={{ marginBottom: "1em" }}>
          <Col xs={8}>
            <h1>{fullname}</h1>
            {iam === appraisers?.proxy?.userid && (
              <span style={{ fontWeight: "100", fontSize: "small" }}>
                delegated to you by {appraisers?.appraiser?.fullname}
              </span>
            )}

            {appraiser_doc && (
              <span style={{ fontSize: "small", float: "right" }}>
                accessed by {appraiser_doc.fullname}
              </span>
            )}
            {appraisal_doc === undefined && (
              <p>
                Click here to begin filling up staff annual performance
                evaluation for {fullname}
              </p>
            )}
          </Col>
          <Col xs={4}>
            {pic && (
              <img
                alt="xx"
                src={pic}
                style={{
                  borderRadius: "50%",
                  width: "8em",
                  float: "right",
                }}
              />
            )}
          </Col>
        </Row>
        {appraisal_doc !== undefined && (
          <Row>
            <Col xs={1}></Col>
            <Col xs={9}>
              <Row>
                <Card className="minicard">
                  <Row>
                    <Col xs={8}>
                      <label>Work Quality</label>
                    </Col>
                    <Col className="scoremark" xs={4}>
                      {workQuality}
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <Card className="minicard">
                  <Row>
                    <Col>
                      <label className="scorelabel">Work Efficiency</label>
                    </Col>
                    <Col className="scoremark" xs={4}>
                      {workEfficiency}
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <Card className="minicard">
                  <Row>
                    <Col>
                      <label className="scorelabel">Job Knowledge</label>
                    </Col>
                    <Col className="scoremark" xs={4}>
                      {jobKnowledge}
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <Card className="minicard">
                  <Row>
                    <Col>
                      <label className="scorelabel">Communication Skills</label>
                    </Col>
                    <Col className="scoremark" xs={4}>
                      {communication}
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <Card className="minicard">
                  <Row>
                    <Col>
                      <label className="scorelabel">Interpersonal</label>
                    </Col>
                    <Col className="scoremark" xs={4}>
                      {workInterpersonalSkills}
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <Card className="minicard">
                  <Row>
                    <Col>
                      <label className="scorelabel">ISO QMS</label>
                    </Col>
                    <Col className="scoremark" xs={4}>
                      {isoQms}
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <Card className="minicard">
                  <Row>
                    <Col>
                      <label className="scorelabel">Attendance</label>
                    </Col>
                    <Col className="scoremark" xs={4}>
                      {attenance}
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <Card className="minicard">
                  <Row>
                    <Col>
                      <label className="scorelabel">Supervisor Skill</label>
                    </Col>
                    <Col className="scoremark" xs={4}>
                      {supervisorSkill}
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Col>
            <Col
              xs={2}
              style={{
                fontSize: "1.5em",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              {appraisal_doc !== undefined && <h2>{score}</h2>}
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};
export default AppraisalListItemSmall;
