import { createSlice } from "@reduxjs/toolkit";

import moment from "moment";

const initialState = {
  dirty: false,
  company: {},
  companyList: [],
  userInCompany: [],
  userList: [],
  userWoCompany: [],
};

const slice = createSlice({
  name: "companySlice",
  initialState: initialState,
  reducers: {
    addCompany: (state, action) => {
      const newName = action.payload;
      const exists = state.companyList.filter((r) => {
        return r.name.toUpperCase() === newName.toUpperCase();
      }).length;

      if (exists === 0) {
        const newCompany = {
          companyid: action.payload + "" + moment().valueOf(),
          name: action.payload,
          staff_doc: [],
        };

        return {
          ...state,
          dirty: true,
          companyList: [...state.companyList, newCompany],
        };
      } else {
        return { ...state };
      }
    },
    loadCompanyList: (state, action) => {
      const newCompanyList = [...action.payload];

      let userWCompany = [];

      newCompanyList.forEach((company) => {
        company.staff_doc.forEach((staff_doc) => {
          userWCompany = [...userWCompany, staff_doc.userid];
        });
      });

      const newUserWoCompany = state.userList?.filter((user) => {
        const exists = userWCompany.filter((user2) => {
          return user.userid === user2;
        }).length;
        return exists === 0;
      });

      return {
        ...state,
        companyList: newCompanyList,
        userWoCompany: newUserWoCompany,
      };
    },
    selectCompany: (state, action) => {
      const company = action.payload;

      return {
        ...state,
        userInCompany: [...company.staff_doc],
        company: company,
      };
    },
    removeCompany: (state, action) => {
      const company = action.payload;

      const newCompanyList = state.companyList.filter((d) => {
        return d.name.toUpperCase() !== company.name.toUpperCase();
      });
      let newUserWoCompany = [...state.userWoCompany];
      company.staff_doc.forEach((s) => {
        const newUser = state.userList
          .filter((user) => {
            return user.userid === s.userid;
          })
          .shift();
        newUserWoCompany = [...newUserWoCompany, newUser];
      });
      newUserWoCompany = newUserWoCompany.sort((a, b) => {
        return a?.fullname
          ?.toUpperCase()
          ?.localeCompare(b?.fullname?.toUpperCase());
      });
      return {
        ...state,
        dirty: true,
        userWoCompany: newUserWoCompany,
        companyList: newCompanyList,
        userInCompany: [],
        company: {},
      };
    },
    updateUserWoCompany: (state, action) => {
      return { ...state, userWoCompany: action.payload };
    },
    removeUserToCompany: (state, action) => {
      const removedUser = action.payload;
      let newUserWoCompany = [...state.userWoCompany];
      let newCompanyList = state.companyList;
      let newUserInCompany = state.userInCompany.filter((user) => {
        return user.userid !== removedUser.userid;
      });
      newUserWoCompany = newUserWoCompany.filter((d) => {
        return d.userid !== removedUser.userid;
      });
      newUserWoCompany = [...newUserWoCompany, removedUser];

      newCompanyList = state.companyList.map((company) => {
        if (company.companyid === state.company.companyid) {
          return { ...company, staff_doc: newUserInCompany };
        } else {
          return company;
        }
      });

      return {
        ...state,
        dirty: true,
        userWoCompany: newUserWoCompany,
        userInCompany: newUserInCompany,
        companyList: newCompanyList,
      };
    },
    addUserToCompany: (state, action) => {
      //   console.log(state.company?.companyid);
      if (state.company?.companyid === undefined) return state;
      const newUser = action.payload;
      let newUserWoCompany = [...state.userWoCompany];
      let newCompanyList = state.companyList;
      let newUserInCompany = [...state.userInCompany];
      newUserWoCompany = newUserWoCompany.filter((d) => {
        return d.userid !== newUser.userid;
      });
      const exists = state.userInCompany.filter((user) => {
        return user.userid === newUser.userid;
      }).length;
      if (exists === 0) {
        newUserInCompany = [...newUserInCompany, newUser];
      }
      newCompanyList = state.companyList.map((company) => {
        if (company.companyid === state.company.companyid) {
          return { ...company, staff_doc: newUserInCompany };
        } else {
          return company;
        }
      });

      return {
        ...state,
        dirty: true,
        userWoCompany: newUserWoCompany,
        userInCompany: newUserInCompany,
        companyList: newCompanyList,
      };
    },
    loadUserList: (state, action) => {
      const newUserList = action.payload;
      let userWCompany = [];
      state.companyList.forEach((company) => {
        company.staff_doc.forEach((staff_doc) => {
          userWCompany = [...userWCompany, staff_doc.userid];
        });
      });

      const newUserWoCompany = newUserList.filter((user) => {
        const exists = userWCompany.filter((user2) => {
          return user2 === user.userid;
        }).length;
        return exists === 0;
      });
      return {
        ...state,
        userList: newUserList,
        userWoCompany: newUserWoCompany,
      };
    },
    clearDirty: (state, action) => {
      return { ...state, dirty: false };
    },
  },
});

export const {
  addCompany,
  removeCompany,
  loadCompanyList,
  loadUserList,
  selectCompany,
  addUserToCompany,
  removeUserToCompany,
  clearDirty,
} = slice.actions;
export default slice.reducer;
