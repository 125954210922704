import { createSlice } from "@reduxjs/toolkit";
import { YESNO, Status, SupervisorFormMode } from "../Constants";

import moment from "moment";

const initialState = {
  search: "",
  orphanOnly: false,
  dirty: YESNO.NO,
  appraisee: null,
  staffs: [],
};

const slice = createSlice({
  name: "AppraiseeListSlice",
  initialState: initialState,
  reducers: {
    updateSearch: (state, action) => {
      return { ...state, search: action.payload };
    },
    updateOrphanOnly: (state, action) => {
      return { ...state, orphanOnly: action.payload };
    },
    updateStaffs: (state, action) => {
      // console.log(action.payload);
      return { ...state, staffs: action.payload };
    },
    updateAppraisee: (state, action) => {
      return { ...state, appraisee: action.payload };
    },
    updateAppraisers: (state, action) => {
      const newStaffs = state.staffs.map((staff) => {
        if (staff.userid === state.appraisee.userid) {
          return { ...staff, appraisers: action.payload };
        } else {
          return staff;
        }
      });
      const newAppraisee = { ...state.appraisee, appraisers: action.payload };
      return { ...state, appraisee: newAppraisee, staffs: newStaffs };
    },
    addAppraiser: (state, action) => {
      const user = action.payload;

      const appraisers = state.appraisee?.appraisers || [];

      const newAppraiser = {
        appraiser: user,
        proxy: {},
        score: 90,
        status: Status.PENDING,
      };

      const exists = appraisers.filter((item) => {
        return item.appraiser.userid === user.userid;
      })?.length;

      if (!exists) {
        const newStaffs = state.staffs.map((staff) => {
          if (staff.userid === state.appraisee.userid) {
            return { ...staff, appraisers: [...appraisers, newAppraiser] };
          } else {
            return staff;
          }
        });

        const newAppraisee = {
          ...state.appraisee,
          appraisers: [...appraisers, newAppraiser],
        };

        return { ...state, appraisee: newAppraisee, staffs: newStaffs };
      } else {
        return state;
      }
    },
    removeAppraiser: (state, action) => {
      const toRemove = action.payload;
      const appraisers = state.appraisee.appraisers;
      const newAppraisers = appraisers.filter((item) => {
        return item.appraiser.userid !== toRemove.appraiserid;
      });
      const newStaffs = state.staffs.map((staff) => {
        if (staff.userid === state.appraisee.userid) {
          return { staff, appraisers: newAppraisers };
        } else {
          return staff;
        }
      });
      const newAppraisee = {
        ...state.appraisee,
        appraisers: newAppraisers,
      };
      return { ...state, appraisee: newAppraisee, staffs: newStaffs };
    },
  },
});

export const {
  updateSearch,
  updateOrphanOnly,
  updateAppraisee,
  updateAppraisers,
  addAppraiser,
  updateStaffs,
  removeAppraiser,
} = slice.actions;
export default slice.reducer;
