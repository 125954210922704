import React, { useState, useEffect } from "react";
import { NOTRELEVANT, YESNO } from "../Constants";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./stats.css";

export const Score2 = (props) => {
  let relevance = props.score?.relevance ? props.score?.relevance : YESNO.YES;
  let score = props.score?.score;
  let averageScore = props.score?.averageScore;

  score = averageScore === undefined ? +score : +averageScore;

  const vw = (multiplier) => {
    var w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    return (multiplier * w) / 100;
  };
  const arcEnd = (score) => {
    const score2 = +score;
    return vw(2) * 1 * 3.14 * ((2 * (100 - score2)) / 100);
  };
  let stroke = "red";
  if (relevance === YESNO.NO) {
    stroke = "white";
  } else if (score >= 90) {
    stroke = "darkgreen";
  } else if (score >= 80) {
    stroke = "yellowgreen";
  } else if (score >= 60) {
    stroke = "orange";
  } else if (score >= 50) {
    stroke = "gold";
  } else if (score >= 20) {
    stroke = "red";
  } else {
    stroke = "white";
  }
  return (
    <>
      {relevance === YESNO.NO && (
        <div style={{ height: "6vw", fontWeight: "100" }}>
          <div style={{ marginTop: "1.5em", textAlign: "center" }}>n/a</div>
        </div>
      )}
      {relevance !== YESNO.NO && (
        <div className="skill">
          <div className="outer">
            <div className="inner">
              <div id="number">{score}</div>
            </div>
          </div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="6vw"
            height="6vw"
            transform="rotate(135)"
            style={{ position: "absolute", top: "0", left: "0" }}
          >
            <defs>
              <linearGradient id="GradientColor">
                <stop offset="0%" stopColor="red" />
                <stop offset="100%" stopColor="blue" />
              </linearGradient>
            </defs>
            <circle className="stats" cx="3vw" cy="3vw" r="2vw" stroke={stroke}>
              <animate
                attributeName="stroke-dashoffset"
                fill="freeze"
                from={vw(2) * 2 * 3.14}
                to={arcEnd(score)}
                dur="0.5s"
                restart="never"
                calcMode="linear"
              />
            </circle>
          </svg>
        </div>
      )}
    </>
  );
};

const Score = (props) => {
  const [end, setEnd] = useState(0);
  const [stroke, setStroke] = useState("white");

  const [relevant, setRelevant] = useState(true);
  const arcEnd = (score) => {
    const score2 = +score;
    return vw(2) * 1 * 3.14 * ((2 * (100 - score2)) / 100);
  };
  useEffect(() => {
    if (props.score === NOTRELEVANT) {
      setEnd(arcEnd(0));
      setRelevant(false);
      setStroke("white");
    } else {
      const score = props.score;
      setEnd(+props.score);
      setRelevant(true);
      let stroke = "red";
      if (!relevant) {
        stroke = "white";
      } else if (score >= 90) {
        stroke = "darkgreen";
      } else if (score >= 85) {
        stroke = "green";
      } else if (score >= 70) {
        stroke = "yellowgreen";
      } else if (score >= 60) {
        stroke = "orange";
      } else if (score >= 50) {
        stroke = "gold";
      } else if (score >= 20) {
        stroke = "red";
      } else {
        stroke = "white";
      }
      setStroke(stroke);
      setEnd(arcEnd(score));
    }
  }, [props.score]);

  const vw = (multiplier) => {
    var w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    return (multiplier * w) / 100;
  };

  return (
    <>
      {!relevant && (
        <div style={{ height: "6vw", fontWeight: "100" }}>
          <div style={{ marginTop: "1.5em", textAlign: "center" }}>n/a</div>
        </div>
      )}
      {relevant && (
        <div className="skill">
          <div className="outer">
            <div className="inner">
              <div id="number">{Math.round(+props.score)}</div>
            </div>
          </div>

          <svg
            name="svg2"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="6vw"
            height="6vw"
            transform="rotate(135)"
            style={{ position: "absolute", top: "0", left: "0" }}
          >
            <circle className="stats" cx="3vw" cy="3vw" r="2vw" stroke={stroke}>
              <animate
                attributeName="stroke-dashoffset"
                fill="freeze"
                from={vw(2) * 2 * 3.14}
                to={end}
                dur="0.5s"
                calcMode="linear"
              />
            </circle>
          </svg>
        </div>
      )}
    </>
  );
};

export const Score3 = (props) => {
  const [score, setScore] = useState(0);
  const [relevant, setRelevant] = useState(true);
  useEffect(() => {
    if (props.score === NOTRELEVANT) {
      setScore(0);
      setRelevant(false);
    } else {
      setScore(+props.score);
      setRelevant(true);
    }
  }, [props.score]);

  const vw = (multiplier) => {
    var w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    return (multiplier * w) / 100;
  };
  const arcEnd = (score) => {
    const score2 = +score;
    return vw(2) * 1 * 3.14 * ((2 * (100 - score2)) / 100);
  };

  let stroke = "red";
  if (!relevant) {
    stroke = "white";
  } else if (score >= 90) {
    stroke = "darkgreen";
  } else if (score >= 85) {
    stroke = "green";
  } else if (score >= 70) {
    stroke = "yellowgreen";
  } else if (score >= 60) {
    stroke = "orange";
  } else if (score >= 50) {
    stroke = "gold";
  } else if (score >= 20) {
    stroke = "orangered";
  } else if (score > 0) {
    stroke = "red";
  } else {
    stroke = "white";
  }

  return (
    <>
      {!relevant && (
        <div style={{ height: "6vw", fontWeight: "100" }}>
          <div style={{ marginTop: "1.5em", textAlign: "center" }}>n/a</div>
        </div>
      )}
      {relevant && (
        <div className="skill">
          <CircularProgressbar
            maxValue={100}
            value={score}
            text={`${score}`}
            styles={{
              // Customize the root svg element
              root: {},
              // Customize the path, i.e. the "completed progress"
              path: {
                // Path color
                textColor: "#f88",
                stroke: stroke,
              },
            }}
          />
        </div>
      )}
    </>
  );
};
export default Score;
