import React, { useState } from "react";
import { connect } from "react-redux";
import { removeUserToCompany } from "../../store/CompanySlice";
import { useDispatch } from "react-redux";
import MyPaginate from "../../components/MyPaginate";
const mstp = (state, ownProps) => {
  const list = state.companySlice.userInCompany;
  return { list };
};

export default connect(mstp)((props) => {
  const dispatch = useDispatch();
  const handleRemove = (u) => {
    dispatch(removeUserToCompany(u));
  };

  const { list } = props;
  const [currentList, setCurrentItems] = useState(list);

  return (
    <>
      <MyPaginate list={list} setCurrentItems={setCurrentItems} />
      <ol>
        {currentList.map((u, i) => {
          return (
            <li key={i}>
              {u.fullname}
              <span className="action">
                <button onClick={(e) => handleRemove(u)}>-</button>
              </span>
            </li>
          );
        })}
      </ol>
    </>
  );
});
