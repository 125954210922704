import React, { useEffect, useState } from "react";

const InspirationalWordFader = (props) => {
  const inspirational = [
    "Always on time (or even early) for meetings and conferences.",

    "Prompt and on time for the start of each workday.",

    "Respects others by arriving at work and at meetings on time.",

    "Adheres to the schedule whenever possible.",

    "Never been a no call, no show employee.",

    "Achieved perfect attendance over X (weeks, months, years).",

    "Inspires others to improve their attendance.",

    "Does not deviate from the attendance policy outlined in our employee handbook.",

    "Begins each day on time and ready to go.",

    "Very reliable about being at work on time.",

    "Has a cheerful attitude that benefits her teammates.",

    "Looks for the positive in every situation.",

    "Quick with a smile and a joke to lighten the mood during stressful times.",

    "Does not let difficult circumstances get him down.",

    "Positive attitude helps others on her team keep their motivation high.",

    "Always reports to work cheerful and ready to get to work.",

    "Maintains a steady and positive attitude that inspires others.",

    "Frequently has a smile for others.",

    "Attitude reflects enjoyment of the job.",

    "Builds an atmosphere of trust with others on the team.",

    "Excellent at customer service.",

    "Deals easily with all types of customers.",

    "Takes great pride in helping each and every customer.",

    "Skillfully overcomes client objections.",

    "Does not let a customer’s negative attitude get him/her down.",

    "Handles difficult customer service situations very well.",

    "Customer satisfaction rating: High",

    "One of our best customer service team members.",

    "Understands how to make a real difference in customer experience.",

    "Stays calm and rational in the face of angry customers.",

    "One of our most dependable team members.",

    "Very reliable in all situations.",

    "Willing to do whatever it takes to get the job done.",

    "Known for dependability and willingness to work hard.",

    "A loyal and trustworthy employee.",

    "Consistently demonstrates that he/she cares about his/her job.",

    "Always performs at or above expectations.",

    "Can always be counted on to complete tasks in a timely and accurate manner.",

    "Ready to get the job done no matter how much work is involved.",

    "Motivated to finish tasks and assignments on time.",

    "Accepts constructive criticism and works to improve.",
    "Shows ability to come up with new solutions to common problems.",

    "Is willing to change the way he/she works for the betterment of the team.",

    "Shows initiative and flexibility when starting a new task.",

    "Capable of handling a variety of assignments.",

    "Able to identify new and more efficient methods.",

    "Calm under pressure.",

    "Willing to admit he/she is wrong.",

    "Quick to adapt to alternate points of view.",

    "Handles change well.",

    "Has strong relationships with coworkers.",

    "Is easy to get to know.",

    "Actively converses with teammates and wants to hear about their lives.",

    "Wants to get to know and understand other employees.",

    "Finds it easy to connect with coworkers.",

    "Makes people feel important.",

    "Makes people feel appreciated.",

    "Can work with a variety of personalities.",

    "Relates well to those around him/her.",

    "Connects easily with others.",

    "Always willing to help a coworker.",

    "Makes team members feel comfortable in voicing their opinions and ideas.",

    "Understands strengths of coworkers and delegates effectively.",

    "Keeps team engaged and on track.",

    "Shows appreciation for a job well done.",

    "Motivates team members to work hard.",

    "Promotes a culture of learning and understanding.",

    "Actively listens and responds to what his/her coworkers say.",

    "Excellent example for others to follow.",

    "Backbone of his/her team.",

    "Exceeded expectations on goals set during last performance review.",

    "Takes initiative to understand what needs to be done.",

    "Excels at developing strategies that deliver results.",

    "Sets appropriate goals and strives to accomplish them.",

    "Is consistently a top performer among teammates.",

    "Clearly communicates drive and desire to others.",

    "Always at the top of the performance-rating scale.",

    "Puts high value on doing a good job.",

    "Willing to assist others and help them do good work.",

    "Wants to improve at everything he/she does.",

    "Proven team player.",

    "Encourages teammates to work together toward a common goal.",

    "Willing to offer assistance and advice at any time.",

    "Promotes a team-centered workplace.",

    "Is sensitive and considerate of coworkers’ feelings.",

    "Shares ideas and techniques.",

    "Builds strong relationships with others by (insert behavior here).",

    "Willing to cooperate with coworkers.",

    "Takes on more work to help the team excel.",

    "Always looking for new ways to help the team.",

    "Respects the time of coworkers.",

    "Uses time effectively to get the job done.",

    "Keeps presentations on schedule.",

    "Regularly meets all deadlines.",

    "Works hard to stay organized and on time.",

    "Driven to complete tasks on time.",

    "Very reliable when it comes to time management.",

    "Can always depend on him/her to manage time well.",

    "Can identify what needs to be done first in order to save time.",

    "Sensitive to the constraints of coworkers’ projects.",
  ];
  const [text, setText] = useState(
    inspirational[Math.round(Math.random() * inspirational.length)]
  );
  const [fadeProp, setFadeProp] = useState({
    fade: "inspirational fade-in",
  });
  useEffect(() => {
    const timeout = setInterval(() => {
      if (fadeProp.fade === "inspirational fade-in") {
        setFadeProp({
          fade: "inspirational fade-out",
        });
      } else {
        const idx = Math.round(Math.random() * inspirational.length);
        setText(inspirational[idx]);
        setFadeProp({
          fade: "inspirational fade-in",
        });
      }
    }, 2000);
    return () => clearInterval(timeout);
  }, [fadeProp]);
  return <p className={fadeProp.fade}>{text}</p>;
};
export default InspirationalWordFader;
