import React, { useState, useEffect } from "react";
import "./Login.css";
import UserService from "../../services/UserService.js";
import { useNavigate } from "react-router-dom";
import { setCurrentUser, clearCurrentUser } from "../../store/ApplicationSlice";
import { useDispatch } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  // const [username, changeUserName] = useState("spyong");
  // const [password, changePassword] = useState("332111");
  const dispatch = useDispatch();
  const [username, changeUserName] = useState("");
  const [password, changePassword] = useState("");

  const handleLogin = (ev) => {
    UserService.doLogin(username, password)
      .then((parsed_token) => {
        console.log(parsed_token);
        dispatch(setCurrentUser(parsed_token));
        navigate("/Main");
      })
      .catch((e) => {
        alert("Login Failed");
      });
    // ev.preventDefault();
    // kc_login(username, password)
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  };
  const handleKeyUp = (ev) => {
    if (ev.code === "Enter") {
      handleLogin(null);
    }
  };
  return (
    <div className="Cover">
      <div className="Login">
        <div className="card">
          <div className="card-header">Staff Appraisal 2023</div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="d-flex flex-column">
                  <div>
                    <div
                      className="card-title mb-3"
                      style={{
                        textAlign: "center",
                        marginTop: "1em",

                        fontWeight: "500",
                      }}
                    >
                      <div style={{ fontSize: "3em" }}>
                        STAFF ANNUAL EVALUATION
                      </div>
                      <div style={{ color: "blue", fontSize: "2.5em" }}>
                        FOR 2023
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-auto"
                    style={{
                      padding: "1em",
                      fontSize: "2em",
                      fontWeight: "300",
                    }}
                  >
                    <div className="form-group row mb-3">
                      <input
                        className="form-control"
                        style={{
                          fontSize: "1em",
                          fontWeight: "300",
                        }}
                        value={username}
                        placeholder="username"
                        onChange={(ev) => changeUserName(ev.target.value)}
                      />
                    </div>
                    <div className="form-group row">
                      <input
                        className="form-control"
                        style={{
                          fontSize: "1em",
                          fontWeight: "300",
                        }}
                        value={password}
                        type="password"
                        placeholder="password"
                        onChange={(ev) => changePassword(ev.target.value)}
                        onKeyUp={handleKeyUp}
                      />
                    </div>
                    <div className="mb-3 mt-3">
                      <div className="d-flex justify-content-between">
                        <div></div>
                        <div>
                          <div className="row">
                            <button
                              style={{
                                fontSize: "1em",
                                fontWeight: "300",
                                borderRadius: "0.5vw",
                                paddingLeft: "1em",
                                paddingRight: "1em",
                              }}
                              className="btn btn-primary"
                              onClick={handleLogin}
                            >
                              LOGIN
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-md-block col-md-4">
                <div className="loginArt"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
