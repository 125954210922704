import React, { useEffect, useState, useCallback } from "react";
import { Score2 as Score } from "../../components/Score";

import "./SupervisorAppraisalListMain.css";
import StatusBall from "../../components/StatusBall";
import { get } from "axios";

const AppraisalListItemRow = (props) => {
  const [pic, setPic] = useState(false);
  const { record, row_no, onView, iam } = props;

  const { appraiser_doc, appraisal_doc, user_doc, appraisers } = record;
  const { fullname, userid } = user_doc;

  const status = appraisal_doc?.status;

  const getPhoto = useCallback((userid, isMounted, setPic2) => {
    get(
      "https://eleave.hslnet.my/apiv2/public/index.php/employeephoto/" + userid
    )
      .then((res) => {
        if (isMounted) {
          setPic2(res.data.photo);
        }
      })
      .catch((e) => {});
  });
  useEffect(() => {
    let isMounted = true;

    getPhoto(userid, isMounted, setPic);

    return () => {
      isMounted = false;
    };
  }, [userid]);
  let nameClassName = "ten tableCellName tip  " + status;
  nameClassName = "ten tableCellName tip  ";
  const handleClick = () => {
    onView && onView(row_no, record);
  };
  return (
    <tr style={{ cursor: "pointer" }} onClick={handleClick}>
      <td>{row_no + 1}</td>
      <td className={nameClassName}>
        <div className="d-flex">
          <div className="me-2">
            {pic !== false && (
              <img
                alt="xx"
                src={pic}
                style={{ borderRadius: "50%", width: "3em" }}
              />
            )}
          </div>

          <div>
            <div className="d-flex flex-column">
              <div>
                <h4>
                  <StatusBall status={status} />
                  {fullname?.toUpperCase()}
                </h4>
                {iam === appraisers?.proxy?.userid && (
                  <span style={{ fontWeight: "100", fontSize: "small" }}>
                    delegated to you by {appraisers?.appraiser?.fullname?.toUpperCase()}
                  </span>
                )}
              </div>
              <div>
                {appraiser_doc && (
                  <span style={{ fontSize: "small" }}>
                    acessed by {appraiser_doc.fullname?.toUpperCase()}
                  </span>
                )}
                {/* 
                <span style={{ fontSize: "x-small", fontWeight: "100" }}>
                  {status && <span>( {status} )</span>}
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </td>
      {appraisal_doc === undefined && (
        <td colSpan={9}>
          Click here to fill Employee Annual Performance Evaluation
        </td>
      )}
      {appraisal_doc !== undefined && (
        <>
          <td className="five">
            <Score score={appraisal_doc.workQuality} />
          </td>
          <td className="five">
            <Score score={appraisal_doc.workEfficiency} />
          </td>
          <td className="five">
            <Score score={appraisal_doc.jobKnowledge} />
          </td>
          <td className="five">
            <Score score={appraisal_doc.communicationSkills} />
          </td>
          <td className="five">
            <Score score={appraisal_doc.interpersonalSkills} />
          </td>
          <td className="five">
            <Score score={appraisal_doc.isoQualityManagementSystem} />
          </td>
          <td className="five">
            <Score score={appraisal_doc.attendance} />
          </td>
          <td className="five">
            <Score score={appraisal_doc.supervisorySkills} />
          </td>

          <td className="total">
            {" "}
            <Score score={appraisal_doc.totalScore} />
          </td>
        </>
      )}
    </tr>
  );
};
export default AppraisalListItemRow;
