import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AppraisalList from "./AppraisalList";
import AppraisalForm from "../AppraisalForm/AppraisalForm";
import { updateList } from "../../store/MyAppraisalListSlice";
import {
  createNewAppraisal,
  updateAppraisal,
} from "../../store/AppraisalSlice";
import { postError } from "../../store/ApplicationSlice";
import { get } from "axios";
import "./SupervisorAppraisalListMain.css";
import { LISTVIEW, Status, YEAR, YESNO } from "../../Constants";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const mstp = (state, ownProps) => {
  const currentUser = state.applicationSlice.currentUser;

  return { username: currentUser.preferred_username };
};
const mdtp = (dispatch, ownProps) => {
  const _createNewAppraisal = (v) => dispatch(createNewAppraisal(v));
  const _updateAppraisal = (v) => dispatch(updateAppraisal(v));

  return {
    createNewAppraisal: _createNewAppraisal,
    updateAppraisal: _updateAppraisal,
  };
};
export default connect(
  mstp,
  mdtp
)((props) => {
  const { username, createNewAppraisal, updateAppraisal } = props;
  const dispatch = useDispatch();
  const [mode, setMode] = useState(LISTVIEW.LIST);
  const navigate = useNavigate();

  useEffect(() => {
    get("api/users/username/" + username)
      .then((res) => {
        return new Promise((resolve) => {
          resolve(res.data.userid);
        });
      })
      .then((userid) => {
        get("api/appraisers/user/" + userid).then((res) => {
          const arr = res.data;
          // console.log(arr);
          dispatch(updateList(arr));
        });
      })
      .catch((e) => {
        dispatch(postError(e));
      });
  }, [username]);
  const handleView = (row_no, record) => {
    let appraisal_doc = { ...record.appraisal_doc };
    console.log(appraisal_doc);
    console.log(Object.keys(appraisal_doc).length === 0);
    const user_doc = record.user_doc;
    const appraisee = { ...user_doc };
    const appraiser = {
      userid: record.appraisers?.appraiser?.userid,
      fullname: record.appraisers?.appraiser?.fullname,
    };
    const status = Status.PENDING;
    const proxy = {
      userid: record.appraisers?.proxy?.userid,
      fullname: record.appraisers?.proxy?.fullname,
    };
    console.log(appraisal_doc);
    if (appraisal_doc === undefined) {
      console.log(76);
      createNewAppraisal({
        year: YEAR,
        appraisee: appraisee,
        appraiser: appraiser,
        proxy: proxy,
        status: status,
        trainingRequired: YESNO.YES,
      });
    } else if (Object.keys(appraisal_doc).length === 0) {
      console.log(86);
      createNewAppraisal({
        year: YEAR,
        appraisee: appraisee,
        appraiser: appraiser,
        proxy: proxy,
        status: status,
        trainingRequired: YESNO.YES,
      });
    } else {
      console.log(96);
      appraisal_doc = {
        ...appraisal_doc,
        appraisee: user_doc,
        appraiser: appraiser,
        proxy: proxy,
      };
      console.log(101);
      console.log(101);
      console.log(101);
      console.log(101);
      console.log(101);

      updateAppraisal({ ...appraisal_doc });
    }

    // console.log(record);
    setMode(LISTVIEW.VIEW);
  };
  const handleList = () => {
    setMode(LISTVIEW.LIST);
  };
  const handleGoToMain = (ev) => {
    navigate("../main");
  };
  return (
    <>
      {mode === LISTVIEW.LIST && (
        <AppraisalList onList={handleGoToMain} onView={handleView} />
      )}
      {mode === LISTVIEW.VIEW && <AppraisalForm onList={handleList} />}
    </>
  );
});
