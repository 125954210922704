import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Row, Col } from "react-bootstrap";
import MyPaginate from "../../components/MyPaginate";
import {
  addDepartment,
  selectDepartment,
  removeDepartment,
} from "../../store/DepartmentSlice";

import "./DepartmentForm.css";

const mstp = (state, ownProps) => {
  const list = state.departmentSlice.departmentList;

  return { list };
};
const mdtp = (dispatch, ownProps) => {
  const _addDepartment = (v) => dispatch(addDepartment(v));
  const _selectDepartment = (department) =>
    dispatch(selectDepartment(department));
  const _removeDepartment = (department) =>
    dispatch(removeDepartment(department));
  return {
    addDepartment: _addDepartment,
    selectDepartment: _selectDepartment,
    removeDepartment: _removeDepartment,
  };
};

const DepartmentList = (props) => {
  const { list, addDepartment, removeDepartment, selectDepartment } = props;
  const [showAdd, setShowAdd] = useState(false);
  const [newDepartment, setNewDepartment] = useState("");
  const [currentList, setCurrentItems] = useState([]);
  const handleAddDepartment = (ev) => {
    const newDept = ev.target.value;
    setNewDepartment(newDept);
  };
  const handleKeyUp = (ev) => {
    if (ev.key === "Escape") {
      addDepartment(newDepartment);
      setShowAdd(false);
    } else if (ev.key === "Enter") {
      addDepartment(newDepartment);
      setShowAdd(false);
    }
  };
  const handleShowAddDepartmentButton = (ev) => {
    setShowAdd(!showAdd);
  };
  const handleSelectDepartment = (department) => {
    selectDepartment(department);
  };
  const handleRemove = (department) => {
    if (window.confirm("Confirm remove this?")) {
      removeDepartment(department);
    }
  };
  return (
    <Card style={{ border: "1px solid black" }}>
      <Card.Header style={{ backgroundColor: "lightgreen" }}>
        <div className="d-flex justify-content-between">
          <div>Department</div>
          <div>
            <Button variant="primary" onClick={handleShowAddDepartmentButton}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        {showAdd && (
          <input
            className="form-control"
            onKeyUp={handleKeyUp}
            onChange={handleAddDepartment}
            placeholder="New Department"
          ></input>
        )}

        <MyPaginate list={list} setCurrentItems={setCurrentItems} />
        <ol>
          {currentList.map((d, i) => {
            return (
              <li key={i}>
                <span onClick={(ev) => handleSelectDepartment(d)}>
                  {d.name}
                </span>
                {d?.staff_doc.length ? <sup>{d.staff_doc.length}</sup> : <></>}{" "}
                <span className="action">
                  <Button
                    className="btn btn-sm"
                    variant="warning"
                    onClick={(ev) => handleRemove(d)}
                  >
                    -
                  </Button>
                </span>
              </li>
            );
          })}
        </ol>
      </Card.Body>
    </Card>
  );
};
export default connect(mstp, mdtp)(DepartmentList);
