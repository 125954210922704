import React from "react";
import { Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const Forbidden = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/main");
  };
  return (
    <Card>
      <Card.Body>
        <div className="unauthorized">
          <h1>401</h1>
        </div>
        <h2>Unauthorized Access</h2>
        <p>Your account does not have access to this page.</p>
        <Button onClick={handleLogin}>Go To HomePage</Button>
      </Card.Body>
    </Card>
  );
};
export default Forbidden;
