import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { ReactSearchAutocomplete } from "react-search-autocomplete";

// import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faArrowLeft,
//   faArrowRight,
//   faEye,
// } from "@fortawesome/free-solid-svg-icons";

const mstp = (state, ownProps) => {
  const list = state.appraiseeListSlice.staffs;
  const appraiserObj = ownProps.appraiserObj;
  const appraiser = appraiserObj.appraiser;
  const proxy = appraiserObj.proxy;
  const status = appraiserObj.status;
  const score = appraiserObj.score;

  return { list, appraiser, proxy, status, score };
};
const mdtp = null;
export default connect(
  mstp,
  mdtp
)((props) => {
  const {
    rowno,
    list,
    appraiser,
    proxy,
    appraisee,
    status,
    score,
    onUpdate,
    onRemove,
  } = props;
  const TYPE = {
    APPRAISER: "APPRAISER",
    PROXY: "PROXY",
  };

  const [editAppraiser, setEditAppraiser] = useState(false);
  const [editProxy, setEditProxy] = useState(false);

  const [showRemoveAppraiser, setShowRemoveAppraiser] = useState(false);
  const [showRemoveProxy, setShowRemoveProxy] = useState(false);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log(string, results);
    console.log(string);
    console.log(results);
  };

  const handleOnHover = (result) => {
    // the item hovered
  };

  const handleOnSelect = (item, type) => {
    // the item selected
    switch (type) {
      case TYPE.APPRAISER:
        onUpdate(rowno, {
          appraiser: item,
          proxy: proxy,
          status: status,
          score: score,
        });
        break;
      case TYPE.PROXY:
        onUpdate(rowno, {
          appraiser: appraiser,
          proxy: item,
          status: status,
          score: score,
        });
        break;
      default:
    }
    setEditAppraiser(false);
    setEditProxy(false);
  };

  const handleOnFocus = () => {};

  const formatResult = (item) => {
    return (
      <>
        <span
          style={{
            display: "block",
            textAlign: "left",
          }}
        >
          {item.fullname}
        </span>
      </>
    );
  };
  const handleRemoveAppraiser = (ev) => {
    onUpdate(rowno, {
      appraiser: {},
      proxy: proxy,
      status: status,
      score: score,
    });
    setShowRemoveAppraiser(false);
  };
  const handleRemoveProxy = (ev) => {
    onUpdate(rowno, {
      appraiser: appraiser,
      proxy: {},
      status: status,
      score: score,
    });
    setShowRemoveProxy(false);
  };
  const handleRemoveThis = () => {
    onRemove(rowno);
  };
  const style =
    editAppraiser | editProxy
      ? {
          height: "40vh",
          marginBottom: "0.25em",
          boxShadow: "5px 5px 5px gray",
        }
      : { marginBottom: "0.75em", boxShadow: "5px 5px 5px gray" };
  return (
    <div className="card" style={style}>
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <div />
          <div style={{ cursor: "pointer" }} onClick={handleRemoveThis}>
            x
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="form-group row">
          <label className="col-4 h4">
            <span
              onClick={() => {
                setShowRemoveAppraiser(!showRemoveAppraiser);
              }}
            >
              Appraiser
            </span>
          </label>
          <div className="col-8">
            {editAppraiser === false && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setEditAppraiser(true)}
              >
                <span>{appraiser?.fullname ? appraiser.fullname : "none"}</span>
              </div>
            )}

            {editAppraiser && (
              <ReactSearchAutocomplete
                items={list}
                fuseOptions={{ keys: ["fullname"] }}
                resultStringKeyName="fullname"
                inputSearchString={appraiser?.fullname}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={(item) => handleOnSelect(item, TYPE.APPRAISER)}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatResult}
                onClear={() => {
                  setEditAppraiser(false);
                }}
              />
            )}
            {showRemoveAppraiser && (
              <button
                className="btn-sm btn-danger"
                onClick={handleRemoveAppraiser}
              >
                -
              </button>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-4 h4" style={{ cursor: "pointer" }}>
            <span
              onClick={() => {
                setShowRemoveProxy(!showRemoveProxy);
              }}
            >
              Proxy
            </span>
          </label>
          <div className="col-8">
            {editProxy === false && (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setEditProxy(true)}
              >
                <span>{proxy?.fullname ? proxy.fullname : "none"}</span>
              </div>
            )}

            {editProxy && (
              <ReactSearchAutocomplete
                items={list}
                fuseOptions={{ keys: ["fullname"] }}
                resultStringKeyName="fullname"
                inputSearchString={proxy?.fullname}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={(item) => handleOnSelect(item, TYPE.PROXY)}
                onFocus={handleOnFocus}
                autoFocus
                formatResult={formatResult}
                onClear={() => {
                  setEditProxy(false);
                }}
              />
            )}
            {showRemoveProxy && (
              <button className="btn-sm btn-danger" onClick={handleRemoveProxy}>
                -
              </button>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label className="col-4 h4">Status</label>
          <div className="col-8">{status}</div>
        </div>
      </div>
    </div>
  );
});
