import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import {
  faArrowLeft,
  faArrowRight,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { YESNO } from "../../Constants";
import { addToUserList } from "../../store/ImportUserSlice";

const mstp = (state, ownProps) => {
  const list = state.importUserSlice.importedList;

  return { list: list };
};
const mdtp = (dispatch, ownProps) => {
  const _addToUserList = (user) => dispatch(addToUserList(user));

  return {
    addToUserList: _addToUserList,
  };
};

export default connect(
  mstp,
  mdtp
)((props) => {
  const { list, addToUserList } = props;
  const [currentItems, setCurrentItems] = useState(list);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [search, setSearch] = useState("");
  const itemsPerPage = 20;

  useEffect(() => {
    // Fetch items from another resources.

    let filteredList = [];

    const list2 = [...list].sort((a, b) => {
      return a.fullname.localeCompare(b.fullname);
    });
    if (search === "") {
      filteredList = [...list2];
    } else {
      filteredList = list2.filter((item) => {
        return item.fullname?.toUpperCase().match(search.toUpperCase());
      });
    }

    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);

    setCurrentItems(filteredList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredList.length / itemsPerPage));
  }, [search, itemOffset, itemsPerPage, list]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };
  const handleClick = (ev, u) => {
    // console.log(69);
    addToUserList(u);
  };
  const handleSearchChange = (ev) => {
    setSearch(ev.target.value);
  };
  const handleAddAll = (ev) => {
    let filteredList = [];
    if (search === "") {
      filteredList = [...list];
    } else {
      filteredList = list.filter((item) => {
        return item.fullname?.toUpperCase().match(search.toUpperCase());
      });
    }

    filteredList.forEach((item) => {
      // console.log(item);
      addToUserList(item);
    });
  };
  return (
    <>
      <div className="form-group row">
        <label className="col-12 col-md-4">
          <h5>Search</h5>
        </label>
        <div className="col-12 col-md-8">
          <input
            className="form-control"
            onChange={handleSearchChange}
            value={search}
          />
        </div>
      </div>

      {pageCount > 1 && (
        <div style={{ fontSize: "small" }}>
          <ReactPaginate
            breakLabel="..."
            nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
            renderOnZeroPageCount={null}
            className="paginate"
          />
        </div>
      )}

      <ol>
        {currentItems.map((u, i) => {
          return (
            <li key={i}>
              {u.fullname}{" "}
              <span className="action">
                <Button
                  variant="info"
                  className="btn-sm"
                  onClick={(ev) => {
                    handleClick(ev, u);
                  }}
                >
                  +
                </Button>
              </span>
            </li>
          );
        })}
      </ol>
      <div className="d-flex justify-content-between">
        <div></div>
        <div>
          <Button variant="warning" className="btn-sm" onClick={handleAddAll}>
            Add All
          </Button>
        </div>
      </div>
    </>
  );
});
