import { createSlice } from "@reduxjs/toolkit";
import { YESNO, GRADES, Status, QuestionNos, scores } from "../Constants";
import moment from "moment";

const initialState = {
  importedList: [],
  userList: [],
};

const slice = createSlice({
  name: "importUserSlice",
  initialState: initialState,
  reducers: {
    updateImportedList: (state, action) => {
      return { ...state, importedList: action.payload };
    },
    updateUserList: (state, action) => {
 
      return { ...state, userList: action.payload };
    },
    addToUserList: (state, action) => {
      const candidate = action.payload;

      const exists = state.userList.filter((u) => {
        return u.userid === candidate.userid;
      })?.length;
      if (exists) {
        return state;
      } else {
        return { ...state, userList: [...state.userList, candidate] };
      }
    },

    removeFromUserList: (state, action) => {
      const candidate = action.payload;
      const newList = state.userList.filter((u) => {
        return u.userid !== candidate.userid;
      });
      return { ...state, userList: newList };
    },
    purgetUserList: (state, action) => {
      return { ...state, userList: [] };
    },
  },
});

export const {
  updateUserList,
  updateImportedList,
  addToUserList,
  removeFromUserList,
  purgetUserList,
} = slice.actions;
export default slice.reducer;
