import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const slice = createSlice({
  name: "MyAppraisalListSlice",
  initialState: initialState,
  reducers: {
    updateList: (state, action) => {
      return { list: action.payload };
    },
  },
});

export const { updateList } = slice.actions;
export default slice.reducer;
