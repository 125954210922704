import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./pages/Login/Login";
import MainPage from "./pages/MainPage/MainPage";

import CompanyForm from "./pages/CompanyForm/CompanyMain";
import DepartmentForm from "./pages/DepartmentForm/DepartmentMain";
import AssignAppraiserForm from "./pages/AssignAppraiserForm/AssignAppraiserForm";
import SupervisorAppraisalListMain from "./pages/SupervisorAppraisalListForm/SupervisorAppraisalListMain";
import ImportUserForm from "./pages/ImportUserForm/ImportUserMain";
import DepartmentSubmitted from "./pages/Stats/DepartmentSubmitted";
import SupervisorSubmitted from "./pages/Stats/SupervisorSubmitted";
import AppContainer from "./AppContainer";
import DepartmentScore from "./pages/Stats/DepartmentScore";
import CompanyScore from "./pages/Stats/CompanyScore";
import AppraiseeScore from "./pages/AppraiseeScore/AppraiseeScore";
import AppraiseeReview from "./pages/AppraiseeReview/AppraiseeReview";
import NotFound from "./NotFound";
import Foobar from "./pages/Foobar";

import { useSelector } from "react-redux";
import { ProtectedRoute } from "./services/UserService";

import "./App.css";

const App = () => {
  useEffect(() => {
    document.title = "Appraisal";
  }, []);
  const loggedIn = useSelector((state) => state.applicationSlice.loggedIn);
  return (
    <AppContainer showControl={loggedIn}>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/foobar" element={
            <ProtectedRoute roles={["appraisal_hr"]}>
              <Foobar />
            </ProtectedRoute>            
          } />
          <Route
            path="/ImportUsers"
            element={
              <ProtectedRoute roles={["appraisal_hr"]}>
                <ImportUserForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/DepartmentScore"
            element={
              <ProtectedRoute roles={["appraisal_hr"]}>
                <DepartmentScore />
              </ProtectedRoute>
            }
          />
          <Route
            path="/CompanyScore"
            element={
              <ProtectedRoute roles={["appraisal_hr"]}>
                <CompanyScore />
              </ProtectedRoute>
            }
          />
          <Route
            path="/DepartmentSubmitted"
            element={
              <ProtectedRoute roles={["appraisal_hr"]}>
                <DepartmentSubmitted />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SupervisorSubmitted"
            element={
              <ProtectedRoute roles={["appraisal_hr"]}>
                <SupervisorSubmitted />
              </ProtectedRoute>
            }
          />          
          <Route
            path="/Department"
            element={
              <ProtectedRoute roles={["appraisal_hr"]}>
                <DepartmentForm />
              </ProtectedRoute>
            }
          />{" "}
          <Route
            path="/Company"
            element={
              <ProtectedRoute roles={["appraisal_hr"]}>
                <CompanyForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AppraiseeScore"
            element={
              <ProtectedRoute roles={["appraisal_hr"]}>
                <AppraiseeScore />
              </ProtectedRoute>
            }
          />
          <Route
            path="/AssignAppraiser"
            element={
              <ProtectedRoute roles={["appraisal_hr"]}>
                <AssignAppraiserForm />
              </ProtectedRoute>
            }
          />
          <Route path="/AppraiseeReview" element={<AppraiseeReview />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="/Appraisal" element={<SupervisorAppraisalListMain />} />
          <Route path="/Login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AppContainer>
  );
};

export default App;
