import React from "react";
import { YESNO, QuestionNos } from "../../Constants";
import "./AppraisalForm.css";
import { connect } from "react-redux";
import { updateScore } from "../../store/AppraisalSlice";

import StarRatingComponent from "react-star-rating-component";
import { Score3 as Score } from "../../components/Score";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = (state, ownProps) => {
  let branch = {};

  switch (ownProps.no) {
    case QuestionNos.One:
      branch = { ...state.appraisalSlice.workQuality };
      //   console.log(branch);
      break;
    case QuestionNos.Two:
      branch = { ...state.appraisalSlice.workEfficiency };
      break;
    case QuestionNos.Three:
      branch = { ...state.appraisalSlice.jobKnowledge };
      break;
    case QuestionNos.Four:
      branch = { ...state.appraisalSlice.communicationSkills };
      break;
    case QuestionNos.Five:
      branch = { ...state.appraisalSlice.interpersonalSkills };
      break;
    case QuestionNos.Six:
      branch = { ...state.appraisalSlice.isoQualityManagementSystem };
      break;
    case QuestionNos.Seven:
      branch = { ...state.appraisalSlice.attendance };
      break;
    case QuestionNos.Eight:
      branch = { ...state.appraisalSlice.supervisorySkills };
      break;
    default:
  }
  // console.log(branch);
  return branch;
  //   const { grade, relevance, score, remark, extraRemark, actionRequired } =
  //     branch;
  //   return { grade, relevance, score, remark, extraRemark, actionRequired };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const update = (payload) => dispatch(updateScore(payload));
  return { updateScore: update };
};
const AppraisalPart1Question = (props) => {
  const {
    readOnly,
    grade,
    relevance,
    score,
    remark,
    remarkValid,
    remarkOptional,
    extraRemark,
    actionRequired,
    no,
    title,
    question,
    tips,
    updateScore,
  } = props;
  // console.log(props);
  let numberOfStars = 2;
  if (+score === 100) {
    numberOfStars = 5;
  } else if (+score >= 85) {
    numberOfStars = 4;
  } else if (+score >= 70) {
    numberOfStars = 3;
  } else if (+score >= 50) {
    numberOfStars = 2;
  } else if (+score >= 30) {
    numberOfStars = 1;
  } else {
    numberOfStars = 0;
  }

  const handleStarClick = (nxValue, prevValue, name) => {
    if (readOnly) return;
    let nxValue2 = nxValue;
    if (+grade === 1 && +nxValue === 1 && +prevValue === 1) {
      nxValue2 = 0;
    }
    let remarkOptional = true;
    let remarkValid = true;
    if (+nxValue2 === 0 || +nxValue2 === 1 || actionRequired === YESNO.YES) {
      remarkOptional = false;
    }
    console.log({
      questionNo: no,
      grade: nxValue2,
      relevance: relevance,
      remark: remark,
      remarkOptional: remarkOptional,
      remarkValid: remarkValid,
      extraRemark: extraRemark,
      actionRequired: actionRequired,
    });
    if (remark.length <= 2 && !remarkOptional) {
      remarkValid = false;
    }
    // console.log({
    //   questionNo: no,
    //   grade: nxValue2,
    //   relevance: relevance,
    //   remark: remark,
    //   remarkOptional: remarkOptional,
    //   remarkValid: remarkValid,
    //   extraRemark: extraRemark,
    //   actionRequired: actionRequired,
    // });
    updateScore({
      questionNo: no,
      grade: nxValue2,
      relevance: relevance,
      remark: remark,
      remarkOptional: remarkOptional,
      remarkValid: remarkValid,
      extraRemark: extraRemark,
      actionRequired: actionRequired,
    });
  };
  const handleChangeRelevance = () => {
    if (readOnly) return;
    updateScore({
      questionNo: no,
      grade: grade,
      relevance: relevance === YESNO.YES ? YESNO.NO : YESNO.YES,
      remark: remark,
      remarkOptional: true,
      remarkValid: true,
      extraRemark: extraRemark,
      actionRequired: actionRequired,
    });
    // setRelevance(relevance === YESNO.YES ? YESNO.NO : YESNO.YES);
  };
  const handleChangeActionRequired = () => {
    if (readOnly) return;
    const newActionRequired =
      actionRequired === YESNO.YES ? YESNO.NO : YESNO.YES;
    let newRemarkOptional = true;
    let newRemarkValid = true;

    if (relevance === YESNO.NO) {
      // console.log(151);
      newRemarkOptional = true;
    } else if (newActionRequired === YESNO.YES) {
      // console.log(154);
      newRemarkOptional = false;
    } else if (+grade < 2) {
      // console.log(157);
      newRemarkOptional = false;
    }

    // console.log(newRemarkOptional);
    if (!newRemarkOptional && remark.length <= 2) {
      console.log(163);
      newRemarkValid = false;
    }
    updateScore({
      questionNo: no,
      grade: grade,
      relevance: relevance,
      remark: remark,
      remarkOptional: newRemarkOptional,
      remarkValid: newRemarkValid,
      extraRemark: extraRemark,
      actionRequired: newActionRequired,
    });
    // setActionRequired(actionRequired === YESNO.YES ? YESNO.NO : YESNO.YES);
  };
  const handleChangeRemark = (ev) => {
    if (readOnly) return;
    const newRemark = ev.target.value;
    let newRemarkValid = true;
    if (actionRequired && newRemark.length <= 2) {
      newRemarkValid = false;
    }

    updateScore({
      questionNo: no,
      grade: grade,
      relevance: relevance,
      remark: newRemark,
      remarkOptional: remarkOptional,
      remarkValid: newRemarkValid,
      extraRemark: extraRemark,
      actionRequired: actionRequired,
    });
  };
  // console.log(remarkValid);
  let remarkClass = remarkValid ? "form-control" : "form-control is-invalid";

  return (
    <div
      className="card"
      style={{
        border: "1px solid black",
        boxShadow: "5px 5px 10px gray",
        backgroundColor: "GhostWhite",
        marginBottom: "1.5em",
      }}
    >
      <div className="card-header row">
        <div className="col-12 col-lg-9" style={{ marginBottom: "1vw" }}>
          <div>
            <span className="questionNumber">{no}</span>
            <span className="questionTitle">{title}</span>
            <span className="relevantLabel">relevant</span>
            <input
              type="checkbox"
              id="relevance"
              value="yes"
              onChange={handleChangeRelevance}
              checked={relevance === YESNO.YES}
              style={{ marginLeft: "1vw" }}
            ></input>
          </div>
          <div className="question">{question}</div>
        </div>
        <div className="col-12 col-lg-3">
          <div className="d-flex flex-nowrap justify-content-end">
            {relevance === YESNO.NO && (
              <span className="h3 text-danger">Not Applicable</span>
            )}
            {relevance === YESNO.YES && (
              <span className="starRating">
                <StarRatingComponent
                  name="foobar"
                  editing={true}
                  starCount={5}
                  value={numberOfStars}
                  onStarClick={handleStarClick}
                />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="row">
            <div className="col-9">
              <ul>
                {tips.map((tip) => {
                  return <li>{tip}</li>;
                })}
              </ul>

              <h6>Comments</h6>
              {remarkValid === false && (
                <span className="smallRemark">Minimum 3 characters</span>
              )}
              <textarea
                rows={4}
                className={remarkClass}
                value={remark}
                onChange={handleChangeRemark}
              >
                {remark}
              </textarea>
            </div>
            <div className="col-3" style={{ fontSize: "small" }}>
              {relevance === YESNO.YES && (
                <div className="d-flex flex-column">
                  <div>
                    <span className="score" style={{ float: "right" }}>
                      <Score score={score} />
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: "2em",
                      textAlign: "left",
                      fontWeight: "650",
                    }}
                  >
                    <span>Competency Status</span>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      id="ActionRequired"
                      className="form-check-input"
                      onChange={handleChangeActionRequired}
                      checked={actionRequired === YESNO.YES}
                    ></input>
                    <label className="form-check-label">Action Required</label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppraisalPart1Question);
