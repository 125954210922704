import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBinoculars } from "@fortawesome/free-solid-svg-icons";
import MyPaginate from "../../components/MyPaginate";
import { addUserToCompany } from "../../store/CompanySlice";
import { Card, Button } from "react-bootstrap";
const mstp = (state, ownProps) => {
  const list = state.companySlice.userWoCompany;

  return { list: list };
};

const mdtp = (dispatch, ownProps) => {
  const _addUserToCompany = (user) => dispatch(addUserToCompany(user));
  return { addUserToCompany: _addUserToCompany };
};

export default connect(
  mstp,
  mdtp
)((props) => {
  const { list, addUserToCompany } = props;
  const [filteredList, setFilteredList] = useState(list);

  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState(""); /* Actual Search*/

  const [currentList, setCurrentItems] = useState([]);
  useEffect(() => {
    if (search !== "") {
      const list2 = list.filter((name) => {
        return name.fullname?.toUpperCase().match(search.toUpperCase());
      });

      setFilteredList(list2);
    } else {
      setFilteredList([...list]);
    }
  }, [search, list]);
  const handleAdd = (user) => {
    addUserToCompany(user);
  };
  const handleSearchChange = (ev) => {
    const str = ev.target.value;

    const replaced = str.replace(/[^a-z0-9 ]/gi, "");
    setSearch(replaced);
  };
  const handleSearchKeyUp = (ev) => {
    switch (ev.key) {
      case "Escape":
        setShowSearch(false);
        break;
      default:
    }
  };
  const handleShowSearch = (ev) => {
    setShowSearch(!showSearch);
  };
  return (
    <Card style={{ border: "1px solid black" }}>
      <Card.Header style={{ backgroundColor: "lightblue" }}>
        <div className="d-flex justify-content-between">
          <div>Unassigned Staffs</div>
          <div>
            <Button
              className="btn-sm"
              variant="primary"
              onClick={handleShowSearch}
            >
              <FontAwesomeIcon icon={faBinoculars} />
            </Button>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        {showSearch && (
          <div className="form-group row">
            <input
              type="text"
              placeholder="search"
              className="form-control"
              onChange={handleSearchChange}
              onKeyUp={handleSearchKeyUp}
              value={search}
            />
          </div>
        )}
        <MyPaginate list={filteredList} setCurrentItems={setCurrentItems} />
        <ol>
          {currentList.map((u, i) => {
            return (
              <li key={i}>
                {u.fullname}{" "}
                <span className="action">
                  <button
                    className="btn btn-sm btn-info"
                    onClick={(ev) => handleAdd(u)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </span>
              </li>
            );
          })}
        </ol>
      </Card.Body>
    </Card>
  );
});
