import React from "react";
import { StatusColor } from "../Constants";

const StatusBall = (props) => {
  const { status } = props;
  const getColor = (v) => {
    return StatusColor[v];
  };
  return (
    <span className="tip">
      <svg height="1em" width="1em">
        <circle
          cx="0.5em"
          cy="0.5em"
          r="0.4em"
          stroke="gray"
          strokeWidth="1"
          fill={getColor(status)}
        />
      </svg>
      {status && <span className="hint">{status}</span>}
    </span>
  );
};
export default StatusBall;
