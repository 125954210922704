export const YEAR = 2023;
export const Status = {
  MISSING: "MISSING",
  PENDING: "PENDING",
  WAITFORPROXY: "WAITFORPROXY",
  WAITFORSUPERVISOR: "WAITFORSUPERVISOR",
  PROXYSUBMITTED: "PROXYSUBMITTED",
  SUPERVISORSUBMITTED: "SUPERVISORSUBMITTED",
  WAITFORSTAFF: "WAITFORSTAFF",
  STAFFREVIEWED: "STAFFREVIEWED",
  CLOSED: "CLOSED",
};

export const YESNOPARTIAL = {
  YES: "YES",
  NO: "NO",
  PARTIAL: "PARTIAL",
};

export const YESNO = {
  YES: "YES",
  NO: "NO",
};

export const scores = [
  { grade: 0, score: 0 },
  { grade: 1, score: 30 },
  { grade: 2, score: 50 },
  { grade: 3, score: 70 },
  { grade: 4, score: 85 },
  { grade: 5, score: 100 },
];
export const QuestionNos = {
  One: "1.0",
  Two: "2.0",
  Three: "3.0",
  Four: "4.0",
  Five: "5.0",
  Six: "6.0",
  Seven: "7.0",
  Eight: "8.0",
};
export const Trainings = {
  ProblemSolving: "Problem Solving",
  DecisionMaking: "Decision Making",
  CriticalThinkingSkills: "Critical Thinking Skills",
  MasteringEmotionalIntelligence: "Mastering Emotional Intelligence",
  EffectiveInterpersonalSkill: "Effective Interpersonal Skill",
  SupervisorySkill: "Supervisory Skill",
  LeadershipSkill: "Leadership Skill",
  QualityManagementSystem: "Quality Management System",
  StressManagement: "Stress Management",
  OrganizationSkill: "Organization Skill",
  BusinessEnglishSkill: "Business English Skill",
  PresentationSkill: "Presentation Skill",
  ComputerLiteracy: "Computer Literacy",
  OfficeProductivitySpreadsheet: "Office Productivity – Spreadsheet",
  OfficeProductivityDocument: "Office Productivity – Document",
};
export const SupervisorFormMode = {
  FINDUSER: "FINDUSER",
  ASSIGNSUPERVISOR: "ASSIGNSUPERVISOR",
};
export const SCORECOLORS = {
  Lime: "#00FF00",
  LawnGreen: "#7CFC00",
  GreenYellow: "#ADFF2F",
  Yellow: "#FFFF00",
  Gold: "#FFD700",
  LightSalmon: "#FFA07A",
};

export const StatusColor = {
  MISSING: "Gray",
  PENDING: "LightYellow",
  WAITFORSUPERVISOR: "Yellow",
  WAITFORPROXY: "Yellow" /* suspected unused */,
  PROXYSUBMITTED: "Red",
  SUPERVISORSUBMITTED: "Blue",
  WAITFORSTAFF: "LightCyan",
  STAFFREVIEWED: "Green",
  CLOSED: "LightGray",
};
export const StatusColor2 = {
  MISSING: "Gray",
  PENDING: "LightYellow",
  WAITFORSUPERVISOR: "Yellow",
  PROXYSUBMITTED: "Red",
  SUPERVISORSUBMITTED: "LightCyan",
  WAITFORSTAFF: "LightCyan",
  STAFFREVIEWED: "LawnGreen",
  CLOSED: "LightGray",
};
export const LISTVIEW = {
  LIST: "LIST",
  VIEW: "VIEW",
};
export const LISTCOMPANYDEPARTMENT = {
  LIST: "LIST",
  COMPANY: "COMPANY",
  DEPARTMENT: "DEPARTMENT",
};
export const APPTITLE = "Staff Annual Performance Evaluation for 2023";
export const NOTRELEVANT = "NOTRELEVANT";

export const APPRAISERS = [
  {staff: `CHUA SIAW TED`, proxy: `ARCHIBAL CATIBOG MANANSALA`, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `LIEW SZE YAH, NICOLE`, proxy: `AU WAN SEE, YVONNE`, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `ARCHIBAL CATIBOG MANANSALA`, proxy: ``, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `FREDERICK CABUNGAN BANAWA`, proxy: ``, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `JONG SUK FANG`, proxy: ``, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `LENOR JOYCE SASOY LEGASPI-BANAWA`, proxy: ``, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `NGO KEH TOH, ANNIE`, proxy: ``, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `DORIS ANAK WILSON ELAH`, proxy: ``, appraiser: `ARCHIBAL CATIBOG MANANSALA` },
{staff: `MARVIN ANAK HENDARY`, proxy: ``, appraiser: `ARCHIBAL CATIBOG MANANSALA` },
{staff: `NORSAMSUDDIN ANAK BANGUN`, proxy: ``, appraiser: `ARCHIBAL CATIBOG MANANSALA` },
{staff: `SITI HAJAR BINTI MOHD YUSOP`, proxy: ``, appraiser: `ARCHIBAL CATIBOG MANANSALA` },
{staff: `CATHERINE CHO SING YIENG`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `JENNY YU PICK HAI`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `STEPHEN TAN SOON HONG`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `TAN KOK SAN, NICOLE`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `TANG LING PING, JENNIFER`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `TAY CHIOK KEE`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `TIONG TECK YIENG`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `YONG SERN PIAT`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `AZALIZA BINTI AMIN`, proxy: ``, appraiser: `BONG IT FIAN` },
{staff: `CECILIA ANAK DAPAN`, proxy: ``, appraiser: `BONG IT FIAN` },
{staff: `ELLY ANAK WILFRED`, proxy: ``, appraiser: `BONG IT FIAN` },
{staff: `HELMI BIN PUTEH`, proxy: ``, appraiser: `BONG IT FIAN` },
{staff: `MARCOS ANAK WARREN SAOH`, proxy: ``, appraiser: `BONG IT FIAN` },
{staff: `NGUANG KIM KIONG`, proxy: ``, appraiser: `BONG IT FIAN` },
{staff: `NORBAYU BT MUKU`, proxy: ``, appraiser: `BONG IT FIAN` },
{staff: `SABREE BIN SABU`, proxy: ``, appraiser: `BONG IT FIAN` },
{staff: `VERONICA A/P NOED`, proxy: ``, appraiser: `BONG IT FIAN` },
{staff: `DARREN JOSHUA LUNA ANAK MARVIN @ IMPI`, proxy: `JOEL SU TAI TUNG`, appraiser: `BONG KING HUANG` },
{staff: `BILLY JONG HUNG ENG`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `BONG IT FIAN`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `CHONG UU LEONG, ZACK`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `JOEL SU TAI TUNG`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `JONG TZE SIN`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `LIM WEN CHUNG, ALEX`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `LING SHI HING, ERIC`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `MARIA JATTI ANAK ENA`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `MARIA SU`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `TEO KEE HUA, DORIS`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `VOON SAY NYUK, SHIRLEY`, proxy: ``, appraiser: `BONG KING HUANG` },
{staff: `LESLEY JENTANG ANAK JOHNNY`, proxy: `JOSEPHINE FOO SIEW PHENG`, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `JACKSON CHAI KEN NAM`, proxy: `NG SEE MEI`, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `KHO BOON POH`, proxy: `NG SEE MEI`, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `FOO HUI HUI, GRACE`, proxy: `TIONG SIEW LING`, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `HELY ANAK SOTIN`, proxy: `TIONG SIEW LING`, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `THON HUI HUI`, proxy: `TIONG SIEW LING`, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `FOO HUI SIEN`, proxy: `YONG SIEW TING`, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `CHEN ANN NEE, ANNIE`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `DOLLY LAU WAN CHING`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `JOSEPHINE FOO SIEW PHENG`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `NG SEE MEI`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `SIA CHAI JIN`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `STEPHANIE YII SZE NIE`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `SUSIE HO HUI PHING`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `TAY WEI LIANG, WILLIAM`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `TIONG SIEW LING`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `WEE MOI CHOO`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `YONG SIEW TING, CONNIE`, proxy: ``, appraiser: `CATHERINE CHO SING YIENG` },
{staff: `JENNIFFER JAWAI ANAK ANTHONEY LIM`, proxy: ``, appraiser: `CHAI TZE LIK` },
{staff: `JUDIRIC ALCAZAR RECIO`, proxy: ``, appraiser: `CHAI TZE LIK` },
{staff: `SIM YUH SHIUN`, proxy: ``, appraiser: `CHAI TZE LIK` },
{staff: `HASNIYATI HII BINTI ABDULLAH`, proxy: ``, appraiser: `CHAN HOOD SOO` },
{staff: `KATHERINE YII JOU PEI`, proxy: ``, appraiser: `CHAN HOOD SOO` },
{staff: `LAI CHEAU NEE`, proxy: ``, appraiser: `CHAN HOOD SOO` },
{staff: `LIM WEI SING`, proxy: ``, appraiser: `CHAN HOOD SOO` },
{staff: `ALVIN LOH`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `BESTER ANAK RIBAT`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `CALVIN ANAK MAWAR`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `CAMILLA MARCIA KEDUAK ANAK UNYUK`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `COLIN MATHEW`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `ELLA ANAK PENGABANG`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `ERIC UNTAMD ANAK MARK`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `JIMMY ANAK BELAJA`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `JOHN WISS ANAK JARO`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `MOHAMAD HATTA BIN ROSIDI`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `MUHAMMAD BIN SARIP`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `NICHOLAS VALEN ANAK RAGIB`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `SENADA ANAK BAJING`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `SITI ZALEHA BINTI ZEN`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `SO HU WEI`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `TANG KIE CHIONG`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `WELLINGTON ANAK JACK`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `WILLY ANAK UDAU`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `LAU SIE CHUNG`, proxy: ``, appraiser: `CHEE SIAW PAW` },
{staff: `TING SII KIONG`, proxy: ``, appraiser: `CHEE SIAW PAW` },
{staff: `CHIN CHEE HAO`, proxy: ``, appraiser: `CHIN YIAN CHON, ALSON` },
{staff: `LEE JIE LING`, proxy: ``, appraiser: `CHIN YIAN CHON, ALSON` },
{staff: `LIM KIAN BENG`, proxy: ``, appraiser: `CHIN YIAN CHON, ALSON` },
{staff: `MUHAMMAD ALFIAN WIRA SEPTIAN`, proxy: ``, appraiser: `CHIN YIAN CHON, ALSON` },
{staff: `RIONSIL DOLO MENDILA`, proxy: ``, appraiser: `CHIN YIAN CHON, ALSON` },
{staff: `SARAH ANAK DONYSOS`, proxy: ``, appraiser: `CHIN YIAN CHON, ALSON` },
{staff: `ERNA MARIANA ANAK JUMAT`, proxy: ``, appraiser: `FREDERICK CABUNGAN BANAWA` },
{staff: `LESTINLEY LUIS ANAK LAWAI`, proxy: ``, appraiser: `FREDERICK CABUNGAN BANAWA` },
{staff: `OW WAN FOO, EDDIE`, proxy: ``, appraiser: `FREDERICK CABUNGAN BANAWA` },
{staff: `RAYNOLD ANAK MERUM`, proxy: ``, appraiser: `FREDERICK CABUNGAN BANAWA` },
{staff: `SHIRLEY WONG LEE SAN`, proxy: ``, appraiser: `FREDERICK CABUNGAN BANAWA` },
{staff: `KHO KIEN QUAN`, proxy: `HO CHEE HONG`, appraiser: `GOH CHIN SING` },
{staff: `AU WAN SEE, YVONNE`, proxy: ``, appraiser: `HENG MUI KEE` },
{staff: `ELSIE LEE SUK CHER`, proxy: ``, appraiser: `HENG MUI KEE` },
{staff: `EVELYN ANAK ANAU`, proxy: ``, appraiser: `HENG MUI KEE` },
{staff: `LUI RUI YING`, proxy: ``, appraiser: `HENG MUI KEE` },
{staff: `KO LIAN CHUENG, KENNETH`, proxy: ``, appraiser: `HON JUN KHIONG` },
{staff: `UMPANG SAONG`, proxy: ``, appraiser: `HON JUN KHIONG` },
{staff: `BONG JOO CHEE`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `DAUD BIN MAHMOR`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `LAW DUEN SIONG`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `LU KA LANG @ LU KAH NANG`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `NANCY THU MEI SZE`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `NG WAN LI, WENDY`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `NUR ASFIA BIN MOHAMAD DOLLAH`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `TAY GUAN PHING`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `WEE GUEK YIAN`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `WEI SZE NEI`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `YUSUF BIN BAHARI`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `ALBERT CHUNG SWEE HENG`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `ARIF BIN CHIK`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `CHIENG SHAW YAW`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `HO AI CHIN`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `JOHNNY WONG HING KIONG`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `MOHAMMAD AZEIR BIN UMIN`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `MUHAMMAD AMIRUL RIDHZUAN STANNY`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `STANLEY MALAKA`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `TIBU ANAK DAUD`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `TING JIT JIE`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `TING YING SIEW`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `AMIRUL SYAZWAN BIN SAMRA`, proxy: ``, appraiser: `JONG KONG LIP` },
{staff: `GARY MADANG ANAK UNDOM`, proxy: ``, appraiser: `JONG KONG LIP` },
{staff: `SHALLERD ANAK NAPEG`, proxy: ``, appraiser: `JONG KONG LIP` },
{staff: `DOLLY ASHLEY ANAK MANDRA`, proxy: ``, appraiser: `JONG SIAW FOONG, STEVEN` },
{staff: `ABDUL MAJID BIN KIPLI`, proxy: ``, appraiser: `KATHERINE SONG` },
{staff: `CHRISTOPHER CHAI KOH WEE`, proxy: ``, appraiser: `KATHERINE SONG` },
{staff: `VELDA CHANG CHIA YIN`, proxy: ``, appraiser: `KATHERINE SONG` },
{staff: `ALVIN CHONG TZE HUI`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `CHAI TZE LIK`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `CHAN HOOD SOO`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `CHIN YIAN CHON, ALSON`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `CHINDAI ANAK JOSEPH`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `HENG MUI KEE`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `HON JUN KHIONG`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `JIMENO JR. LUGUE JERUSALEM`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `JONG SIAW FOONG, STEVEN`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `LAU YUK SIONG, PHILIP`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `LIM KHIN YONG, JOHN`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `SIAW WEN YIAP, MICHEAL`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `SIM KAY CHIAN, STEPHEN`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `TING SING HWA`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `WONG ING SIU, THOMAS`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `CHANG FU BOON`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `DIANA LEE MEI MEI`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `EMILY ANAK USAM`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `GOH CHIN SING`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `JAVAN LIEW SAN JER`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `JUSTINE ANAK MALAKA`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `LAU KIEW HING`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `PRESCILLIA ANAK PHILIP`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `SIEW UO SIONG`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `SIM YIK CHWIN`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `SYLVESTER MIKE LIEW CHO KEAT`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `ANDYANNA ANAK ADRIS`, proxy: ``, appraiser: `LAWRENCE KONG` },
{staff: `LEE PING CHIONG`, proxy: ``, appraiser: `LEE MING SWEE, JUSTIN` },
{staff: `MELISA ANAK SURING`, proxy: ``, appraiser: `LEE MING SWEE, JUSTIN` },
{staff: `DIONYSIUS ALVINOLD ANAK RANTAI`, proxy: ``, appraiser: `LIM KHIN YONG, JOHN` },
{staff: `KELVIN YII SENG HUA`, proxy: ``, appraiser: `LIM KHIN YONG, JOHN` },
{staff: `KUNJAN ANAK ENGGONG`, proxy: ``, appraiser: `LIM KHIN YONG, JOHN` },
{staff: `LIEW HOCK RUI`, proxy: ``, appraiser: `LIM KHIN YONG, JOHN` },
{staff: `RONIE LAU HUI LIONG`, proxy: ``, appraiser: `LIM KHIN YONG, JOHN` },
{staff: `ANGELA KATHY ANAK KINTANG`, proxy: ``, appraiser: `NGO KEH TOH` },
{staff: `ALIAA SYAMIMI BINTI JAMALI`, proxy: ``, appraiser: `SIA SOON GUEI` },
{staff: `OLIZARENA ANAK ENA`, proxy: ``, appraiser: `SIA SOON GUEI` },
{staff: `WAN MOHAMAD BIN WAN ALLI`, proxy: ``, appraiser: `SIA SOON GUEI` },
{staff: `AUDREY PEARL ANAK DEHU @ RICHARD NERU`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `DAVIDSON AK DAYUT`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `LEE KIN TAT`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `MOHD FIRAS BIN JUANDA`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `PERNANDEZ TULIS`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `TANG SING HUI`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `HA HAW DING`, proxy: ``, appraiser: `SIEW MING HUA` },
{staff: `HENRY ANAK ATA`, proxy: ``, appraiser: `SIEW UO SIONG` },
{staff: `SYLVIA TANG CHUNG TIEN`, proxy: ``, appraiser: `SIEW UO SIONG` },
{staff: `WONG LEE HOE`, proxy: ``, appraiser: `SIEW UO SIONG` },
{staff: `HANNA SANTARINA ANAK MAURICE`, proxy: `HO CHEE HONG`, appraiser: `SIM YIH SENG` },
{staff: `ABDUL WARITH BIN MOHAMMAD PHILIP`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `BONG KWONG NYAP`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `CASLEN HIU JIU HAW`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `CHAI CHANG LECK, ERIC`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `CHAI PEI SZE`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `CHIANG HENG HO`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `CHIANG SIONG ING`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `CHIN WEI KING, ERIC`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `CHONG YU CHIAU`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `CHRIS HUDSON ANAK BUJANG`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `DAPHNE DONNA BINTI MARTIN`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `HO CHEE HONG`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `KELVIN CHAI HOCK JOI`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `LAI SIEW LIEN`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `LEE KUET LUNG`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `LEE MOOK FUA`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `LIM KAI HENG`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `ROBICA ANAK KALONG`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `ROSELIND IKAH ANAK ROBERT @ KELIMBANG`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `SELINBIT ANAK MOS`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `SIM POH KIAW, EMELYN`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `TOH JAG HUI`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `TULIS ANAK CHANDANG`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `VIVIANA ONG ZHI JINN`, proxy: ``, appraiser: `SIM YIH SENG` },
{staff: `DAYANG NUR AININA BINTI ABG MOHAMAD`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `FADILLAH BTE ABDULLAH`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `HII CHUI YEN, IVY`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `LING FUNG ENG`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `MONA KEDAYA ANAK STORY`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `NAJA ANAK NYAUN`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `SANDRA ANAK JAWING`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `SONIA VIVIANI ANAK JANIS`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `TING SIAW WEE`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `VOON KHENG HUA, VALERIE`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `ADRIAN CHAI YOU QING`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `HO SIAW LEE, LILY`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `IRENE KHO AI LING`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `IRWAN BIN BUJANG`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `LEE SHAW HIENG`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `TANG KUI SIEW, DEBBY`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `VIVIAN FU JIA WEN`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `ANDRESS LANTUK ANAK MAJING`, proxy: `ALEXANDER LAI HORNG WEN`, appraiser: `TAY CHIOK KEE` },
{staff: `WINNIE LAI EE FEI`, proxy: `ALEXANDER LAI HORNG WEN`, appraiser: `TAY CHIOK KEE` },
{staff: `FARAHANI BINTI HAINI`, proxy: `ANGIE JOAN ANAK DANIEL`, appraiser: `TAY CHIOK KEE` },
{staff: `PHANG MING JING, RAINIE`, proxy: `ANGIE JOAN ANAK DANIEL`, appraiser: `TAY CHIOK KEE` },
{staff: `SHARON NG KHE SWYN`, proxy: `ANGIE JOAN ANAK DANIEL`, appraiser: `TAY CHIOK KEE` },
{staff: `TOMMY LEE SOON MING`, proxy: `CHIENG CHING KUOK`, appraiser: `TAY CHIOK KEE` },
{staff: `TSAI KAI LEN, JOSEPH`, proxy: `CHIENG CHING KUOK`, appraiser: `TAY CHIOK KEE` },
{staff: `CHUA YONG TIEN, EUNICE`, proxy: `CHONG SIAW HUA`, appraiser: `TAY CHIOK KEE` },
{staff: `JOHAN ANAK JAWA`, proxy: `GOH CHIN SING`, appraiser: `TAY CHIOK KEE` },
{staff: `HII YII SHIN`, proxy: `LESLIE LIM CHUNG LEONG`, appraiser: `TAY CHIOK KEE` },
{staff: `TIE SHIE LING`, proxy: `NGO JIN JIN`, appraiser: `TAY CHIOK KEE` },
{staff: `ABDUL AZIZ BIN HIPENI`, proxy: `TAN YIEN LIN`, appraiser: `TAY CHIOK KEE` },
{staff: `LINDA ANAK BUYONG`, proxy: `VICTORIA ANAK UMPANG`, appraiser: `TAY CHIOK KEE` },
{staff: `VICTORIA ANAK UMPANG`, proxy: `YEO TANG TANG`, appraiser: `TAY CHIOK KEE` },
{staff: `ALEXANDER LAI HORNG WEN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `ANGELINE ONG CHEAH JOO`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `ANGIE JOAN ANAK DANIEL`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `CHARLES LEE WEE TECK`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `CHARLES REYNOLD ANAK NIGEL`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `CHIENG CHING KUOK`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `CHONG SIAW HUA`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `JONG KONG LIP`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `JOSEPH CHAI MING KIEN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `LAI SIAW LING`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `LESLIE LIM CHUNG LEONG`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `LING SHEAU WEI`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `NGO JIN JIN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `TAN YIEN LIN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `TEO TAI WEE, STEPHEN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `THIAN FUI CHIUN, KERRY`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `TIEO SEOW LING, LYN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `TING KWAN HEE`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `TIONG HWA AI`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `YEO TANG TANG`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `JESECA ANAK NYAMBA`, proxy: ``, appraiser: `THIAN FUI CHIUN, KERRY` },
{staff: `KHAIRUF FAIZAH BINTI MASLIM`, proxy: ``, appraiser: `THIAN FUI CHIUN, KERRY` },
{staff: `ALBINUS PETRUS RATIM NGIBAN`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `ALI BIN SABRI`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `BONNIE ANAK ANTHONY`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `CLINTON JAWA ANAK RICHARDLY`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `HAIDI BIN MARANI`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `TUNI ANAK OUI JUAN`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `KOH SIANG SENG`, proxy: ``, appraiser: `TING SING HWA` },
{staff: `PETER LAW KIING KING`, proxy: ``, appraiser: `TING SING HWA` },
{staff: `ESTHER CH'NG PEI YAN`, proxy: `CHAI SIN HWEI`, appraiser: `TIONG TECK YIENG` },
{staff: `SIM SIAT FUNG, ALICE`, proxy: `CHRISTINE YII SHU YEE`, appraiser: `TIONG TECK YIENG` },
{staff: `NG KHOON CHIENG`, proxy: `LIM EK HUA`, appraiser: `TIONG TECK YIENG` },
{staff: `AMANDA LAW LI KHING`, proxy: `SIA SOON GUEI`, appraiser: `TIONG TECK YIENG` },
{staff: `BELINDA TAN CHAI YING`, proxy: `SIA SOON GUEI`, appraiser: `TIONG TECK YIENG` },
{staff: `ANNIE LEE SIEW PHIN`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `BRENDAN LOUIS KUOK THAI HONG`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHAI LI SWAN`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHAI PEI SHAN`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHAI SIN HWEI`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHIN JIUN HUAH, ANGEL`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHRISTINE YII SHU YEE`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHUNG XIN YII`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CLEMENT CHOO YEK SHIUNG`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `JACKUELINE ONG YUEN CHING`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `JONG PEI PEI, ANNA`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `LAW SIEW NI, TIFFANY`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `LIM EK HUA`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `LINNA WONG`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `ONG CHIAW SIAN`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `SIA SOON GUEI`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `TAN MIAW ENG, ANGELINE`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `TAY YAI YING`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `TERRY THAM TZE PING`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `WONG WOAN MIING, RACHELLE`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `YONG TING`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `BONG KING HUANG`, proxy: ``, appraiser: `TONY YU YUONG WEE` },
{staff: `CHEE SIAW PAW`, proxy: ``, appraiser: `TONY YU YUONG WEE` },
{staff: `LEE MING SWEE, JUSTIN`, proxy: ``, appraiser: `TONY YU YUONG WEE` },
{staff: `SIEW MING HUA`, proxy: ``, appraiser: `TONY YU YUONG WEE` },
{staff: `WONG MING`, proxy: ``, appraiser: `TONY YU YUONG WEE` },
{staff: `YONG SIAW FONG, MICHELLE`, proxy: ``, appraiser: `TONY YU YUONG WEE` },
{staff: `REMY ANAK JOHEL`, proxy: `JOEL SU TAI TUNG`, appraiser: `WONG MING` },
{staff: `FABIANA ANAK CHAN`, proxy: `KHO HO SIA`, appraiser: `WONG MING` },
{staff: `LIEGAN ANAK MANGGUH`, proxy: `KHO HO SIA`, appraiser: `WONG MING` },
{staff: `MACCOLLIN DEE DANNY ERICK`, proxy: `KHO HO SIA`, appraiser: `WONG MING` },
{staff: `NOOR MARLIDAH HAI`, proxy: `KHO HO SIA`, appraiser: `WONG MING` },
{staff: `PETRUS SCHENKER ANAK RABES`, proxy: `KHO HO SIA`, appraiser: `WONG MING` },
{staff: `BRIAN JOVY ANAK AHMEW`, proxy: ``, appraiser: `WONG MING` },
{staff: `BRIGETTE BRENDA BASE`, proxy: ``, appraiser: `WONG MING` },
{staff: `CHENDANG ANAK THOMAS ALI`, proxy: ``, appraiser: `WONG MING` },
{staff: `CHIN LEE HUA`, proxy: ``, appraiser: `WONG MING` },
{staff: `DIANA BIDAI ANAK MENDALI`, proxy: ``, appraiser: `WONG MING` },
{staff: `JOHNNY HO KOK SIAM`, proxy: ``, appraiser: `WONG MING` },
{staff: `JONG KIM WONG`, proxy: ``, appraiser: `WONG MING` },
{staff: `JULLYANA GORILA ANAK JENOS`, proxy: ``, appraiser: `WONG MING` },
{staff: `KHO HO SIA, GEORGE`, proxy: ``, appraiser: `WONG MING` },
{staff: `LESTER LUNA ANAK ULOK`, proxy: ``, appraiser: `WONG MING` },
{staff: `MAINA NICKOLA ANAK MICHILE`, proxy: ``, appraiser: `WONG MING` },
{staff: `MOHD. HAZRUL ALEX BIN ABDULLAH`, proxy: ``, appraiser: `WONG MING` },
{staff: `NURUL HURAIRAH BINTI SARIP`, proxy: ``, appraiser: `WONG MING` },
{staff: `SYLVESTER ANAK KENETH KANYAN`, proxy: ``, appraiser: `WONG MING` },
{staff: `VERONICA SULAN ANAK VINCENT`, proxy: ``, appraiser: `WONG MING` },
{staff: `VORNICA NAYAN ANAK NANANG`, proxy: ``, appraiser: `WONG MING` },
{staff: `EDWARD TENLEY AK FRANCIS KETIR`, proxy: ``, appraiser: `YEO TANG TANG` },
{staff: `YUNG MEI HUA, MANDY`, proxy: ``, appraiser: `YEO TANG TANG` },
{staff: `ABUZAR GHIFARI BIN ZAKARIA`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `AUDREY STECCY ANAK DOUGLAS`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `GASTON QION EMMANUEL AK GEORGE`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `IRENE ENGGU`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `LO JAK FATT, EVENCE`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `TAN KEENG LING`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `KATHERINE SONG`, proxy: ``, appraiser: `YU JI` },
{staff: `STEPHEN SUNNY ANAK RICHMOND`, proxy: ``, appraiser: `YU JI` },
{staff: `SYLVIA ANAK CHAONG`, proxy: ``, appraiser: `YU JI` },
{staff: `YVONNE SHARON ANAK MIKOLDEN`, proxy: ``, appraiser: `YU JI` }
]

export const DEPARTMENTS = [
  { name: "ACCOUNTS", departmentid: "ACCOUNTS" },
  { name: "ADMINISTRATION", departmentid: "ADMINISTRATION" },
  { name: "CORPORATION", departmentid: "CORPORATION" },
  { name: "HR & CORPORATE COMM", departmentid: "HR" },
  { name: "IT", departmentid: "IT" },
  { name: "PA", departmentid: "PA" },
  { name: "PROJECT ADMIN", departmentid: "PROJECT ADMIN" },
  { name: "PROJECTS", departmentid: "PROJECTS" },
  { name: "PROPERTY", departmentid: "PROPERTY" },
  { name: "QS & CONTRACT MGT", departmentid: "QS" },
  { name: "TREASURY", departmentid: "TREASURY" },
];

export const DEPARTMENT_STAFFS = [
  { department: "PROJECT ADMIN", staff: "LIEW SZE YAH, NICOLE" },
  { department: "QS & CONTRACT MGT", staff: "NGO KEH TOH, ANNIE" },
  { department: "PA", staff: "AU WAN SEE, YVONNE" },
  { department: "QS & CONTRACT MGT", staff: "APPLE LAI SZE YII" },
  { department: "QS & CONTRACT MGT", staff: "VOON ANN KEE" },
  { department: "QS & CONTRACT MGT", staff: "CHONG PEI SZE" },
  { department: "PROPERTY", staff: "TAY CHIOK KEE" },
  { department: "ACCOUNTS", staff: "TIONG TECK YIENG" },
  { department: "IT", staff: "YONG SERN PIAT" },
  { department: "IT", staff: "STEPHEN TAN SOON HONG" },
  { department: "ACCOUNTS", staff: "CATHERINE CHO SING YIENG" },
  { department: "HR & CORPORATE COMM", staff: "TANG LING PING, JENNIFER" },
  { department: "ADMINISTRATION", staff: "TAY CHIOK KEE" },
  { department: "TREASURY", staff: "JENNY YU PICK HAI" },
  { department: "PROJECT ADMIN", staff: "TAN KOK SAN, NICOLE" },
  { department: "CORPORATION", staff: "ELLY ANAK WILFRED" },
  { department: "CORPORATION", staff: "VERONICA A/P NOED" },
  { department: "CORPORATION", staff: "MARCOS ANAK WARREN SAOH" },
  { department: "CORPORATION", staff: "AZALIZA BINTI AMIN" },
  { department: "CORPORATION", staff: "CECILIA ANAK DAPAN" },
  { department: "CORPORATION", staff: "HELMI BIN PUTEH" },
  { department: "CORPORATION", staff: "SABREE BIN SABU" },
  { department: "CORPORATION", staff: "NORBAYU BT MUKU" },
  { department: "CORPORATION", staff: "NGUANG KIM KIONG" },
  { department: "ACCOUNTS", staff: "LESLEY JENTANG ANAK JOHNNY" },
  { department: "ACCOUNTS", staff: "JACKSON CHAI KEN NAM" },
  { department: "ACCOUNTS", staff: "KHO BOON POH" },
  { department: "ACCOUNTS", staff: "ERIC CHAN KIEN SHIUNG" },
  { department: "ACCOUNTS", staff: "HELY ANAK SOTIN" },
  { department: "ACCOUNTS", staff: "FOO HUI HUI, GRACE" },
  { department: "ACCOUNTS", staff: "THON HUI HUI" },
  { department: "ACCOUNTS", staff: "FOO HUI SIEN" },
  { department: "ACCOUNTS", staff: "YONG SIEW TING, CONNIE" },
  { department: "ACCOUNTS", staff: "SUSIE HO HUI PHING" },
  { department: "ACCOUNTS", staff: "WEE MOI CHOO" },
  { department: "ACCOUNTS", staff: "NG SEE MEI" },
  { department: "ACCOUNTS", staff: "JOSEPHINE FOO SIEW PHENG" },
  { department: "ACCOUNTS", staff: "TIONG SIEW LING" },
  { department: "PROPERTY", staff: "CHONG SIAW HUA" },
  { department: "PROJECTS", staff: "SIM YUH SHIUN" },
  { department: "PROJECT ADMIN", staff: "JENNIFFER JAWAI ANAK ANTHONEY LIM" },
  { department: "PROJECT ADMIN", staff: "HASNIYATI HII BINTI ABDULLAH" },
  { department: "PROJECT ADMIN", staff: "KATHERINE YII JOU PEI" },
  { department: "QS & CONTRACT MGT", staff: "LAW LEE SZE, DORIS" },
  { department: "QS & CONTRACT MGT", staff: "LAI CHEAU NEE" },
  { department: "QS & CONTRACT MGT", staff: "LIM WEI SING" },
  { department: "PROJECTS", staff: "ERIC UNTAMD ANAK MARK" },
  { department: "PROJECTS", staff: "LING WANG CHING, EDMUND" },
  { department: "PROJECTS", staff: "CALVIN ANAK MAWAR" },
  { department: "PROJECTS", staff: "SENADA ANAK BAJING" },
  { department: "PROJECTS", staff: "MALING ANAK BADINDANG" },
  { department: "PROJECTS", staff: "TANG KIE CHIONG" },
  { department: "PROJECTS", staff: "SITI ZALEHA BINTI ZEN" },
  { department: "PROJECTS", staff: "JIMMY ANAK BELAJA" },
  { department: "PROJECTS", staff: "SO HU WEI" },
  { department: "PROJECTS", staff: "IAN WALTER JUNIT" },
  { department: "PROJECTS", staff: "SIRICORD SATOH ANAK MINOS" },
  { department: "PROJECTS", staff: "WILLY ANAK UDAU" },
  { department: "PROJECTS", staff: "JAMES VALENTINE ANAK JEFFERY" },
  { department: "PROJECTS", staff: "CAMILLA MARCIA KEDUAK ANAK UNYUK" },
  { department: "PROJECTS", staff: "MUHAMMAD BIN SARIP" },
  { department: "PROJECTS", staff: "PHILMAL LEONCIO CAPULONG" },
  { department: "PROJECTS", staff: "BESTER ANAK RIBAT" },
  { department: "PROJECTS", staff: "STANISLAUSH ANAK LAN PETER-resigned" },
  { department: "PROJECTS", staff: "ALVIN LOH" },
  { department: "PROJECTS", staff: "MOHAMAD HATTA BIN ROSIDI" },
  { department: "PROJECTS", staff: "WELLINGTON ANAK JACK" },
  { department: "PROJECTS", staff: "FELIX NICO ANAK RAMLI" },
  { department: "PROJECTS", staff: "JOHN WISS ANAK JARO" },
  { department: "CORPORATION", staff: "LAU SIE CHUNG" },
  { department: "CORPORATION", staff: "TING SII KIONG" },
  { department: "PROJECT ADMIN", staff: "HII SIEW NEE" },
  { department: "PROJECT ADMIN", staff: "LEE JIE LING" },
  { department: "PROJECTS", staff: "SARAH ANAK DONYSOS" },
  { department: "PROJECTS", staff: "COSMAS PANG HAN MING" },
  { department: "PROJECTS", staff: "MUHAMMAD ALFIAN WIRA SEPTIAN" },
  { department: "PROJECTS", staff: "LING TUNG YEK" },
  { department: "PROJECTS", staff: "IDA BAGUS INDRA PREMANA PUTRA" },
  { department: "PROJECTS", staff: "RIONSIL DOLO MENDILA" },
  { department: "PROJECTS", staff: "DANIEL TIE YIN SIONG" },
  { department: "PROJECTS", staff: "CHIN CHEE HAO" },
  { department: "PROJECTS", staff: "LIM KIAN BENG" },
  { department: "PROJECTS", staff: "OW WAN FOO, EDDIE" },
  { department: "PA", staff: "LUI RUI YING" },
  { department: "PA", staff: "PHANG SIAW TIEN" },
  { department: "PROJECT ADMIN", staff: "MASI ANAK RIKAW" },
  { department: "PROJECTS", staff: "VINCENT SIA KEE SHIIK" },
  { department: "PROJECTS", staff: "LEE KIN TAT" },
  { department: "PROJECTS", staff: "PETER LAW KIING KING" },
  { department: "TREASURY", staff: "WEE GUEK YIAN" },
  { department: "TREASURY", staff: "LU KA LANG @ LU KAH NANG" },
  { department: "ADMINISTRATION", staff: "NUR ASFIA BIN MOHAMAD DOLLAH" },
  { department: "ADMINISTRATION", staff: "DAUD BIN MAHMOR" },
  { department: "ADMINISTRATION", staff: "YUSUF BIN BAHARI" },
  { department: "TREASURY", staff: "LAW DUEN SIONG" },
  { department: "TREASURY", staff: "WEI SZE NEI" },
  { department: "TREASURY", staff: "TAY GUAN PHING" },
  { department: "TREASURY", staff: "NANCY THU MEI SZE" },
  { department: "TREASURY", staff: "NG WAN LI, WENDY" },
  { department: "TREASURY", staff: "BONG JOO CHEE" },
  { department: "PROJECT ADMIN", staff: "TING YING SIEW" },
  { department: "PROJECTS", staff: "PRESCILLIA ANAK PHILIP" },
  { department: "PROJECTS", staff: "UMPANG SAONG" },
  { department: "PROJECTS", staff: "ALBERT CHUNG SWEE HENG" },
  { department: "PROJECTS", staff: "CHIENG SHAW YAW" },
  { department: "PROJECTS", staff: "WARREN ANG LIANG" },
  { department: "PROJECTS", staff: "JOHNNY WONG HING KIONG" },
  { department: "PROJECTS", staff: "SAMUEL LING SU YIEW" },
  { department: "PROJECTS", staff: "MUHAMMAD AMIRUL RIDHZUAN STANNY" },
  { department: "PROJECTS", staff: "STANLEY MALAKA" },
  { department: "PROJECTS", staff: "TING JIT JIE" },
  { department: "PROJECTS", staff: "HO AI CHIN" },
  { department: "PROJECTS", staff: "ARIF BIN CHIK" },
  { department: "PROJECTS", staff: "MOHAMMAD AZEIR BIN UMIN" },
  { department: "PROJECTS", staff: "TIBU ANAK DAUD" },
  { department: "PROJECTS", staff: "DOLLY ASHLEY ANAK MANDRA" },
  { department: "QS & CONTRACT MGT", staff: "JULIE BONG" },
  { department: "CORPORATION", staff: "JONG SIEW FAH" },
  { department: "PROPERTY", staff: "VELDA CHANG CHIA YIN" },
  { department: "PROJECTS", staff: "SIM YIH SENG" },
  { department: "PROJECTS", staff: "LAU YUK SIONG, PHILIP" },
  { department: "QS & CONTRACT MGT", staff: "CHAN HOOD SOO" },
  { department: "QS & CONTRACT MGT", staff: "JONG SIAW FOONG, STEVEN" },
  { department: "ADMINISTRATION", staff: "CHINDAI ANAK JOSEPH" },
  { department: "CORPORATION", staff: "WONG ING SIU, THOMAS" },
  { department: "PROJECTS", staff: "SIM KAY CHIAN, STEPHEN" },
  { department: "PA", staff: "HENG MUI KEE" },
  { department: "PROJECTS", staff: "HON JUN KHIONG" },
  { department: "PROJECTS", staff: "SIAW WEN YIAP, MICHEAL" },
  { department: "QS & CONTRACT MGT", staff: "ALVIN CHONG TZE HUI" },
  { department: "QS & CONTRACT MGT", staff: "CHAI TZE LIK" },
  { department: "PROJECTS", staff: "JIMENO JR. LUGUE JERUSALEM" },
  { department: "PROJECTS", staff: "LIM KHIN YONG, JOHN" },
  { department: "PROJECTS", staff: "CHIN YIAN CHON, ALSON" },
  { department: "QS & CONTRACT MGT", staff: "JONG SUK FANG" },
  { department: "PROJECT ADMIN", staff: "LAU KIEW HING" },
  { department: "PROJECTS", staff: "HENRY ANAK ATA" },
  { department: "PROJECTS", staff: "JAVAN LIEW SAN JER" },
  { department: "PROJECTS", staff: "SIEW UO SIONG" },
  { department: "PROJECTS", staff: "SIM YIK CHWIN" },
  { department: "QS & CONTRACT MGT", staff: "DIANA LEE MEI MEI" },
  { department: "PROJECTS", staff: "KO LIAN CHUENG, KENNETH" },
  { department: "PROJECT ADMIN", staff: "DORIS ANAK WILSON ELAH" },
  { department: "PROJECT ADMIN", staff: "TING SIAW WEE" },
  { department: "PROJECTS", staff: "SOON CHONG TECK" },
  { department: "PROJECTS", staff: "LIEW HOCK RUI" },
  { department: "PROJECTS", staff: "KELVIN YII SENG HUA" },
  { department: "QS & CONTRACT MGT", staff: "RONIE LAU HUI LIONG" },
  { department: "QS & CONTRACT MGT", staff: "ANGELA KATHY ANAK KINTANG" },
  { department: "ADMINISTRATION", staff: "OLIZARENA ANAK ENA" },
  { department: "ADMINISTRATION", staff: "WAN MOHAMAD BIN WAN ALLI" },
  { department: "PROJECTS", staff: "GOH CHIN SING" },
  { department: "PROJECTS", staff: "PERNANDEZ TULIS" },
  { department: "PROJECTS", staff: "SYLVESTER MIKE LIEW CHO KEAT" },
  { department: "PROJECTS", staff: "AUDREY PEARL ANAK DEHU @ RICHARD NERU" },
  { department: "PROJECT ADMIN", staff: "SHERIN ANAK SIMEK" },
  { department: "PROJECTS", staff: "TANG SING HUI" },
  { department: "PROJECTS", staff: "DAVIDSON AK DAYUT" },
  { department: "PROJECTS", staff: "HA HAW DING" },
  { department: "PROJECTS", staff: "ALBBEY GULANG ANAK JACKSON BUNDAN" },
  { department: "PROJECTS", staff: "JUSTINE ANAK MALAKA" },
  { department: "PROJECTS", staff: "WONG LEE HOE" },
  { department: "PROJECTS", staff: "THIERRY WONG YU WEI" },
  { department: "QS & CONTRACT MGT", staff: "SYLVIA TANG CHUNG TIEN" },
  { department: "PROJECTS", staff: "KUNJAN ANAK ENGGONG" },
  { department: "PROJECTS", staff: "MARVIN ANAK HENDARY" },
  { department: "PROJECTS", staff: "SITI HAJAR BINTI MOHD YUSOP" },
  { department: "PROJECTS", staff: "CHUA SIAW TED" },
  { department: "PROJECTS", staff: "NORSAMSUDDIN ANAK BANGUN" },
  { department: "PROJECT ADMIN", staff: "HANNA SANTARINA ANAK MAURICE" },
  { department: "PROJECTS", staff: "CHANG FU BOON" },
  { department: "PROJECTS", staff: "KHO KIEN QUAN" },
  { department: "PROJECTS", staff: "LING JEN UNG" },
  { department: "PROJECTS", staff: "SUZANNA ANAK RAGAM" },
  { department: "PROJECT ADMIN", staff: "DAPHNE DONNA BINTI MARTIN" },
  { department: "PROJECT ADMIN", staff: "FEILYCIA ANAK SAMBANG" },
  { department: "PROJECT ADMIN", staff: "ROBICA ANAK KALONG" },
  { department: "PROJECTS", staff: "ABDUL WARITH BIN MOHAMMAD PHILIP" },
  { department: "PROJECTS", staff: "SELINBIT ANAK MOS" },
  {
    department: "PROJECT ADMIN",
    staff: "ROSELIND IKAH ANAK ROBERT @ KELIMBANG",
  },
  { department: "PROJECT ADMIN", staff: "SHIRLEY WONG LEE SAN" },
  { department: "PROJECT ADMIN", staff: "CHAI PEI SZE" },
  { department: "PROJECTS", staff: "CHIANG HENG HO" },
  { department: "PROJECT ADMIN", staff: "LAI SIEW LIEN" },
  { department: "PROJECT ADMIN", staff: "SIM POH KIAW, EMELYN" },
  { department: "PROJECTS", staff: "CHAI CHANG LECK, ERIC" },
  { department: "PROJECTS", staff: "CHRIS HUDSON ANAK BUJANG" },
  { department: "PROJECTS", staff: "TOH JAG HUI" },
  { department: "PROJECTS", staff: "CINDY LIEW" },
  { department: "PROJECTS", staff: "KELVIN CHAI HOCK JOI" },
  { department: "PROJECTS", staff: "LIM KAI HENG" },
  { department: "PROJECTS", staff: "VIVIANA ONG ZHI JINN" },
  { department: "PROJECTS", staff: "RAYNOLD ANAK MERUM" },
  { department: "PROJECTS", staff: "DENNIS CHANG CHOON HOR" },
  { department: "PROJECTS", staff: "ERNA MARIANA ANAK JUMAT" },
  { department: "PROJECTS", staff: "LEE PING CHIONG" },
  { department: "PROJECTS", staff: "TING SING HWA" },
  { department: "PROJECTS", staff: "KELVIN POH KHEE JOO" },
  { department: "PROJECTS", staff: "LIEW SIEW VUI, ALVIN" },
  { department: "PROJECTS", staff: "LING KHO TUNG, TOMMY" },
  { department: "PROJECTS", staff: "ARCHIBAL CATIBOG MANANSALA" },
  { department: "PROJECT ADMIN", staff: "CATHERINE ANAK MENJJALIE" },
  { department: "PROJECTS", staff: "HO CHEE HONG" },
  { department: "PROJECTS", staff: "LEE KUET LUNG" },
  { department: "PROJECTS", staff: "CHAI SAM CHOI" },
  { department: "PROJECTS", staff: "CHIN WEI KING, ERIC" },
  { department: "PROJECTS", staff: "LEE MOOK FUA" },
  { department: "PROJECTS", staff: "CHIANG SIONG ING" },
  { department: "PROJECTS", staff: "CHONG YU CHIAU" },
  { department: "PROJECTS", staff: "TULIS ANAK CHANDANG" },
  { department: "PROJECTS", staff: "LESTINLEY LUIS ANAK LAWAI" },
  { department: "PROJECT ADMIN", staff: "LING FUNG ENG" },
  { department: "PROJECT ADMIN", staff: "MONA KEDAYA ANAK STORY" },
  { department: "PROJECT ADMIN", staff: "SANDRA ANAK JAWING" },
  { department: "PROJECT ADMIN", staff: "SONIA VIVIANI ANAK JANIS" },
  { department: "PROJECT ADMIN", staff: "FADILLAH BTE ABDULLAH" },
  { department: "PROJECT ADMIN", staff: "HII CHUI YEN, IVY" },
  { department: "PROJECT ADMIN", staff: "ELSIE LEE SUK CHER" },
  { department: "PROJECT ADMIN", staff: "DAYANG NUR AININA BINTI ABG MOHAMAD" },
  { department: "PROJECT ADMIN", staff: "NAJA ANAK NYAUN" },
  { department: "PROJECT ADMIN", staff: "VOON KHENG HUA, VALERIE" },
  { department: "HR & CORPORATE COMM", staff: "IRENE KHO AI LING" },
  { department: "HR & CORPORATE COMM", staff: "SIA SOON DING" },
  { department: "HR & CORPORATE COMM", staff: "LEE SHAW HIENG" },
  { department: "HR & CORPORATE COMM", staff: "VIVIAN FU JIA WEN" },
  { department: "HR & CORPORATE COMM", staff: "ANDY VOON PERNG KAI" },
  { department: "HR & CORPORATE COMM", staff: "HO SIAW LEE, LILY" },
  { department: "HR & CORPORATE COMM", staff: "TANG KUI SIEW, DEBBY" },
  { department: "HR & CORPORATE COMM", staff: "IRWAN BIN BUJANG" },
  { department: "PROPERTY", staff: "SHARON NG KHE SWYN" },
  { department: "PROPERTY", staff: "ALVIN SIA LOONG WEI" },
  { department: "PROPERTY", staff: "FARAHANI BINTI HAINI" },
  { department: "PROPERTY", staff: "PHANG MING JING, RAINIE" },
  { department: "PROPERTY", staff: "TOMMY LEE SOON MING" },
  { department: "PROPERTY", staff: "JOHAN ANAK JAWA" },
  { department: "PROPERTY", staff: "HII YII SHIN" },
  { department: "ADMINISTRATION", staff: "TIE SHIE LING" },
  { department: "ADMINISTRATION", staff: "ABDUL AZIZ BIN HIPENI" },
  { department: "ADMINISTRATION", staff: "ALBINUS PETRUS RATIM NGIBAN" },
  { department: "ADMINISTRATION", staff: "LINDA ANAK BUYONG" },
  { department: "ADMINISTRATION", staff: "VICTORIA ANAK UMPANG" },
  { department: "PROPERTY", staff: "ALEXANDER LAI HORNG WEN" },
  { department: "ADMINISTRATION", staff: "NGO JIN JIN" },
  { department: "PROPERTY", staff: "ANGIE JOAN ANAK DANIEL" },
  { department: "PROPERTY", staff: "TEO MEI FONG, MELISSA" },
  { department: "ADMINISTRATION", staff: "TING KWAN HEE" },
  { department: "ADMINISTRATION", staff: "CHARLES LEE WEE TECK" },
  { department: "ADMINISTRATION", staff: "YEO TANG TANG" },
  { department: "ADMINISTRATION", staff: "LESLIE LIM CHUNG LEONG" },
  { department: "PROPERTY", staff: "JORDAN LAI JIN LI" },
  { department: "PROPERTY", staff: "JOSEPH CHAI MING KIEN" },
  { department: "PROPERTY", staff: "ANGELINE ONG CHEAH JOO" },
  { department: "QS & CONTRACT MGT", staff: "LING SHEAU WEI" },
  { department: "PROPERTY", staff: "TIEO SEOW LING, LYN" },
  { department: "ADMINISTRATION", staff: "TAN YIEN LIN" },
  { department: "ADMINISTRATION", staff: "THIAN FUI CHIUN, KERRY" },
  { department: "PROPERTY", staff: "CHIENG CHING KUOK" },
  { department: "PROPERTY", staff: "TEO TAI WEE, STEPHEN" },
  { department: "PROPERTY", staff: "CHUA WEE LEONG, RUDY" },
  { department: "ADMINISTRATION", staff: "BONNIE ANAK ANTHONY" },
  { department: "ADMINISTRATION", staff: "TUNI ANAK OUI JUAN" },
  { department: "ADMINISTRATION", staff: "ALI BIN SABRI" },
  { department: "ADMINISTRATION", staff: "HAIDI BIN MARANI" },
  { department: "PROPERTY", staff: "AMIRUL SYAZWAN BIN SAMRA" },
  { department: "PROPERTY", staff: "WATTSON ZAMBRY AK SAKUPA" },
  { department: "ADMINISTRATION", staff: "JESECA ANAK NYAMBA" },
  { department: "PROPERTY", staff: "CLINTON JAWA ANAK RICHARDLY" },
  { department: "PROPERTY", staff: "SHALLERD ANAK NAPEG" },
  { department: "ACCOUNTS", staff: "ESTHER CH'NG PEI YAN" },
  { department: "ACCOUNTS", staff: "SIM SIAT FUNG, ALICE" },
  { department: "ACCOUNTS", staff: "NG KHOON CHIENG" },
  { department: "ACCOUNTS", staff: "IVAN JONG SZE YING" },
  { department: "ACCOUNTS", staff: "BELINDA TAN CHAI YING" },
  { department: "ACCOUNTS", staff: "CHRISTINE YII SHU YEE" },
  { department: "ACCOUNTS", staff: "SIM TONG SEN" },
  { department: "ACCOUNTS", staff: "LIM EK HUA" },
  { department: "ACCOUNTS", staff: "TAY YAI YING" },
  { department: "ACCOUNTS", staff: "ONG CHIAW SIAN" },
  { department: "ACCOUNTS", staff: "CHAI SIN HWEI" },
  { department: "ACCOUNTS", staff: "ANNIE LEE SIEW PHIN" },
  { department: "ACCOUNTS", staff: "CHAI LI SWAN" },
  { department: "ACCOUNTS", staff: "CLEMENT CHOO YEK SHIUNG" },
  { department: "ACCOUNTS", staff: "GOH BEE HUA" },
  { department: "ACCOUNTS", staff: "YONG TING" },
  { department: "ACCOUNTS", staff: "TERRY THAM TZE PING" },
  { department: "ACCOUNTS", staff: "LING CHEW HUI, CHRISTINA" },
  { department: "ACCOUNTS", staff: "LINNA WONG" },
  { department: "ACCOUNTS", staff: "WONG WOAN MIING, RACHELLE" },
  { department: "ACCOUNTS", staff: "LAW SIEW NI, TIFFANY" },
  { department: "ACCOUNTS", staff: "SIA SOON GUEI" },
  { department: "ACCOUNTS", staff: "CHIN JIUN HUAH, ANGEL" },
  { department: "ACCOUNTS", staff: "TAN MIAW ENG, ANGELINE" },
  { department: "PROJECTS", staff: "FREDERICK CABUNGAN BANAWA" },
  { department: "PROJECTS", staff: "TIONG JIH MEI, JIMMY" },
  { department: "CORPORATION", staff: "CHEE SIAW PAW" },
  { department: "PA", staff: "YONG SIAW FONG, MICHELLE" },
  { department: "CORPORATION", staff: "WONG MING" },
  { department: "CORPORATION", staff: "BONG IT FIAN" },
  { department: "PROJECTS", staff: "LENOR JOYCE SASOY LEGASPI-BANAWA" },
  { department: "PROJECTS", staff: "SIEW MING HUA" },
  { department: "CORPORATION", staff: "BILLY JONG HUNG ENG" },
  { department: "CORPORATION", staff: "IRENE ENGGU" },
  { department: "CORPORATION", staff: "MARIA SU" },
  { department: "CORPORATION", staff: "CHONG UU LEONG, ZACK" },
  { department: "CORPORATION", staff: "TEO KEE HUA, DORIS" },
  { department: "CORPORATION", staff: "JONG TZE SIN" },
  { department: "CORPORATION", staff: "JOEL SU TAI TUNG" },
  { department: "CORPORATION", staff: "MARIA JATTI ANAK ENA" },
  { department: "CORPORATION", staff: "LING SHI HING, ERIC" },
  { department: "CORPORATION", staff: "REMY ANAK JOHEL" },
  { department: "CORPORATION", staff: "PETRUS SCHENKER ANAK RABES" },
  { department: "CORPORATION", staff: "FABIANA ANAK CHAN" },
  { department: "CORPORATION", staff: "MACCOLLIN DEE DANNY ERICK" },
  { department: "CORPORATION", staff: "NOOR MARLIDAH HAI" },
  { department: "CORPORATION", staff: "JULLYANA GORILA ANAK JENOS" },
  { department: "CORPORATION", staff: "CHIN LEE HUA" },
  { department: "CORPORATION", staff: "BRIAN JOVY ANAK AHMEW" },
  { department: "CORPORATION", staff: "MOHD. HAZRUL ALEX BIN ABDULLAH" },
  { department: "CORPORATION", staff: "MOHAMMAD REDZUAN BIN ABDULLAH" },
  { department: "CORPORATION", staff: "DIANA BIDAI ANAK MENDALI" },
  { department: "CORPORATION", staff: "VORNICA NAYAN ANAK NANANG" },
  { department: "CORPORATION", staff: "BRIGETTE BRENDA BASE" },
  { department: "CORPORATION", staff: "NURUL HURAIRAH BINTI SARIP" },
  { department: "CORPORATION", staff: "CHENDANG ANAK THOMAS ALI" },
  { department: "CORPORATION", staff: "JONG KIM WONG" },
  { department: "CORPORATION", staff: "VERONICA SULAN ANAK VINCENT" },
  { department: "CORPORATION", staff: "SYLVESTER ANAK KENETH KANYAN" },
  { department: "CORPORATION", staff: "MAINA NICKOLA ANAK MICHILE" },
  { department: "CORPORATION", staff: "KHO HO SIA, GEORGE" },
  { department: "CORPORATION", staff: "LESTER LUNA ANAK ULOK" },
  { department: "ADMINISTRATION", staff: "YUNG MEI HUA, MANDY" },
  { department: "ADMINISTRATION", staff: "EDWARD TENLEY AK FRANCIS KETIR" },
  { department: "IT", staff: "LO JAK FATT, EVENCE" },
  { department: "IT", staff: "GASTON QION EMMANUEL AK GEORGE" },
  { department: "IT", staff: "TAN KEENG LING" },
  { department: "IT", staff: "ABUZAR GHIFARI BIN ZAKARIA" },
  { department: "PROPERTY", staff: "YVONNE SHARON ANAK MIKOLDEN" },
  { department: "PROPERTY", staff: "LIM ZHEN YI, RONALD" },
  { department: "PROPERTY", staff: "JOTHINI NAIR NARANSAMY GOVINDA NAIR" },
  { department: "PROPERTY", staff: "SYLVIA ANAK CHAONG" },
  { department: "PROPERTY", staff: "KATHERINE SONG" },
  { department: "PROPERTY", staff: "ONG PEI CHEN, SOPHIA" },
];

export const STAFFS = [
  {
    fullname: "LIEW SZE YAH, NICOLE",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "13/11/2017",
  },
  {
    fullname: "NGO KEH TOH, ANNIE",
    position: "ASSISTANT MANAGER - CONTRACTS",
    job_grade: "E3",
    date_joined: "01/11/2005",
  },
  {
    fullname: "AU WAN SEE, YVONNE",
    position: "PERSONAL ASSISTANT CUM ADMIN OFFICER",
    job_grade: "NES4",
    date_joined: "03/03/2014",
  },



  {
    fullname: "TAY CHIOK KEE",
    position: "GENERAL MANAGER - PROPERTY DEVELOPMENT",
    job_grade: "GM1",
    date_joined: "19/08/1996*",
  },
  {
    fullname: "TIONG TECK YIENG",
    position: "GROUP ACCOUNTANT CUM COMPANY SECRETARY",
    job_grade: "SM1",
    date_joined: "12/05/1997",
  },
  {
    fullname: "YONG SERN PIAT",
    position: "IT MANAGER",
    job_grade: "M2",
    date_joined: "11/04/2005*",
  },
  {
    fullname: "STEPHEN TAN SOON HONG",
    position: "NETWORK ADMIN MANAGER",
    job_grade: "M1",
    date_joined: "05/10/1995*",
  },
  {
    fullname: "CATHERINE CHO SING YIENG",
    position: "SENIOR MANAGER – FINANCE, CORPORATE & SYSTEM DEVELOPMENT ",
    job_grade: "SM1",
    date_joined: "21/05/2018",
  },
  {
    fullname: "TANG LING PING, JENNIFER",
    position: "SENIOR MANAGER - HR & CORPORATE COMMUNICATIONS",
    job_grade: "SM1",
    date_joined: "17/10/1995",
  },
  {
    fullname: "TAY CHIOK KEE",
    position: "SENIOR MANAGER - PROCUREMENT & ADMIN",
    job_grade: "SM2",
    date_joined: "19/08/1996",
  },
  {
    fullname: "JENNY YU PICK HAI",
    position: "SENIOR MANAGER - TREASURY",
    job_grade: "SM1",
    date_joined: "01/03/1995",
  },
  {
    fullname: "TAN KOK SAN, NICOLE",
    position: "SENIOR OFFICER – PROJECTS ADMIN CUM ASSISTANT QMR",
    job_grade: "E2",
    date_joined: "15/02/2008*",
  },
  {
    fullname: "ELLY ANAK WILFRED",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "13/07/2010*",
  },
  {
    fullname: "VERONICA A/P NOED",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE3",
    date_joined: "07/06/2010*",
  },
  {
    fullname: "MARCOS ANAK WARREN SAOH",
    position: "ASSISTANT SUPERVISOR",
    job_grade: "NES4",
    date_joined: "01/08/2005",
  },
  {
    fullname: "AZALIZA BINTI AMIN",
    position: "GENERAL CLERK",
    job_grade: "NE2",
    date_joined: "14/04/2005*",
  },
  {
    fullname: "CECILIA ANAK DAPAN",
    position: "GENERAL CLERK",
    job_grade: "NE2",
    date_joined: "01/10/2012*",
  },
  {
    fullname: "HELMI BIN PUTEH",
    position: "PLANT SUPERVISOR",
    job_grade: "NES4",
    date_joined: "15/04/2005",
  },
  {
    fullname: "SABREE BIN SABU",
    position: "QA TECHNICIAN",
    job_grade: "NE2",
    date_joined: "09/08/2017",
  },
  {
    fullname: "NORBAYU BT MUKU",
    position: "QC SUPERVISOR",
    job_grade: "NES4",
    date_joined: "24/02/1995*",
  },
  {
    fullname: "NGUANG KIM KIONG",
    position: "SUPERVISOR",
    job_grade: "NES5",
    date_joined: "01/04/1994*",
  },
  {
    fullname: "LESLEY JENTANG ANAK JOHNNY",
    position: "ACCOUNTS CLERK",
    job_grade: "NE2",
    date_joined: "05/06/2017",
  },
  {
    fullname: "JACKSON CHAI KEN NAM",
    position: "ACCOUNTS CLERK",
    job_grade: "NE2",
    date_joined: "09/05/2017",
  },
  {
    fullname: "KHO BOON POH",
    position: "ACCOUNTS CLERK",
    job_grade: "NE2",
    date_joined: "05/05/2011*",
  },
  {
    fullname: "HELY ANAK SOTIN",
    position: "ACCOUNTS CLERK",
    job_grade: "NE2",
    date_joined: "08/03/2010*",
  },
  {
    fullname: "FOO HUI HUI, GRACE",
    position: "ACCOUNTS OFFICER",
    job_grade: "NES4",
    date_joined: "18/05/2011",
  },
  {
    fullname: "THON HUI HUI",
    position: "ACCOUNTS OFFICER",
    job_grade: "E1",
    date_joined: "19/04/2021",
  },
  {
    fullname: "FOO HUI SIEN",
    position: "ACCOUNTS CLERK",
    job_grade: "NE3",
    date_joined: "04/07/2016*",
  },
  {
    fullname: "YONG SIEW TING, CONNIE",
    position: "ACCOUNTANT",
    job_grade: "M1",
    date_joined: "04/01/2011",
  },
  {
    fullname: "SUSIE HO HUI PHING",
    position: "ACCOUNTS OFFICER",
    job_grade: "NES5",
    date_joined: "18/03/2002",
  },
  {
    fullname: "WEE MOI CHOO",
    position: "ACCOUNTS OFFICER",
    job_grade: "NES4",
    date_joined: "22/04/1985",
  },
  {
    fullname: "NG SEE MEI",
    position: "ASSISTANT ACCOUNTANT",
    job_grade: "E3",
    date_joined: "09/11/2015*",
  },
  {
    fullname: "JOSEPHINE FOO SIEW PHENG",
    position: "ASSISTANT ACCOUNTANT",
    job_grade: "E3",
    date_joined: "04/06/2003",
  },
  {
    fullname: "TIONG SIEW LING",
    position: "ASSISTANT ACCOUNTANT",
    job_grade: "E3",
    date_joined: "01/03/2010",
  },
  {
    fullname: "CHONG SIAW HUA",
    position: "ASSISTANT MANAGER - SALES ADMIN",
    job_grade: "NES5",
    date_joined: "16/02/2004*",
  },
  {
    fullname: "SIM YUH SHIUN",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "03/01/2023",
  },
  {
    fullname: "JENNIFFER JAWAI ANAK ANTHONEY LIM",
    position: "SITE CLERK",
    job_grade: "NE2",
    date_joined: "18/05/2020",
  },
  {
    fullname: "HASNIYATI HII BINTI ABDULLAH",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE3",
    date_joined: "01/08/2007",
  },
  {
    fullname: "KATHERINE YII JOU PEI",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE3",
    date_joined: "01/08/2013",
  },
  {
    fullname: "LAI CHEAU NEE",
    position: "SENIOR QS",
    job_grade: "E2",
    date_joined: "16/01/2008",
  },
  {
    fullname: "LIM WEI SING",
    position: "SENIOR QS",
    job_grade: "E1",
    date_joined: "25/05/2009",
  },
  {
    fullname: "ERIC UNTAMD ANAK MARK",
    position: "ASSISTANT SAFETY & HEALTH OFFICER",
    job_grade: "NES4",
    date_joined: "15/05/2017",
  },
  {
    fullname: "LING WANG CHING, EDMUND",
    position: "SAFETY & HEALTH OFFICER",
    job_grade: "E3",
    date_joined: "24/04/2019",
  },
  {
    fullname: "CALVIN ANAK MAWAR",
    position: "SAFETY & HEALTH OFFICER",
    job_grade: "NES6",
    date_joined: "12/04/2021",
  },
  {
    fullname: "SENADA ANAK BAJING",
    position: "SAFETY & HEALTH OFFICER",
    job_grade: "NES6",
    date_joined: "14/12/2020",
  },
  {
    fullname: "TANG KIE CHIONG",
    position: "SAFETY & HEALTH OFFICER",
    job_grade: "E3",
    date_joined: "01/01/2018",
  },
  {
    fullname: "SITI ZALEHA BINTI ZEN",
    position: "SAFETY & HEALTH OFFICER",
    job_grade: "NES6",
    date_joined: "19/10/2015",
  },
  {
    fullname: "JIMMY ANAK BELAJA",
    position: "SAFETY & HEALTH OFFICER",
    job_grade: "E3",
    date_joined: "01/12/2020",
  },
  {
    fullname: "SO HU WEI",
    position: "SAFETY & HEALTH OFFICER",
    job_grade: "E3",
    date_joined: "01/04/2018",
  },
  {
    fullname: "WILLY ANAK UDAU",
    position: "SITE SAFETY SUPERVISOR",
    job_grade: "NES4",
    date_joined: "27/09/2021",
  },
  {
    fullname: "JAMES VALENTINE ANAK JEFFERY",
    position: "SITE SAFETY SUPERVISOR",
    job_grade: "NES5",
    date_joined: "20/12/2021",
  },
  {
    fullname: "CAMILLA MARCIA KEDUAK ANAK UNYUK",
    position: "SITE SAFETY SUPERVISOR",
    job_grade: "NES4",
    date_joined: "27/07/2020",
  },
  {
    fullname: "MUHAMMAD BIN SARIP",
    position: "SITE SAFETY SUPERVISOR",
    job_grade: "NES4",
    date_joined: "27/12/2016",
  },
  {
    fullname: "BESTER ANAK RIBAT",
    position: "SITE SAFETY SUPERVISOR",
    job_grade: "NES4",
    date_joined: "03/11/2015*",
  },
  {
    fullname: "ALVIN LOH",
    position: "SITE SAFETY SUPERVISOR",
    job_grade: "NES4",
    date_joined: "05/06/2017",
  },
  {
    fullname: "MOHAMAD HATTA BIN ROSIDI",
    position: "SITE SAFETY SUPERVISOR ",
    job_grade: "NES4",
    date_joined: "01/09/1999",
  },
  {
    fullname: "WELLINGTON ANAK JACK",
    position: "TRAFFIC MANAGEMENT OFFICER",
    job_grade: "NES4",
    date_joined: "14/03/2023",
  },
  {
    fullname: "FELIX NICO ANAK RAMLI",
    position: "TRAFFIC MANAGEMENT OFFICER",
    job_grade: "NES4",
    date_joined: "07/06/2023",
  },
  {
    fullname: "JOHN WISS ANAK JARO",
    position: "TRAFFIC MANAGEMENT OFFICER",
    job_grade: "NES4",
    date_joined: "23/04/2019",
  },
  {
    fullname: "LAU SIE CHUNG",
    position: "MECHANIC",
    job_grade: "NES4",
    date_joined: "01/01/2020",
  },
  {
    fullname: "TING SII KIONG",
    position: "STORE SUPERVISOR",
    job_grade: "NES4",
    date_joined: "01/01/2020",
  },
  {
    fullname: "LEE JIE LING",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NES4",
    date_joined: "05/04/2021",
  },
  {
    fullname: "SARAH ANAK DONYSOS",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "02/05/2018",
  },
  {
    fullname: "MUHAMMAD ALFIAN WIRA SEPTIAN",
    position: "PROJECT ENGINEER",
    job_grade: "E2",
    date_joined: "01/08/2019*",
  },
  {
    fullname: "LING TUNG YEK",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "07/12/2020",
  },
  {
    fullname: "RIONSIL DOLO MENDILA",
    position: "PROJECT ENGINEER",
    job_grade: "E2",
    date_joined: "01/02/2019*",
  },
  {
    fullname: "CHIN CHEE HAO",
    position: "SITE COORDINATOR",
    job_grade: "NES6",
    date_joined: "01/01/2021",
  },
  {
    fullname: "LIM KIAN BENG",
    position: "SITE MANAGER",
    job_grade: "M1",
    date_joined: "16/03/2010",
  },
  {
    fullname: "OW WAN FOO, EDDIE",
    position: "ELECTRICAL ENGINEER",
    job_grade: "NES5",
    date_joined: "14/12/2015",
  },
  {
    fullname: "LUI RUI YING",
    position: "PERSONAL ASSISTANT",
    job_grade: "NE2",
    date_joined: "04/07/2018",
  },

  {
    fullname: "LEE KIN TAT",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "07/03/2023",
  },
  {
    fullname: "PETER LAW KIING KING",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "13/12/2021",
  },
  {
    fullname: "WEE GUEK YIAN",
    position: "ASSISTANT MANAGER - TREASURY",
    job_grade: "NES5",
    date_joined: "08/10/1993*",
  },
  {
    fullname: "LU KA LANG @ LU KAH NANG",
    position: "GENERAL COORDINATOR",
    job_grade: "NES4",
    date_joined: "07/04/1997",
  },
  {
    fullname: "NUR ASFIA BIN MOHAMAD DOLLAH",
    position: "OFFICE ASSISTANT",
    job_grade: "NE1",
    date_joined: "16/03/2010*",
  },
  {
    fullname: "DAUD BIN MAHMOR",
    position: "OFFICE ASSISTANT",
    job_grade: "NE1",
    date_joined: "01/10/2011*",
  },
  {
    fullname: "YUSUF BIN BAHARI",
    position: "OFFICE ASSISTANT",
    job_grade: "NE1",
    date_joined: "19/08/2016",
  },
  {
    fullname: "LAW DUEN SIONG",
    position: "SENIOR TREASURY CLERK",
    job_grade: "NE3",
    date_joined: "03/10/2005*",
  },
  {
    fullname: "WEI SZE NEI",
    position: "SENIOR TREASURY OFFICER",
    job_grade: "E2",
    date_joined: "01/09/2010",
  },
  {
    fullname: "TAY GUAN PHING",
    position: "TREASURY CLERK",
    job_grade: "NE3",
    date_joined: "05/05/2015*",
  },
  {
    fullname: "NANCY THU MEI SZE",
    position: "TREASURY CLERK",
    job_grade: "NE2",
    date_joined: "01/09/2016*",
  },
  {
    fullname: "NG WAN LI, WENDY",
    position: "TREASURY CLERK",
    job_grade: "NE2",
    date_joined: "18/05/2020",
  },
  {
    fullname: "BONG JOO CHEE",
    position: "TREASURY OFFICER",
    job_grade: "NES4",
    date_joined: "05/06/2007*",
  },
  {
    fullname: "TING YING SIEW",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "16/03/2012",
  },
  {
    fullname: "PRESCILLIA ANAK PHILIP",
    position: "ASSISTANT PROJECT PLANNER",
    job_grade: "E1",
    date_joined: "17/10/2016",
  },
  {
    fullname: "UMPANG SAONG",
    position: "ASSISTANT SURVEYOR",
    job_grade: "NES4",
    date_joined: "01/08/2019",
  },
  {
    fullname: "ALBERT CHUNG SWEE HENG",
    position: "CONSTRUCTION MANAGER",
    job_grade: "M2",
    date_joined: "01/09/2018",
  },
  {
    fullname: "CHIENG SHAW YAW",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "01/08/2018",
  },
  {
    fullname: "JOHNNY WONG HING KIONG",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "15/10/2021",
  },
  {
    fullname: "MUHAMMAD AMIRUL RIDHZUAN STANNY",
    position: "PROJECT ENGINEER",
    job_grade: "NES5",
    date_joined: "05/07/2010",
  },
  {
    fullname: "STANLEY MALAKA",
    position: "QUANTITY SURVEYOR",
    job_grade: "E1",
    date_joined: "26/02/2020",
  },
  {
    fullname: "TING JIT JIE",
    position: "QUANTITY SURVEYOR",
    job_grade: "E1",
    date_joined: "01/07/2020",
  },
  {
    fullname: "HO AI CHIN",
    position: "SENIOR PROJECT ENGINEER",
    job_grade: "E2",
    date_joined: "05/12/2011",
  },
  {
    fullname: "ARIF BIN CHIK",
    position: "SITE COORDINATOR",
    job_grade: "NES4",
    date_joined: "17/05/2021",
  },
  {
    fullname: "MOHAMMAD AZEIR BIN UMIN",
    position: "SURVEYOR",
    job_grade: "NES5",
    date_joined: "02/06/1998*",
  },
  {
    fullname: "TIBU ANAK DAUD",
    position: "SURVEYOR",
    job_grade: "NES5",
    date_joined: "16/07/2002",
  },
  {
    fullname: "DOLLY ASHLEY ANAK MANDRA",
    position: "ASSISTANT QS",
    job_grade: "NES4",
    date_joined: "03/01/2021",
  },
  {
    fullname: "VELDA CHANG CHIA YIN",
    position: "GRAPHIC DESIGNER",
    job_grade: "E1",
    date_joined: "12/10/2020",
  },
  {
    fullname: "SIM YIH SENG",
    position: "ASSISTANT GENERAL MANAGER - PROJECTS",
    job_grade: "SM2",
    date_joined: "08/06/2009",
  },
  {
    fullname: "LAU YUK SIONG, PHILIP",
    position: "ASSISTANT GENERAL MANAGER - PROJECTS",
    job_grade: "SM2",
    date_joined: "17/06/1996",
  },
  {
    fullname: "CHAN HOOD SOO",
    position: "CONTRACT MANAGER",
    job_grade: "M2",
    date_joined: "01/11/1996",
  },
  {
    fullname: "JONG SIAW FOONG, STEVEN",
    position: "CONTRACT MANAGER",
    job_grade: "M2",
    date_joined: "02/04/1997",
  },
  {
    fullname: "CHINDAI ANAK JOSEPH",
    position: "DRIVER",
    job_grade: "NES4",
    date_joined: "05/06/2017",
  },
  {
    fullname: "WONG ING SIU, THOMAS",
    position: "GENERAL MANAGER - COMMERCIAL",
    job_grade: "SM1",
    date_joined: "13/08/2001",
  },
  {
    fullname: "SIM KAY CHIAN, STEPHEN",
    position: "LAB SUPERVISOR",
    job_grade: "NES5",
    date_joined: "02/05/1998",
  },
  {
    fullname: "HENG MUI KEE",
    position: "MANAGER - OFFICE ADMIN & HEAD OF PA",
    job_grade: "M1",
    date_joined: "16/09/1993",
  },
  {
    fullname: "HON JUN KHIONG",
    position: "PROJECT MANAGER",
    job_grade: "M2",
    date_joined: "20/11/2017",
  },
  {
    fullname: "SIAW WEN YIAP, MICHEAL",
    position: "PROJECT MANAGER",
    job_grade: "M2",
    date_joined: "09/06/2008*",
  },
  {
    fullname: "ALVIN CHONG TZE HUI",
    position: "SENIOR CONTRACTS MANAGER",
    job_grade: "SM1",
    date_joined: "01/08/2002*",
  },
  {
    fullname: "CHAI TZE LIK",
    position: "SENIOR MANAGER - CONTRACT / PROJECTS",
    job_grade: "SM1",
    date_joined: "01/11/2019",
  },
  {
    fullname: "JIMENO JR. LUGUE JERUSALEM",
    position: "SENIOR PROJECT MANAGER",
    job_grade: "SM2",
    date_joined: "04/12/2003*",
  },
  {
    fullname: "LIM KHIN YONG, JOHN",
    position: "SENIOR PROJECT MANAGER",
    job_grade: "SM2",
    date_joined: "01/09/2019",
  },
  {
    fullname: "CHIN YIAN CHON, ALSON",
    position: "SENIOR PROJECT MANAGER",
    job_grade: "SM1",
    date_joined: "15/05/2017",
  },
  {
    fullname: "JONG SUK FANG",
    position: "SENIOR QS",
    job_grade: "E3",
    date_joined: "08/04/2019",
  },
  {
    fullname: "LAU KIEW HING",
    position: "ADMINISTRATIVE OFFICER",
    job_grade: "NES4",
    date_joined: "12/10/2004",
  },
  {
    fullname: "HENRY ANAK ATA",
    position: "ASSISTANT SURVEYOR",
    job_grade: "NES4",
    date_joined: "02/12/1993",
  },
  {
    fullname: "JAVAN LIEW SAN JER",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "11/10/2021",
  },
  {
    fullname: "SIEW UO SIONG",
    position: "PROJECT MANAGER",
    job_grade: "M2",
    date_joined: "09/02/2004",
  },
  {
    fullname: "SIM YIK CHWIN",
    position: "SENIOR PROJECT ENGINEER",
    job_grade: "E2",
    date_joined: "29/10/2003",
  },
  {
    fullname: "DIANA LEE MEI MEI",
    position: "SENIOR QS",
    job_grade: "E3",
    date_joined: "10/12/2018",
  },
  {
    fullname: "KO LIAN CHUENG, KENNETH",
    position: "SENIOR PROJECT ENGINEER",
    job_grade: "E2",
    date_joined: "01/03/2004*",
  },
  {
    fullname: "DORIS ANAK WILSON ELAH",
    position: "GENERAL CLERK ",
    job_grade: "NE2",
    date_joined: "16/06/2016",
  },
  {
    fullname: "TING SIAW WEE",
    position: "OFFICER - PROJECT ADMIN ",
    job_grade: "E1",
    date_joined: "01/12/2010*",
  },
  {
    fullname: "SOON CHONG TECK",
    position: "SITE SUPERVISOR",
    job_grade: "NES4",
    date_joined: "14/03/2008",
  },
  {
    fullname: "LIEW HOCK RUI",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "11/11/2019",
  },
  {
    fullname: "KELVIN YII SENG HUA",
    position: "PROJECT ENGINEER CUM QAE ",
    job_grade: "E2",
    date_joined: "01/07/2019",
  },
  {
    fullname: "RONIE LAU HUI LIONG",
    position: "QUANTITY SURVEYOR",
    job_grade: "E1",
    date_joined: "17/02/2020",
  },
  {
    fullname: "ANGELA KATHY ANAK KINTANG",
    position: "QUANTITY SURVEYOR CUM PROJECT EXECUTIVE ",
    job_grade: "E1",
    date_joined: "06/01/2014*",
  },
  {
    fullname: "OLIZARENA ANAK ENA",
    position: "GENERAL CLERK",
    job_grade: "NE2",
    date_joined: "24/10/2011*",
  },
  {
    fullname: "WAN MOHAMAD BIN WAN ALLI",
    position: "OFFICE ASSISTANT",
    job_grade: "NE1",
    date_joined: "16/04/2009*",
  },
  {
    fullname: "GOH CHIN SING",
    position: "CONSTRUCTION MANAGER",
    job_grade: "M1",
    date_joined: "01/07/2020*",
  },
  {
    fullname: "PERNANDEZ TULIS",
    position: "M & E ENGINEER",
    job_grade: "NES4",
    date_joined: "01/10/2021",
  },
  {
    fullname: "SYLVESTER MIKE LIEW CHO KEAT",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "01/04/2023",
  },
  {
    fullname: "AUDREY PEARL ANAK DEHU @ RICHARD NERU",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "16/05/2023",
  },
  {
    fullname: "SHERIN ANAK SIMEK",
    position: "SITE CLERK",
    job_grade: "NE1",
    date_joined: "01/12/2019",
  },
  {
    fullname: "TANG SING HUI",
    position: "SITE MANAGER",
    job_grade: "M1",
    date_joined: "23/12/2003",
  },
  {
    fullname: "DAVIDSON AK DAYUT",
    position: "SURVEYOR",
    job_grade: "NES5",
    date_joined: "24/06/1996",
  },
  {
    fullname: "HA HAW DING",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "27/06/2020",
  },
  {
    fullname: "JUSTINE ANAK MALAKA",
    position: "ENVIRONMENTAL OFFICER",
    job_grade: "E1",
    date_joined: "13/09/2016",
  },
  {
    fullname: "WONG LEE HOE",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "26/08/2019",
  },
  {
    fullname: "SYLVIA TANG CHUNG TIEN",
    position: "QUANTITY SURVEYOR",
    job_grade: "E1",
    date_joined: "03/01/2017",
  },
  {
    fullname: "KUNJAN ANAK ENGGONG",
    position: "SURVEYOR",
    job_grade: "NES5",
    date_joined: "01/12/2008",
  },
  {
    fullname: "MARVIN ANAK HENDARY",
    position: "M & E ENGINEER",
    job_grade: "E2",
    date_joined: "06/07/2017",
  },
  {
    fullname: "SITI HAJAR BINTI MOHD YUSOP",
    position: "PROJECT ENGINEER",
    job_grade: "E2",
    date_joined: "01/09/2016",
  },
  {
    fullname: "CHUA SIAW TED",
    position: "SITE MANAGER",
    job_grade: "M2",
    date_joined: "16/07/2012*",
  },
  {
    fullname: "NORSAMSUDDIN ANAK BANGUN",
    position: "SURVEYOR CUM SUPERVISOR ",
    job_grade: "NES5",
    date_joined: "15/08/2008",
  },
  {
    fullname: "HANNA SANTARINA ANAK MAURICE",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "01/09/2011",
  },
  {
    fullname: "CHANG FU BOON",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "11/05/2021",
  },
  {
    fullname: "KHO KIEN QUAN",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "07/12/2020",
  },
  {
    fullname: "SUZANNA ANAK RAGAM",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "06/05/2023",
  },
  {
    fullname: "DAPHNE DONNA BINTI MARTIN",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "01/01/2018",
  },
  {
    fullname: "FEILYCIA ANAK SAMBANG",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "06/06/2017",
  },
  {
    fullname: "ROBICA ANAK KALONG",
    position: "OFFICER - PROJECTS ADMIN CUM PURCHASING ASSISTANT",
    job_grade: "NES4",
    date_joined: "24/04/2019",
  },
  {
    fullname: "ABDUL WARITH BIN MOHAMMAD PHILIP",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "01/05/2019",
  },
  {
    fullname: "SELINBIT ANAK MOS",
    position: "SURVEYOR",
    job_grade: "NES6",
    date_joined: "16/06/2000",
  },
  {
    fullname: "ROSELIND IKAH ANAK ROBERT @ KELIMBANG",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "11/12/2017",
  },
  {
    fullname: "SHIRLEY WONG LEE SAN",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "E1",
    date_joined: "21/06/2021",
  },
  {
    fullname: "CHAI PEI SZE",
    position: "ADMINISTRATIVE OFFICER",
    job_grade: "E1",
    date_joined: "04/01/2021",
  },
  {
    fullname: "CHIANG HENG HO",
    position: "ADMINISTRATIVE OFFICER",
    job_grade: "NES4",
    date_joined: "17/08/2009",
  },
  {
    fullname: "LAI SIEW LIEN",
    position: "ADMINISTRATIVE OFFICER",
    job_grade: "E1",
    date_joined: "15/06/2020",
  },
  {
    fullname: "SIM POH KIAW, EMELYN",
    position: "ADMINISTRATIVE OFFICER",
    job_grade: "E1",
    date_joined: "03/07/2017",
  },
  {
    fullname: "CHAI CHANG LECK, ERIC",
    position: "ASSISTANT PROJECT MANAGER",
    job_grade: "NES6",
    date_joined: "01/04/2013",
  },
  {
    fullname: "CHRIS HUDSON ANAK BUJANG",
    position: "DRAFTSMAN",
    job_grade: "NES4",
    date_joined: "08/01/2018",
  },
  {
    fullname: "TOH JAG HUI",
    position: "M & E COORDINATOR",
    job_grade: "NES5",
    date_joined: "18/09/2012",
  },
  {
    fullname: "CINDY LIEW",
    position: "PROJECT ENGINEER",
    job_grade: "E2",
    date_joined: "17/09/2019",
  },
  {
    fullname: "KELVIN CHAI HOCK JOI",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "09/07/2012*",
  },
  {
    fullname: "LIM KAI HENG",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "15/04/2019",
  },
  {
    fullname: "VIVIANA ONG ZHI JINN",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "03/08/2018*",
  },
  {
    fullname: "RAYNOLD ANAK MERUM",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "01/01/2019",
  },
  {
    fullname: "ERNA MARIANA ANAK JUMAT",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "19/07/2021",
  },
  {
    fullname: "LEE PING CHIONG",
    position: "PROJECT MANAGER",
    job_grade: "M2",
    date_joined: "16/12/2021",
  },
  {
    fullname: "TING SING HWA",
    position: "PROJECT MANAGER",
    job_grade: "M2",
    date_joined: "17/08/2009",
  },
  {
    fullname: "LING KHO TUNG, TOMMY",
    position: "PROJECT MANAGER",
    job_grade: "M1",
    date_joined: "02/01/2015",
  },
  {
    fullname: "ARCHIBAL CATIBOG MANANSALA",
    position: "PROJECT MANAGER",
    job_grade: "M2",
    date_joined: "03/10/2011",
  },
  {
    fullname: "HO CHEE HONG",
    position: "SENIOR PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "01/10/2015*",
  },
  {
    fullname: "LEE KUET LUNG",
    position: "SENIOR PROJECT ENGINEER",
    job_grade: "E2",
    date_joined: "02/11/2009*",
  },
  {
    fullname: "CHAI SAM CHOI",
    position: "SITE COORDINATOR",
    job_grade: "NES5",
    date_joined: "07/09/2009",
  },
  {
    fullname: "CHIN WEI KING, ERIC",
    position: "SITE COORDINATOR ",
    job_grade: "NES6",
    date_joined: "15/02/1997",
  },
  {
    fullname: "LEE MOOK FUA",
    position: "SITE MANAGER",
    job_grade: "M2",
    date_joined: "01/09/2018",
  },
  {
    fullname: "CHIANG SIONG ING",
    position: "SITE MANAGER",
    job_grade: "M2",
    date_joined: "13/04/2009",
  },
  {
    fullname: "CHONG YU CHIAU",
    position: "SITE SUPERVISOR",
    job_grade: "NES4",
    date_joined: "01/02/2015*",
  },
  {
    fullname: "TULIS ANAK CHANDANG",
    position: "SURVEYOR",
    job_grade: "NES5",
    date_joined: "03/06/2009",
  },
  {
    fullname: "LESTINLEY LUIS ANAK LAWAI",
    position: "SURVEYOR",
    job_grade: "NES5",
    date_joined: "15/03/2021",
  },
  {
    fullname: "LING FUNG ENG",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE3",
    date_joined: "12/08/2013",
  },
  {
    fullname: "MONA KEDAYA ANAK STORY",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "10/08/2020",
  },
  {
    fullname: "SANDRA ANAK JAWING",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE3",
    date_joined: "04/04/2016",
  },
  {
    fullname: "SONIA VIVIANI ANAK JANIS",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "01/10/2018",
  },
  {
    fullname: "FADILLAH BTE ABDULLAH",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "01/10/1996*",
  },
  {
    fullname: "HII CHUI YEN, IVY",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE3",
    date_joined: "16/08/2010",
  },
  {
    fullname: "ELSIE LEE SUK CHER",
    position: "ADMINISTRATIVE OFFICER – PROJECTS / QMS",
    job_grade: "NES4",
    date_joined: "15/09/2021",
  },
  {
    fullname: "DAYANG NUR AININA BINTI ABG MOHAMAD",
    position: "GENERAL CLERK",
    job_grade: "NE2",
    date_joined: "03/12/2013",
  },
  {
    fullname: "NAJA ANAK NYAUN",
    position: "PROJECTS ADMINISTRATION OFFICER",
    job_grade: "NES4",
    date_joined: "26/06/1995*",
  },
  {
    fullname: "VOON KHENG HUA, VALERIE",
    position: "PROJECTS ADMINISTRATION OFFICER",
    job_grade: "NES4",
    date_joined: "03/07/2006",
  },
  {
    fullname: "IRENE KHO AI LING",
    position: "ASSISTANT MANAGER - HR",
    job_grade: "E3",
    date_joined: "20/08/2002*",
  },
  {
    fullname: "LEE SHAW HIENG",
    position: "HR OFFICER",
    job_grade: "E1",
    date_joined: "04/09/2017",
  },
  {
    fullname: "VIVIAN FU JIA WEN",
    position: "HR OFFICER",
    job_grade: "E1",
    date_joined: "11/03/2021",
  },
  {
    fullname: "HO SIAW LEE, LILY",
    position: "HR OFFICER",
    job_grade: "NES4",
    date_joined: "02/05/2017*",
  },
  {
    fullname: "TANG KUI SIEW, DEBBY",
    position: "HR SUPERVISOR",
    job_grade: "E2",
    date_joined: "25/09/2012",
  },
  {
    fullname: "IRWAN BIN BUJANG",
    position: "OFFICE ASSISTANT",
    job_grade: "NE1",
    date_joined: "25/05/2009",
  },
  {
    fullname: "SHARON NG KHE SWYN",
    position: "MARKETING EXECUTIVE",
    job_grade: "E1",
    date_joined: "02/05/2018",
  },
  {
    fullname: "ALVIN SIA LOONG WEI",
    position: "SALES EXECUTIVE",
    job_grade: "NES4",
    date_joined: "14/05/2018",
  },
  {
    fullname: "FARAHANI BINTI HAINI",
    position: "SALES EXECUTIVE",
    job_grade: "E1",
    date_joined: "02/04/2012",
  },
  {
    fullname: "PHANG MING JING, RAINIE",
    position: "SALES EXECUTIVE",
    job_grade: "E1",
    date_joined: "12/09/2018",
  },
  {
    fullname: "TOMMY LEE SOON MING",
    position: "PROJECT ENGINEER",
    job_grade: "E1",
    date_joined: "01/12/2021",
  },
  {
    fullname: "JOHAN ANAK JAWA",
    position: "ASSISTANT SURVEYOR",
    job_grade: "NES4",
    date_joined: "01/04/2011",
  },
  {
    fullname: "HII YII SHIN",
    position: "ASSISTANT SITE MANAGER",
    job_grade: "E3",
    date_joined: "14/07/2016",
  },
  {
    fullname: "TIE SHIE LING",
    position: "PURCHASING OFFICER",
    job_grade: "E1",
    date_joined: "15/11/2019*",
  },
  {
    fullname: "ABDUL AZIZ BIN HIPENI",
    position: "GENERAL COORDINATOR",
    job_grade: "NES4",
    date_joined: "01/08/2002",
  },
  {
    fullname: "ALBINUS PETRUS RATIM NGIBAN",
    position: "ADMINISTRATIVE OFFICER",
    job_grade: "NES4",
    date_joined: "03/10/2011*",
  },
  {
    fullname: "LINDA ANAK BUYONG",
    position: "ADMINISTRATIVE CLERK",
    job_grade: "NE2",
    date_joined: "01/03/2016",
  },
  {
    fullname: "VICTORIA ANAK UMPANG",
    position: "RECEPTION OFFICER",
    job_grade: "NES4",
    date_joined: "15/04/1993*",
  },
  {
    fullname: "ALEXANDER LAI HORNG WEN",
    position: "ASSISTANT MANAGER - PROPERTY DEVELOPMENT",
    job_grade: "NES6",
    date_joined: "01/08/2016",
  },
  {
    fullname: "NGO JIN JIN",
    position: "ASSISTANT MANAGER - PURCHASING",
    job_grade: "E3",
    date_joined: "09/09/2008*",
  },
  {
    fullname: "ANGIE JOAN ANAK DANIEL",
    position: "ASSISTANT MANAGER - SALES & MARKETING",
    job_grade: "NES5",
    date_joined: "15/12/2008",
  },
  {
    fullname: "TING KWAN HEE",
    position: "MANAGER - ADMIN CUM MALL OPERATION",
    job_grade: "M1",
    date_joined: "18/09/2000",
  },
  {
    fullname: "CHARLES LEE WEE TECK",
    position: "MANAGER - SAFETY & HEALTH",
    job_grade: "M1",
    date_joined: "18/01/2016",
  },
  {
    fullname: "YEO TANG TANG",
    position: "OFFICE EXECUTIVE",
    job_grade: "NES5",
    date_joined: "15/05/1990",
  },
  {
    fullname: "LESLIE LIM CHUNG LEONG",
    position: "OPERATIONS MANAGER",
    job_grade: "M1",
    date_joined: "17/10/2005*",
  },
  {
    fullname: "JOSEPH CHAI MING KIEN",
    position: "PROPERTY DEVELOMENT MANAGER",
    job_grade: "M1",
    date_joined: "03/06/2021",
  },
  {
    fullname: "ANGELINE ONG CHEAH JOO",
    position: "PROPERTY DEVELOPMENT OFFICER",
    job_grade: "E1",
    date_joined: "03/06/2021*",
  },
  {
    fullname: "LING SHEAU WEI",
    position: "QUANTITY SURVEYOR",
    job_grade: "E1",
    date_joined: "09/06/2020",
  },
  {
    fullname: "TIEO SEOW LING, LYN",
    position: "SALES EXECUTIVE",
    job_grade: "NES4",
    date_joined: "14/11/2016",
  },
  {
    fullname: "TAN YIEN LIN",
    position: "SENIOR ADMIN OFFICER",
    job_grade: "NES5",
    date_joined: "01/06/2012*",
  },
  {
    fullname: "THIAN FUI CHIUN, KERRY",
    position: "SENIOR ADMIN OFFICER",
    job_grade: "NES5",
    date_joined: "04/06/1997*",
  },
  {
    fullname: "CHIENG CHING KUOK",
    position: "SENIOR PROJECT ENGINEER",
    job_grade: "E2",
    date_joined: "01/10/2018",
  },
  {
    fullname: "TEO TAI WEE, STEPHEN",
    position: "SITE SUPERVISOR",
    job_grade: "NES5",
    date_joined: "05/10/2006",
  },
  {
    fullname: "CHUA WEE LEONG, RUDY",
    position: "SITE SUPERVISOR",
    job_grade: "NES5",
    date_joined: "18/12/2017",
  },
  {
    fullname: "BONNIE ANAK ANTHONY",
    position: "OFFICE ASSISTANT",
    job_grade: "NE1",
    date_joined: "11/01/2021*",
  },
  {
    fullname: "TUNI ANAK OUI JUAN",
    position: "OFFICE ASSISTANT",
    job_grade: "NE1",
    date_joined: "01/10/2019*",
  },
  {
    fullname: "ALI BIN SABRI",
    position: "OFFICE ASSISTANT",
    job_grade: "NE1",
    date_joined: "24/10/2020*",
  },
  {
    fullname: "HAIDI BIN MARANI",
    position: "OFFICE ASSISTANT",
    job_grade: "NE1",
    date_joined: "06/01/2015",
  },
  {
    fullname: "AMIRUL SYAZWAN BIN SAMRA",
    position: "TECHNICIAN - MAINTENANCE",
    job_grade: "NES4",
    date_joined: "19/04/2021",
  },
  {
    fullname: "WATTSON ZAMBRY AK SAKUPA",
    position: "TECHNICIAN - MAINTENANCE",
    job_grade: "NES4",
    date_joined: "01/03/2021",
  },
  {
    fullname: "JESECA ANAK NYAMBA",
    position: "GENERAL CLERK",
    job_grade: "NE2",
    date_joined: "22/06/2020",
  },
  {
    fullname: "CLINTON JAWA ANAK RICHARDLY",
    position: "MAINTENANCE OFFICER",
    job_grade: "E1",
    date_joined: "01/12/2020*",
  },
  {
    fullname: "SHALLERD ANAK NAPEG",
    position: "TECHNICAL OFFICER",
    job_grade: "NES4",
    date_joined: "24/08/2020",
  },
  {
    fullname: "ESTHER CH'NG PEI YAN",
    position: "ACCOUNTS OFFICER",
    job_grade: "NES4",
    date_joined: "01/09/2019",
  },
  {
    fullname: "SIM SIAT FUNG, ALICE",
    position: "ACCOUNTS OFFICER",
    job_grade: "NES4",
    date_joined: "22/08/2011",
  },
  {
    fullname: "NG KHOON CHIENG",
    position: "ACCOUNTS OFFICER",
    job_grade: "NES4",
    date_joined: "14/08/2003*",
  },
  {
    fullname: "IVAN JONG SZE YING",
    position: "ACCOUNTS OFFICER",
    job_grade: "NES4",
    date_joined: "13/08/2018",
  },
  {
    fullname: "BELINDA TAN CHAI YING",
    position: "SENIOR ACCOUNTS CLERK",
    job_grade: "NE3",
    date_joined: "22/07/2002*",
  },
  {
    fullname: "CHRISTINE YII SHU YEE",
    position: "ACCOUNTANT",
    job_grade: "M1",
    date_joined: "10/05/2021*",
  },
  {
    fullname: "SIM TONG SEN",
    position: "ACCOUNTANT",
    job_grade: "M1",
    date_joined: "28/12/2021",
  },
  {
    fullname: "LIM EK HUA",
    position: "ACCOUNTS ADMIN MANAGER",
    job_grade: "M1",
    date_joined: "01/11/1992*",
  },
  {
    fullname: "TAY YAI YING",
    position: "ACCOUNTS CLERK",
    job_grade: "NE2",
    date_joined: "10/10/2011",
  },
  {
    fullname: "ONG CHIAW SIAN",
    position: "ACCOUNTS CLERK",
    job_grade: "NE2",
    date_joined: "20/10/2021",
  },
  {
    fullname: "CHAI SIN HWEI",
    position: "ACCOUNTS MANAGER",
    job_grade: "M1",
    date_joined: "03/05/2005",
  },
  {
    fullname: "ANNIE LEE SIEW PHIN",
    position: "ACCOUNTS OFFICER",
    job_grade: "E1",
    date_joined: "15/01/2010*",
  },
  {
    fullname: "CHAI LI SWAN",
    position: "ACCOUNTS OFFICER",
    job_grade: "NES4",
    date_joined: "06/11/2006*",
  },
  {
    fullname: "CLEMENT CHOO YEK SHIUNG",
    position: "ACCOUNTS OFFICER",
    job_grade: "E1",
    date_joined: "01/12/2020",
  },
  {
    fullname: "YONG TING",
    position: "ACCOUNTS OFFICER",
    job_grade: "E1",
    date_joined: "09/07/2012",
  },
  {
    fullname: "TERRY THAM TZE PING",
    position: "ACCOUNTS OFFICER",
    job_grade: "E1",
    date_joined: "01/10/2018",
  },
  {
    fullname: "LING CHEW HUI, CHRISTINA",
    position: "ACCOUNTS OFFICER",
    job_grade: "E1",
    date_joined: "08/11/2021",
  },
  {
    fullname: "LINNA WONG",
    position: "ACCOUNTS OFFICER",
    job_grade: "E1",
    date_joined: "01/12/2021",
  },
  {
    fullname: "WONG WOAN MIING, RACHELLE",
    position: "ACCOUNTS OFFICER",
    job_grade: "E1",
    date_joined: "01/07/2014",
  },
  {
    fullname: "LAW SIEW NI, TIFFANY",
    position: "ACCOUNTS OFFICER",
    job_grade: "E1",
    date_joined: "03/01/2023",
  },
  {
    fullname: "SIA SOON GUEI",
    position: "ASSISTANT ACCOUNTANT",
    job_grade: "E3",
    date_joined: "01/11/2012*",
  },
  {
    fullname: "CHIN JIUN HUAH, ANGEL",
    position: "CORPORATE SECRETARIAL CUM LEGAL OFFICER",
    job_grade: "E1",
    date_joined: "12/11/2018",
  },
  {
    fullname: "TAN MIAW ENG, ANGELINE",
    position: "OFFICER - ACCOUNTS CUM PA TO GM",
    job_grade: "E1",
    date_joined: "10/11/2014",
  },
  {
    fullname: "FREDERICK CABUNGAN BANAWA",
    position: "ASSISTANT PROJECT MANAGER",
    job_grade: "E3",
    date_joined: "40973",
  },
  {
    fullname: "TIONG JIH MEI, JIMMY",
    position: "OPERATION & ADMIN MANAGER",
    job_grade: "M1",
    date_joined: "01/12/2009",
  },
  {
    fullname: "CHEE SIAW PAW",
    position: "OPERATIONS MANAGER",
    job_grade: "M1",
    date_joined: "01/01/2020",
  },
  {
    fullname: "YONG SIAW FONG, MICHELLE",
    position: "PERSONAL ASSISTANT",
    job_grade: "NES4",
    date_joined: "15/08/2011",
  },
  {
    fullname: "WONG MING",
    position: "PLANT MANAGER",
    job_grade: "M1",
    date_joined: "27/08/2001",
  },
  {
    fullname: "BONG IT FIAN",
    position: "PLANT MANAGER",
    job_grade: "M1",
    date_joined: "01/10/2021",
  },
  {
    fullname: "LENOR JOYCE SASOY LEGASPI-BANAWA",
    position: "PROJECT ENGINEER",
    job_grade: "E2",
    date_joined: "41767",
  },
  {
    fullname: "SIEW MING HUA",
    position: "PROJECT MANAGER",
    job_grade: "M2",
    date_joined: "01/11/2019",
  },
  {
    fullname: "BILLY JONG HUNG ENG",
    position: "SALES & ADMIN COORDINATOR",
    job_grade: "NES4",
    date_joined: "10/10/2014",
  },
  {
    fullname: "IRENE ENGGU",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "08/06/2009*",
  },
  {
    fullname: "MARIA SU",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE1",
    date_joined: "01/03/2020",
  },
  {
    fullname: "CHONG UU LEONG, ZACK",
    position: "MARKETING EXECUTIVE",
    job_grade: "NES4",
    date_joined: "15/04/2019",
  },
  {
    fullname: "TEO KEE HUA, DORIS",
    position: "MARKETING EXECUTIVE",
    job_grade: "NES4",
    date_joined: "01/09/2005",
  },
  {
    fullname: "JONG TZE SIN",
    position: "MARKETING MANAGER",
    job_grade: "M1",
    date_joined: "02/01/1999",
  },
  {
    fullname: "JOEL SU TAI TUNG",
    position: "OFFICER - QUALITY, SAFETY, HEALTH & ENVIRONMENTAL",
    job_grade: "NES5",
    date_joined: "12/01/2010",
  },
  {
    fullname: "MARIA JATTI ANAK ENA",
    position: "PERSONAL ASSISTANT / PA",
    job_grade: "NE3",
    date_joined: "26/11/2007",
  },
  {
    fullname: "LING SHI HING, ERIC",
    position: "SITE ENGINEER",
    job_grade: "E1",
    date_joined: "02/03/2017",
  },
  {
    fullname: "REMY ANAK JOHEL",
    position: "OFFICER - QAQC CUM DOCUMENT CONTROL",
    job_grade: "E1",
    date_joined: "26/08/2013",
  },
  {
    fullname: "PETRUS SCHENKER ANAK RABES",
    position: "ASSISTANT SUPERVISOR",
    job_grade: "NES4",
    date_joined: "02/10/2013*",
  },
  {
    fullname: "FABIANA ANAK CHAN",
    position: "GENERAL CLERK",
    job_grade: "NE2",
    date_joined: "02/07/2012",
  },
  {
    fullname: "MACCOLLIN DEE DANNY ERICK",
    position: "QA TECHNICIAN",
    job_grade: "NE2",
    date_joined: "09/10/2017",
  },
  {
    fullname: "NOOR MARLIDAH HAI",
    position: "STORE KEEPER",
    job_grade: "NE2",
    date_joined: "15/03/2011",
  },
  {
    fullname: "JULLYANA GORILA ANAK JENOS",
    position: "ADMINISTRATIVE ASSISTANT",
    job_grade: "NE2",
    date_joined: "11/10/2016",
  },
  {
    fullname: "CHIN LEE HUA",
    position: "ADMINISTRATIVE CLERK",
    job_grade: "NE2",
    date_joined: "01/07/1995*",
  },
  {
    fullname: "BRIAN JOVY ANAK AHMEW",
    position: "ASSISTANT SUPERVISOR",
    job_grade: "NES4",
    date_joined: "01/01/2018",
  },
  {
    fullname: "MOHD. HAZRUL ALEX BIN ABDULLAH",
    position: "ASSISTANT SUPERVISOR",
    job_grade: "NES4",
    date_joined: "27/09/1996",
  },
  {
    fullname: "DIANA BIDAI ANAK MENDALI",
    position: "GENERAL CLERK",
    job_grade: "NE2",
    date_joined: "23/04/2007",
  },
  {
    fullname: "VORNICA NAYAN ANAK NANANG",
    position: "GENERAL CLERK",
    job_grade: "NE2",
    date_joined: "03/01/2017",
  },
  {
    fullname: "BRIGETTE BRENDA BASE",
    position: "GENERAL CLERK",
    job_grade: "NE2",
    date_joined: "05/05/2010",
  },
  {
    fullname: "NURUL HURAIRAH BINTI SARIP",
    position: "GENERAL CLERK",
    job_grade: "NE2",
    date_joined: "11/07/2016",
  },
  {
    fullname: "CHENDANG ANAK THOMAS ALI",
    position: "QA TECHNICIAN",
    job_grade: "NE2",
    date_joined: "10/08/2004",
  },
  {
    fullname: "JONG KIM WONG",
    position: "QA TECHNICIAN",
    job_grade: "NE2",
    date_joined: "11/10/2010",
  },
  {
    fullname: "VERONICA SULAN ANAK VINCENT",
    position: "QA TECHNICIAN",
    job_grade: "NE2",
    date_joined: "21/06/2010",
  },
  {
    fullname: "SYLVESTER ANAK KENETH KANYAN",
    position: "QA TECHNICIAN",
    job_grade: "NES4",
    date_joined: "10/06/2019*",
  },
  {
    fullname: "MAINA NICKOLA ANAK MICHILE",
    position: "STORE KEEPER",
    job_grade: "NE2",
    date_joined: "05/06/2012",
  },
  {
    fullname: "KHO HO SIA, GEORGE",
    position: "SUPERVISOR",
    job_grade: "NES5",
    date_joined: "01/09/2004",
  },
  {
    fullname: "LESTER LUNA ANAK ULOK",
    position: "TECHNICAL ASSISTANT",
    job_grade: "NES4",
    date_joined: "04/06/2018",
  },
  {
    fullname: "YUNG MEI HUA, MANDY",
    position: "OFFICER - ADMIN & PURCHASING",
    job_grade: "E1",
    date_joined: "12/08/2013",
  },
  {
    fullname: "EDWARD TENLEY AK FRANCIS KETIR",
    position: "SHIPPING OFFICER",
    job_grade: "NES4",
    date_joined: "11/01/1995*",
  },
  {
    fullname: "LO JAK FATT, EVENCE",
    position: "IT OFFICER",
    job_grade: "NES4",
    date_joined: "06/06/2011*",
  },
  {
    fullname: "GASTON QION EMMANUEL AK GEORGE",
    position: "SENIOR OFFICER - SOFTWARE DEVELOPER",
    job_grade: "NES4",
    date_joined: "12/12/2019",
  },
  {
    fullname: "TAN KEENG LING",
    position: "SOFTWARE DEVELOPER",
    job_grade: "NES4",
    date_joined: "21/11/2016*",
  },
  {
    fullname: "ABUZAR GHIFARI BIN ZAKARIA",
    position: "SYSTEM ANALYST",
    job_grade: "E1",
    date_joined: "12/04/2021",
  },
  {
    fullname: "YVONNE SHARON ANAK MIKOLDEN",
    position: "CORPORATE COMMUNICATIONS OFFICER",
    job_grade: "E1",
    date_joined: "29/11/2021",
  },
  {
    fullname: "SYLVIA ANAK CHAONG",
    position: "ADMINISTRATIVE OFFICER",
    job_grade: "NES4",
    date_joined: "01/12/2020*",
  },
  {
    fullname: "KATHERINE SONG",
    position: "MANAGER - DESIGN",
    job_grade: "M1",
    date_joined: "16/04/2018",
  },
  {
    fullname: "ONG PEI CHEN, SOPHIA",
    position: "MANAGER - DIGITAL MARKETING & CORPORATE COMMUNICATIONS",
    job_grade: "M1",
    date_joined: "11/10/2021",
  },

  {
    fullname: "AUGUSTINE LAW SEK HIAN",
    date_joined: "1/1/1996"
  },

  {
    fullname: "LAU KIING KANG, SIMON",
    date_joined: "1/1/1990"
  },
  
  {
    fullname: "LAWRENCE KONG",
    date_joined: "5/5/1999"
  },  

  {
    fullname: "SIM YIH SENG",
    date_joined: "8/6/2009"
  },  

  {
    fullname: "TONY YU YUONG WEE",
    date_joined: "21/5/2003"
  },  

  {
    fullname: "WONG MING",
    date_joined: "27/8/2001"
  },  

  {
    fullname: "YU JI",
    date_joined: "11/4/2011"
  },    



];
export const COMPANY_STAFFS = [
  {staff: `CHUA SIAW TED`, company: `HOCK SENG LEE BERHAD` },
{staff: `LIEW SZE YAH, NICOLE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `ARCHIBAL CATIBOG MANANSALA`, company: `HOCK SENG LEE BERHAD` },
{staff: `FREDERICK CABUNGAN BANAWA`, company: `HOCK SENG LEE BERHAD` },
{staff: `JONG SUK FANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `LENOR JOYCE SASOY LEGASPI-BANAWA`, company: `HOCK SENG LEE BERHAD` },
{staff: `NGO KEH TOH, ANNIE`, company: `HOCK SENG LEE BERHAD` },
{staff: `DORIS ANAK WILSON ELAH`, company: `HOCK SENG LEE BERHAD` },
{staff: `MARVIN ANAK HENDARY`, company: `HOCK SENG LEE BERHAD` },
{staff: `NORSAMSUDDIN ANAK BANGUN`, company: `HOCK SENG LEE BERHAD` },
{staff: `SITI HAJAR BINTI MOHD YUSOP`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `CATHERINE CHO SING YIENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `JENNY YU PICK HAI`, company: `HOCK SENG LEE BERHAD` },
{staff: `STEPHEN TAN SOON HONG`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `TAN KOK SAN, NICOLE`, company: `HOCK SENG LEE BERHAD` },
{staff: `TANG LING PING, JENNIFER`, company: `HOCK SENG LEE BERHAD` },
{staff: `TAY CHIOK KEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `TAY CHIOK KEE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `TIONG TECK YIENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `YONG SERN PIAT`, company: `HOCK SENG LEE BERHAD` },
{staff: `AZALIZA BINTI AMIN`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `CECILIA ANAK DAPAN`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `ELLY ANAK WILFRED`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `HELMI BIN PUTEH`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `MARCOS ANAK WARREN SAOH`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `NGUANG KIM KIONG`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `NORBAYU BT MUKU`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `SABREE BIN SABU`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `VERONICA A/P NOED`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `DARREN JOSHUA LUNA ANAK MARVIN @ IMPI`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `BILLY JONG HUNG ENG`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `BONG IT FIAN`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `CHONG UU LEONG, ZACK`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `JOEL SU TAI TUNG`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `JONG TZE SIN`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `LIM WEN CHUNG, ALEX`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `LING SHI HING, ERIC`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `MARIA JATTI ANAK ENA`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `MARIA SU`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `TEO KEE HUA, DORIS`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `VOON SAY NYUK, SHIRLEY`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `LESLEY JENTANG ANAK JOHNNY`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `JACKSON CHAI KEN NAM`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `KHO BOON POH`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `FOO HUI HUI, GRACE`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `HELY ANAK SOTIN`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `THON HUI HUI`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `FOO HUI SIEN`, company: `PLUDEC TRANSPORT SDN BHD` },
{staff: `CHEN ANN NEE, ANNIE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `DOLLY LAU WAN CHING`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `JOSEPHINE FOO SIEW PHENG`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `NG SEE MEI`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `SIA CHAI JIN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `STEPHANIE YII SZE NIE`, company: `ACEGRADE SDN BHD` },
{staff: `SUSIE HO HUI PHING`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `TAY WEI LIANG, WILLIAM`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `TIONG SIEW LING`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `WEE MOI CHOO`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `YONG SIEW TING, CONNIE`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `JENNIFFER JAWAI ANAK ANTHONEY LIM`, company: `HOCK SENG LEE BERHAD` },
{staff: `JUDIRIC ALCAZAR RECIO`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM YUH SHIUN`, company: `HOCK SENG LEE BERHAD` },
{staff: `HASNIYATI HII BINTI ABDULLAH`, company: `HOCK SENG LEE BERHAD` },
{staff: `KATHERINE YII JOU PEI`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAI CHEAU NEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `LIM WEI SING`, company: `HOCK SENG LEE BERHAD` },
{staff: `ALVIN LOH`, company: `HOCK SENG LEE BERHAD` },
{staff: `BESTER ANAK RIBAT`, company: `HOCK SENG LEE BERHAD` },
{staff: `CALVIN ANAK MAWAR`, company: `HOCK SENG LEE BERHAD` },
{staff: `CAMILLA MARCIA KEDUAK ANAK UNYUK`, company: `HOCK SENG LEE BERHAD` },
{staff: `COLIN MATHEW`, company: `HOCK SENG LEE BERHAD` },
{staff: `ELLA ANAK PENGABANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `ERIC UNTAMD ANAK MARK`, company: `HOCK SENG LEE BERHAD` },
{staff: `JIMMY ANAK BELAJA`, company: `HOCK SENG LEE BERHAD` },
{staff: `JOHN WISS ANAK JARO`, company: `HOCK SENG LEE BERHAD` },
{staff: `MOHAMAD HATTA BIN ROSIDI`, company: `HOCK SENG LEE BERHAD` },
{staff: `MUHAMMAD BIN SARIP`, company: `HOCK SENG LEE BERHAD` },
{staff: `NICHOLAS VALEN ANAK RAGIB`, company: `HOCK SENG LEE BERHAD` },
{staff: `SENADA ANAK BAJING`, company: `HOCK SENG LEE BERHAD` },
{staff: `SITI ZALEHA BINTI ZEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `SO HU WEI`, company: `HOCK SENG LEE BERHAD` },
{staff: `TANG KIE CHIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `WELLINGTON ANAK JACK`, company: `HOCK SENG LEE BERHAD` },
{staff: `WILLY ANAK UDAU`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAU SIE CHUNG`, company: `PLUDEC TRANSPORT SDN BHD` },
{staff: `TING SII KIONG`, company: `PLUDEC TRANSPORT SDN BHD` },
{staff: `CHIN CHEE HAO`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `LEE JIE LING`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `LIM KIAN BENG`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `MUHAMMAD ALFIAN WIRA SEPTIAN`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `RIONSIL DOLO MENDILA`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `SARAH ANAK DONYSOS`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `ERNA MARIANA ANAK JUMAT`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `LESTINLEY LUIS ANAK LAWAI`, company: `HOCK SENG LEE BERHAD` },
{staff: `OW WAN FOO, EDDIE`, company: `HOCK SENG LEE BERHAD` },
{staff: `RAYNOLD ANAK MERUM`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `SHIRLEY WONG LEE SAN`, company: `HOCK SENG LEE BERHAD` },
{staff: `KHO KIEN QUAN`, company: `HOCK SENG LEE BERHAD` },
{staff: `AU WAN SEE, YVONNE`, company: `HOCK SENG LEE BERHAD` },
{staff: `ELSIE LEE SUK CHER`, company: `HOCK SENG LEE BERHAD` },
{staff: `EVELYN ANAK ANAU`, company: `HOCK SENG LEE BERHAD` },
{staff: `LUI RUI YING`, company: `HOCK SENG LEE BERHAD` },
{staff: `KO LIAN CHUENG, KENNETH`, company: `HOCK SENG LEE BERHAD` },
{staff: `UMPANG SAONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `BONG JOO CHEE`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `DAUD BIN MAHMOR`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAW DUEN SIONG`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `LU KA LANG @ LU KAH NANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `NANCY THU MEI SZE`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `NG WAN LI, WENDY`, company: `HOCK SENG LEE BERHAD` },
{staff: `NUR ASFIA BIN MOHAMAD DOLLAH`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `TAY GUAN PHING`, company: `KENDREDGE SDN BHD` },
{staff: `WEE GUEK YIAN`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `WEI SZE NEI`, company: `HOCK SENG LEE BERHAD` },
{staff: `YUSUF BIN BAHARI`, company: `HOCK SENG LEE BERHAD` },
{staff: `ALBERT CHUNG SWEE HENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `ARIF BIN CHIK`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIENG SHAW YAW`, company: `HOCK SENG LEE BERHAD` },
{staff: `HO AI CHIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `JOHNNY WONG HING KIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `MOHAMMAD AZEIR BIN UMIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `MUHAMMAD AMIRUL RIDHZUAN STANNY`, company: `MEGADUTA SDN BHD` },
{staff: `STANLEY MALAKA`, company: `HOCK SENG LEE BERHAD` },
{staff: `TIBU ANAK DAUD`, company: `HOCK SENG LEE BERHAD` },
{staff: `TING JIT JIE`, company: `HOCK SENG LEE BERHAD` },
{staff: `TING YING SIEW`, company: `HOCK SENG LEE BERHAD` },
{staff: `AMIRUL SYAZWAN BIN SAMRA`, company: `HSL LAND SDN BHD` },
{staff: `GARY MADANG ANAK UNDOM`, company: `HSL LAND SDN BHD` },
{staff: `SHALLERD ANAK NAPEG`, company: `HSL LAND SDN BHD` },
{staff: `DOLLY ASHLEY ANAK MANDRA`, company: `HOCK SENG LEE BERHAD` },
{staff: `ABDUL MAJID BIN KIPLI`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHRISTOPHER CHAI KOH WEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `VELDA CHANG CHIA YIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `ALVIN CHONG TZE HUI`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHAI TZE LIK`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHAN HOOD SOO`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIN YIAN CHON, ALSON`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `CHINDAI ANAK JOSEPH`, company: `HOCK SENG LEE BERHAD` },
{staff: `HENG MUI KEE`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `HON JUN KHIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `JIMENO JR. LUGUE JERUSALEM`, company: `HOCK SENG LEE BERHAD` },
{staff: `JONG SIAW FOONG, STEVEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAU YUK SIONG, PHILIP`, company: `HOCK SENG LEE BERHAD` },
{staff: `LIM KHIN YONG, JOHN`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIAW WEN YIAP, MICHEAL`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM KAY CHIAN, STEPHEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `TING SING HWA`, company: `HOCK SENG LEE BERHAD` },
{staff: `WONG ING SIU, THOMAS`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `CHANG FU BOON`, company: `HOCK SENG LEE BERHAD` },
{staff: `DIANA LEE MEI MEI`, company: `HOCK SENG LEE BERHAD` },
{staff: `EMILY ANAK USAM`, company: `HOCK SENG LEE BERHAD` },
{staff: `GOH CHIN SING`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `JAVAN LIEW SAN JER`, company: `HOCK SENG LEE BERHAD` },
{staff: `JUSTINE ANAK MALAKA`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAU KIEW HING`, company: `HOCK SENG LEE BERHAD` },
{staff: `PRESCILLIA ANAK PHILIP`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIEW UO SIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM YIK CHWIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `SYLVESTER MIKE LIEW CHO KEAT`, company: `HOCK SENG LEE BERHAD` },
{staff: `ANDYANNA ANAK ADRIS`, company: `HOCK SENG LEE BERHAD` },
{staff: `LEE PING CHIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `MELISA ANAK SURING`, company: `HOCK SENG LEE BERHAD` },
{staff: `DIONYSIUS ALVINOLD ANAK RANTAI`, company: `HOCK SENG LEE BERHAD` },
{staff: `KELVIN YII SENG HUA`, company: `HOCK SENG LEE BERHAD` },
{staff: `KUNJAN ANAK ENGGONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `LIEW HOCK RUI`, company: `HOCK SENG LEE BERHAD` },
{staff: `RONIE LAU HUI LIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `ANGELA KATHY ANAK KINTANG`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `ALIAA SYAMIMI BINTI JAMALI`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `OLIZARENA ANAK ENA`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `WAN MOHAMAD BIN WAN ALLI`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `AUDREY PEARL ANAK DEHU @ RICHARD NERU`, company: `HOCK SENG LEE BERHAD` },
{staff: `DAVIDSON AK DAYUT`, company: `HOCK SENG LEE BERHAD` },
{staff: `LEE KIN TAT`, company: `HOCK SENG LEE BERHAD` },
{staff: `MOHD FIRAS BIN JUANDA`, company: `HOCK SENG LEE BERHAD` },
{staff: `PERNANDEZ TULIS`, company: `HOCK SENG LEE BERHAD` },
{staff: `TANG SING HUI`, company: `HOCK SENG LEE BERHAD` },
{staff: `HA HAW DING`, company: `HOCK SENG LEE BERHAD` },
{staff: `HENRY ANAK ATA`, company: `HOCK SENG LEE BERHAD` },
{staff: `SYLVIA TANG CHUNG TIEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `WONG LEE HOE`, company: `HOCK SENG LEE BERHAD` },
{staff: `HANNA SANTARINA ANAK MAURICE`, company: `HOCK SENG LEE BERHAD` },
{staff: `ABDUL WARITH BIN MOHAMMAD PHILIP`, company: `HOCK SENG LEE BERHAD` },
{staff: `BONG KWONG NYAP`, company: `HOCK SENG LEE BERHAD` },
{staff: `CASLEN HIU JIU HAW`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHAI CHANG LECK, ERIC`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHAI PEI SZE`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIANG HENG HO`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIANG SIONG ING`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIN WEI KING, ERIC`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHONG YU CHIAU`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `CHRIS HUDSON ANAK BUJANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `DAPHNE DONNA BINTI MARTIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `HO CHEE HONG`, company: `RED DOT TUNNELLING SDN BHD` },
{staff: `KELVIN CHAI HOCK JOI`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAI SIEW LIEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `LEE KUET LUNG`, company: `RED DOT TUNNELLING SDN BHD` },
{staff: `LEE MOOK FUA`, company: `HOCK SENG LEE BERHAD` },
{staff: `LIM KAI HENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `ROBICA ANAK KALONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `ROSELIND IKAH ANAK ROBERT @ KELIMBANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `SELINBIT ANAK MOS`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM POH KIAW, EMELYN`, company: `HOCK SENG LEE BERHAD` },
{staff: `TOH JAG HUI`, company: `HOCK SENG LEE BERHAD` },
{staff: `TULIS ANAK CHANDANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `VIVIANA ONG ZHI JINN`, company: `HOCK SENG LEE BERHAD` },
{staff: `DAYANG NUR AININA BINTI ABG MOHAMAD`, company: `HOCK SENG LEE BERHAD` },
{staff: `FADILLAH BTE ABDULLAH`, company: `JENTERA CERIA SDN BHD` },
{staff: `HII CHUI YEN, IVY`, company: `HOCK SENG LEE BERHAD` },
{staff: `LING FUNG ENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `MONA KEDAYA ANAK STORY`, company: `HOCK SENG LEE BERHAD` },
{staff: `NAJA ANAK NYAUN`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `SANDRA ANAK JAWING`, company: `HOCK SENG LEE BERHAD` },
{staff: `SONIA VIVIANI ANAK JANIS`, company: `HOCK SENG LEE BERHAD` },
{staff: `TING SIAW WEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `VOON KHENG HUA, VALERIE`, company: `HOCK SENG LEE BERHAD` },
{staff: `ADRIAN CHAI YOU QING`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `HO SIAW LEE, LILY`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `IRENE KHO AI LING`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `IRWAN BIN BUJANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `LEE SHAW HIENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `TANG KUI SIEW, DEBBY`, company: `HOCK SENG LEE BERHAD` },
{staff: `VIVIAN FU JIA WEN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `ANDRESS LANTUK ANAK MAJING`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `WINNIE LAI EE FEI`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `FARAHANI BINTI HAINI`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `PHANG MING JING, RAINIE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `SHARON NG KHE SWYN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `TOMMY LEE SOON MING`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `TSAI KAI LEN, JOSEPH`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `CHUA YONG TIEN, EUNICE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `JOHAN ANAK JAWA`, company: `HOCK SENG LEE BERHAD` },
{staff: `HII YII SHIN`, company: `BORNEO PALEM LESTARI SDN BHD` },
{staff: `TIE SHIE LING`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `ABDUL AZIZ BIN HIPENI`, company: `HOCK SENG LEE BERHAD` },
{staff: `LINDA ANAK BUYONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `VICTORIA ANAK UMPANG`, company: `MEGADUTA SDN BHD` },
{staff: `ALEXANDER LAI HORNG WEN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `ANGELINE ONG CHEAH JOO`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `ANGIE JOAN ANAK DANIEL`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `CHARLES LEE WEE TECK`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHARLES REYNOLD ANAK NIGEL`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIENG CHING KUOK`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `CHONG SIAW HUA`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `JONG KONG LIP`, company: `HSL LAND SDN BHD` },
{staff: `JOSEPH CHAI MING KIEN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `LAI SIAW LING`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `LESLIE LIM CHUNG LEONG`, company: `BORNEO PALEM LESTARI SDN BHD` },
{staff: `LING SHEAU WEI`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `NGO JIN JIN`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `TAN YIEN LIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `TEO TAI WEE, STEPHEN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `THIAN FUI CHIUN, KERRY`, company: `MEGAKINA SHIPPING SDN BHD` },
{staff: `TIEO SEOW LING, LYN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `TING KWAN HEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `TIONG HWA AI`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `YEO TANG TANG`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `JESECA ANAK NYAMBA`, company: `HOCK SENG LEE BERHAD` },
{staff: `KHAIRUF FAIZAH BINTI MASLIM`, company: `HOCK SENG LEE SERVICES SDN BHD` },
{staff: `ALBINUS PETRUS RATIM NGIBAN`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `ALI BIN SABRI`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `BONNIE ANAK ANTHONY`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `CLINTON JAWA ANAK RICHARDLY`, company: `HSL LAND SDN BHD` },
{staff: `HAIDI BIN MARANI`, company: `MEGAKINA SHIPPING SDN BHD` },
{staff: `TUNI ANAK OUI JUAN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `KOH SIANG SENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `PETER LAW KIING KING`, company: `HOCK SENG LEE BERHAD` },
{staff: `ESTHER CH'NG PEI YAN`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM SIAT FUNG, ALICE`, company: `HOCK SENG LEE BERHAD` },
{staff: `NG KHOON CHIENG`, company: `KENDREDGE SDN BHD` },
{staff: `AMANDA LAW LI KHING`, company: `HOCK SENG LEE BERHAD` },
{staff: `BELINDA TAN CHAI YING`, company: `MEGADUTA SDN BHD` },
{staff: `ANNIE LEE SIEW PHIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `BRENDAN LOUIS KUOK THAI HONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHAI LI SWAN`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHAI PEI SHAN`, company: `HSL TRADING SDN BHD` },
{staff: `CHAI SIN HWEI`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIN JIUN HUAH, ANGEL`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHRISTINE YII SHU YEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHUNG XIN YII`, company: `HOCK SENG LEE BERHAD` },
{staff: `CLEMENT CHOO YEK SHIUNG`, company: `HOCK SENG LEE BERHAD` },
{staff: `JACKUELINE ONG YUEN CHING`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `JONG PEI PEI, ANNA`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `LAW SIEW NI, TIFFANY`, company: `ULTRA MAJESTIC SDN BHD` },
{staff: `LIM EK HUA`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `LINNA WONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `ONG CHIAW SIAN`, company: `JENTERA CERIA SDN BHD` },
{staff: `SIA SOON GUEI`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `TAN MIAW ENG, ANGELINE`, company: `HOCK SENG LEE BERHAD` },
{staff: `TAY YAI YING`, company: `HOCK SENG LEE BERHAD` },
{staff: `TERRY THAM TZE PING`, company: `RED DOT TUNNELLING SDN BHD` },
{staff: `WONG WOAN MIING, RACHELLE`, company: `HOCK SENG LEE BERHAD` },
{staff: `YONG TING`, company: `HOCK SENG LEE BERHAD` },
{staff: `BONG KING HUANG`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `CHEE SIAW PAW`, company: `PLUDEC TRANSPORT SDN BHD` },
{staff: `LEE MING SWEE, JUSTIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIEW MING HUA`, company: `HOCK SENG LEE BERHAD` },
{staff: `WONG MING`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `YONG SIAW FONG, MICHELLE`, company: `HOCK SENG LEE BERHAD` },
{staff: `REMY ANAK JOHEL`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `FABIANA ANAK CHAN`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `LIEGAN ANAK MANGGUH`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `MACCOLLIN DEE DANNY ERICK`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `NOOR MARLIDAH HAI`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `PETRUS SCHENKER ANAK RABES`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `BRIAN JOVY ANAK AHMEW`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `BRIGETTE BRENDA BASE`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `CHENDANG ANAK THOMAS ALI`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `CHIN LEE HUA`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `DIANA BIDAI ANAK MENDALI`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `JOHNNY HO KOK SIAM`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `JONG KIM WONG`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `JULLYANA GORILA ANAK JENOS`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `KHO HO SIA, GEORGE`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `LESTER LUNA ANAK ULOK`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `MAINA NICKOLA ANAK MICHILE`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `MOHD. HAZRUL ALEX BIN ABDULLAH`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `NURUL HURAIRAH BINTI SARIP`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `SYLVESTER ANAK KENETH KANYAN`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `VERONICA SULAN ANAK VINCENT`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `VORNICA NAYAN ANAK NANANG`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `EDWARD TENLEY AK FRANCIS KETIR`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `YUNG MEI HUA, MANDY`, company: `HOCK SENG LEE BERHAD` },
{staff: `ABUZAR GHIFARI BIN ZAKARIA`, company: `MEGAKINA SHIPPING SDN BHD` },
{staff: `AUDREY STECCY ANAK DOUGLAS`, company: `HOCK SENG LEE BERHAD` },
{staff: `GASTON QION EMMANUEL AK GEORGE`, company: `HOCK SENG LEE BERHAD` },
{staff: `IRENE ENGGU`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `LO JAK FATT, EVENCE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `TAN KEENG LING`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `KATHERINE SONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `STEPHEN SUNNY ANAK RICHMOND`, company: `HSL LAND SDN BHD` },
{staff: `SYLVIA ANAK CHAONG`, company: `NEXT PHASE SDN BHD` },
{staff: `YVONNE SHARON ANAK MIKOLDEN`, company: `HOCK SENG LEE BERHAD` }
];

export const COMPANIES = [
"ACEGRADE SDN BHD",
"AF CONSTRUCTION SDN BHD",
"BORNEO GEOTECHNICAL ENGINEERING SDN BHD",
"BORNEO PALEM LESTARI SDN BHD",
"HOCK SENG LEE BERHAD",
"HOCK SENG LEE CONSTRUCTION SDN BHD",
"HOCK SENG LEE MACHINERY SDN BHD",
"HOCK SENG LEE SERVICES SDN BHD",
"HSL MANAGEMENT SERVICES SDN BHD,",
"HSL LAND SDN BHD",
"HSL TRADING SDN BHD",
"JENTERA CERIA SDN BHD",
"KENDREDGE SDN BHD",
"MEGADUTA SDN BHD",
"MEGAKINA SHIPPING SDN BHD",
"MEGAPRON ENGINEERING SDN BHD",
"NEXT PHASE SDN BHD",
"PN CONSTRUCTION SDN BHD",
"POH YONG CONCRETE SDN BHD",
"POH YONG TILES INDUSTRIES SDN BHD",
"PLUDEC STONES & CONSTRUCTION SDN BHD",
"PLUDEC TRANSPORT SDN BHD",
"RED DOT TUNNELLING SDN BHD",
"ULTRA MAJESTIC SDN BHD"
];
