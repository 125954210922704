import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { YESNO } from "../../Constants";
import { removeFromUserList } from "../../store/ImportUserSlice";
import { Button } from "react-bootstrap";

const mstp = (state, ownProps) => {
  const list = state.importUserSlice.userList;
  return { list };
};
const mdtp = (dispatch, ownProps) => {
  const _removeFromUserList = (user) => dispatch(removeFromUserList(user));
  return { removeFromUserList: _removeFromUserList };
};

export default connect(
  mstp,
  mdtp
)((props) => {
  const { list, removeFromUserList } = props;
  const [search, setSearch] = useState("");
  const [currentItems, setCurrentItems] = useState(list);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  useEffect(() => {
    let filteredList = [];
    const list2 = [...list].sort((a, b) => {
      return a.fullname.localeCompare(b.fullname);
    });
    if (search === "") {
      filteredList = [...list2];
    } else {
      filteredList = list2.filter((item) => {
        return item.fullname?.toUpperCase().match(search.toUpperCase());
      });
    }

    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(filteredList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredList.length / itemsPerPage));
  }, [search, itemOffset, itemsPerPage, list]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };
  const handleClick = (ev, u) => {
    removeFromUserList(u);
  };

  const handleSearchChange = (ev) => {
    // console.log(57);
    setSearch(ev.target.value);
  };
  return (
    <>
      <div className="form-group row">
        <label className="col-12 col-md-4">
          <h5>Search</h5>
        </label>
        <div className="col-12 col-md-8">
          <input
            className="form-control"
            onChange={handleSearchChange}
            value={search}
          />
        </div>
      </div>

      {pageCount > 1 && (
        <div style={{ fontSize: "small", marginTop: "1em" }}>
          <ReactPaginate
            breakLabel="..."
            nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
            renderOnZeroPageCount={null}
            className="paginate"
          />
        </div>
      )}
      <ol>
        {currentItems.map((u, i) => {
          return (
            <li key={i}>
              {u.fullname}
              {u.viewOnly === YESNO.YES && <FontAwesomeIcon icon={faEye} />}
              <span className="action">
                <Button
                  variant="info"
                  className="btn-sm"
                  onClick={(ev) => {
                    handleClick(ev, u);
                  }}
                >
                  -
                </Button>
              </span>
            </li>
          );
        })}
      </ol>
    </>
  );
});
