import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { YESNO } from "../../Constants";
import { updateAppraisee } from "../../store/AppraiseeListSlice";

const mstp = (state, ownProps) => {
  const list = state.appraiseeListSlice.staffs;

  return { list: list };
};
const mdtp = (dispatch, ownProps) => {
  const _updateAppraisee = (appraisee) => dispatch(updateAppraisee(appraisee));
  return { updateAppraisee: _updateAppraisee };
};

export default connect(
  mstp,
  mdtp
)((props) => {
  const { list, updateAppraisee } = props;
  const [search, setSearch] = useState("");
  const [orphanOnly, setOrphanOnly] = useState(false);
  const [currentItems, setCurrentItems] = useState(list);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    let filteredList = [...list];
    if (search !== "") {
      filteredList = list.filter((item) => {
        return item.fullname.toUpperCase().match(search.toUpperCase());
      });
    }
    if (orphanOnly) {
      filteredList = filteredList.filter((item) => {
        const appraisers = item.appraisers || [];
        return appraisers.length === 0;
      });
    }
    setCurrentItems(filteredList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredList.length / itemsPerPage));
  }, [search, orphanOnly, itemOffset, itemsPerPage, list]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % list.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };
  const handleClick = (ev, u) => {
    console.log(u);
    updateAppraisee(u);
  };
  const handleSearch = (ev) => {
    setSearch(ev.target.value);
  };

  return (
    <div>
      <div className="form-group row">
        <label className="col-12  col-md-4 h4">Search</label>
        <div className="col-12 col-md-8">
          <input
            className="form-control"
            value={search}
            onChange={handleSearch}
          ></input>
        </div>
      </div>
      <div className="form-group row">
        <div className="form-check" style={{ fontSize: "small" }}>
          <input
            type="checkbox"
            checked={orphanOnly}
            onChange={() => {
              setOrphanOnly(!orphanOnly);
            }}
            onClick={() => {}}
          ></input>
          <label>Orphan Only</label>
        </div>
      </div>

      <div style={{ marginTop: "1em" }}>
        {pageCount > 1 && (
          <div style={{ fontSize: "small" }}>
            <ReactPaginate
              breakLabel="..."
              nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
              renderOnZeroPageCount={null}
              className="paginate"
            />
          </div>
        )}
        <ol>
          {currentItems.map((u, i) => {
            return (
              <li
                key={i}
                onClick={(ev) => {
                  handleClick(ev, u);
                }}
              >
                {u.fullname}
                {u.appraisers?.length !== 0 && (
                  <sup className="text-danger">{u.appraisers?.length}</sup>
                )}
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
});
