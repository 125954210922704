import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Row, Col, Table } from "react-bootstrap";
import { updateList } from "../../store/MyAppraisalListSlice";
import { LISTVIEW , YEAR} from "../../Constants";
import { postError } from "../../store/ApplicationSlice";
import { get } from "axios";
import Score from "../../components/Score";
import CompanyScoreSummary from "./CompanyScoreSummary";
import CompanyScoreDetail from "./CompanyScoreDetail";

// import "./DepartmentForm.css";
// import "./stats.css";

const DepartmentScore = (props) => {
  const dispatch = useDispatch();

  const [records, setRecords] = useState([]);
  const [mode, setMode] = useState(LISTVIEW.LIST);
  useEffect(() => {
    get("api/companies/stats/score/2023")
      .then((res) => {
        console.log(res.data);
        setRecords(res.data);
      })
      .catch((e) => {
        dispatch(postError(e));
      });
  }, []);
  const handleView = (company) => {
    console.log(company);
    const companyid = company._id.companyid;

    get("api/companies/company/" + companyid + "/year/" + YEAR)
      .then((res) => {
        return new Promise((resolve) => {
          const arr = res.data;
          // console.log(arr);
          dispatch(updateList(arr));
          resolve();
        });
      })
      .then(() => {
        setMode(LISTVIEW.VIEW);
      })

   
  };
  const handleList = (ev) => {
    setMode(LISTVIEW.LIST);
  };
  return (
    <>
      {mode === LISTVIEW.LIST && <CompanyScoreSummary onView={handleView} />}
      {mode === LISTVIEW.VIEW && <CompanyScoreDetail onList={handleList} />}
    </>
  );
};

export default DepartmentScore;
