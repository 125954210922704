import React, { useEffect, useState, useCallback } from "react";
import { get } from "axios";

const Pic = (props) => {
  const userid = props.userid;
  const width = props.width;
  const round = props.round;

  let style = { width: "1em" };
  if (width !== undefined) {
    style = { ...style, width: width };
  }
  if (round !== undefined && round) {
    style = { ...style, borderRadius: "50%" };
  }
  const [img, setImage] = useState(false);
  // const [hasImage, setHasImage] = useState(false);
  const getImage = useCallback((userid, isMounted, setImage) => {
    get(
      "https://eleave.hslnet.my/apiv2/public/index.php/employeephoto/" + userid
    )
      .then((res) => {
        if (isMounted) {
          setImage(res.data.photo);
        }
      })
      .catch((e) => {});
  });
  useEffect(() => {
    let isMounted = true;

    if (userid === undefined) return;

    getImage(userid, isMounted, setImage);
    return () => {
      isMounted = false;
    };
  }, [userid]);

  // eslint-disable-next-line jsx-a11y/alt-text
  return <>{img && <img alt="xx" src={img} style={style} />}</>;
};
export default Pic;
