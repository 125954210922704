import React, { useEffect } from "react";
import LinkCard from "./LinkCard";
import "./MainPage.css";
import { YESNO } from "../../Constants";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MainPage = () => {
  let navigate = useNavigate();
  const currentUser = useSelector(
    (state) => state.applicationSlice.currentUser
  );
  const loggedIn = useSelector((state) => state.applicationSlice.currentUser);
  const isHr = currentUser.realm_access?.roles?.includes("appraisal_hr");

  const gotoAppraisal = (ev) => {
    navigate("../Appraisal");
  };
  const gotoAppraiseeScore = (ev) => {
    navigate("../AppraiseeScore");
  };
  const gotoReview = (ev) => {
    navigate("../AppraiseeReview");
  };
  const gotoAppraisalSubmitted = (ev) => {
    navigate("../DepartmentSubmitted");
  };
  const gotoSupervisorSubmitted = (ev) => {
    navigate("../SupervisorSubmitted");
  };
  const gotoDepartmentScore = (ev) => {
    navigate("../DepartmentScore");
  };
  const gotoCompanyScore = (ev) => {
    navigate("../CompanyScore");
  };
  const gotoCompanyForm = (ev) => {
    navigate("../Company");
  };
  const gotoDepartmentForm = (ev) => {
    navigate("../Department");
  };
  const gotoImportUser = (ev) => {
    navigate("../ImportUsers");
  };
  const gotoAssignAppraiser = (ev) => {
    navigate("../AssignAppraiser");
  };

  return (
    <div className="menupanel">
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4">
          <LinkCard
            title="Appraisal"
            description="Fill in Performance Review Form for the staffs under your care."
            onClick={gotoAppraisal}
          >
            <div className="writeArt art"></div>
          </LinkCard>
        </div>
        <div className="col-12 col-sm-6 col-md-4">
          <LinkCard
            title="Review"
            description="Review your Performance Review Form"
            onClick={gotoReview}
          >
            <div className="readArt art"></div>
          </LinkCard>
        </div>
        {isHr && (
          <div className="col-12 col-sm-6 col-md-4">
            <LinkCard
              title="Result"
              description="Review the appraisal results of all staffs"
              onClick={gotoAppraiseeScore}
            >
              <div className="examResultArt art"></div>
            </LinkCard>
          </div>
        )}
        {isHr && (
          <div className="col-12 col-sm-6 col-md-4">
            <LinkCard
              title="Dept Performance"
              description="Review Performance of each department"
              onClick={gotoDepartmentScore}
            >
              <div className="departmentPerformanceArt art"></div>
            </LinkCard>
          </div>
        )}
        {isHr && (
          <div className="col-12 col-sm-6 col-md-4">
            <LinkCard
              title="Company Performance"
              description="Review Performance of each company"
              onClick={gotoCompanyScore}
            >
              <div className="coProgressArt art"></div>
            </LinkCard>
          </div>
        )}
        {isHr && (
          <div className="col-12 col-sm-6 col-md-4">
            <LinkCard
              title="Progress"
              description="For HR Department to review Progress of staff appraisal for each department"
              onClick={gotoAppraisalSubmitted}
            >
              <div className="progressArt art"></div>
            </LinkCard>
          </div>
        )}
        {isHr && (
          <div className="col-12 col-sm-6 col-md-4">
            <LinkCard
              title="Supervisor Progress"
              description="For HR Department to review Progress of staff appraisal by supervisor"
              onClick={gotoSupervisorSubmitted}
            >
              <div className="progressArt art"></div>
            </LinkCard>
          </div>
        )}
        {isHr && (
          <div className="col-12 col-sm-6 col-md-4">
            <LinkCard
              title="Import User"
              description="Import Users into Appraisal System"
              onClick={gotoImportUser}
            >
              <div className="orphanArt art"></div>
            </LinkCard>
          </div>
        )}
        {isHr && (
          <div className="col-12 col-sm-6 col-md-4">
            <LinkCard
              title="Department"
              description="Assign an staff to department for Staff Annual Performance Evaluation"
              onClick={gotoDepartmentForm}
            >
              <div className="departmentArt art"></div>
            </LinkCard>
          </div>
        )}
        {isHr && (
          <div className="col-12 col-sm-6 col-md-4">
            <LinkCard
              title="Company"
              description="Assign an staff to company for Staff Annual Performance Evaluation"
              onClick={gotoCompanyForm}
            >
              <div className="companyArt art"></div>
            </LinkCard>
          </div>
        )}
        {isHr && (
          <div className="col-12 col-sm-6 col-md-4">
            <LinkCard
              title="Assign Appraiser"
              description="Assign an appraiser to staff for Staff Annual Performance Evaluation"
              onClick={gotoAssignAppraiser}
            >
              <div className="supervisorArt art"></div>
            </LinkCard>
          </div>
        )}
        {isHr && (
          <div className="col-12 col-sm-6 col-md-4">
            <LinkCard
              title="Orphan"
              description="List staff without a supervisor to review his appraisal"
              onClick={gotoImportUser}
            >
              <div className="orphanArt art"></div>
            </LinkCard>
          </div>
        )}
      </div>
    </div>
  );
};
export default MainPage;
