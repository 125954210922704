import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { LISTVIEW } from "../../Constants";
import AppraisalList from "../SupervisorAppraisalListForm/AppraisalList";
import AppraisalForm from "../AppraisalForm/AppraisalForm";
import { updateAppraisal } from "../../store/AppraisalSlice";
// import "./DepartmentForm.css";
// import "./stats.css";

const DepartmentScoreDetail = (props) => {
  const { onList } = props;
  const [viewDetail, setViewDetail] = useState(false);
  const dispatch = useDispatch();

  const handleView = (row_no, record) => {
    const appraisal_doc = record.appraisal_doc;
    dispatch(updateAppraisal({ ...appraisal_doc }));
    setViewDetail(true);
  };
  const handleOnList = () => {
    setViewDetail(false);
  };
  const handleGoBackToSummary = () => {
    onList && onList();
  };
  return (
    <>
      {viewDetail === false && (
        <AppraisalList onView={handleView} onList={handleGoBackToSummary} />
      )}
      {viewDetail === true && (
        <AppraisalForm readOnly={true} onList={handleOnList} />
      )}
    </>
  );
};

export default DepartmentScoreDetail;
